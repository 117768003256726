import WasmController, { WasmHandler } from 'react-lib/frameworks/WasmController'
import * as FirebaseI from 'react-lib/frameworks/FirebaseInterface'
import axios from "axios"
import * as Diagform from "issara-sdk/DiagForm_apps_PRX"
import DivisionProfileList from "issara-sdk/apis/DivisionProfileList_apps_PRX"
import RoleList from 'issara-sdk/apis/RoleList_users'
import * as VaccineUploadI from "./VaccineUploadInterface";

export type State =
  {
    division?: any,
    patientSegmentList?: any[],
    apiToken?: string,
    diagFormDetail?: any,
    isLoadingFormAnalytics?: boolean,
    divisionProfileList?: {
      items: any[]
    }
    userRoleList?: any[]
  } 
  & VaccineUploadI.State

export const StateInitial =
{
  patientSegmentList: [],
  diagFormDetail: {},
  isLoadingFormAnalytics: false,
  userRoleList: [],
  ...VaccineUploadI.StateInitial
}

export type Event =
  { message: "DidMount", params: {} } |
  { message: "LoginFirebase", params: {} } |
  { message: "GetPatientSegment", params: {} } |
  { message: "GetDiagFormById", params: { id: number } } |
  { message: "GetDivisionProfile", params: { division: number; code?: string } } |
  { message: "GetRoleListUsers", params: {} } |
  { message: "CreateOfficialAccount", params: { data: { divisionName: string; userRole: number; detail: string } } } |
  VaccineUploadI.Event

export type Data = {}

export const DataInitial = {}
const DIVISION = 2223

type Handler = WasmHandler<State, Event>

export const DidMount: Handler = async (controller, params) => {
  const onFirebaseAuthStateChanged = (user: any) => {
    if (user) {
      // Check if logged in
      console.log("user: ", user.uid);
    } else {
      console.log("no user");
    }
  };
  controller.app.auth().onAuthStateChanged(onFirebaseAuthStateChanged);
}

export const LoginFirebase: Handler = async (controller, params) => {
  FirebaseI.CustomLoginWithUsername(
    controller as any, 
    { username: params.userId.toString() }
  )
  
  // Test calling cloud functions here
  controller.functions.httpsCallable("getIHUserProfile")(
    { 
      apiToken: controller.getState().apiToken
    }
  ).then((result: any) => {
    console.log(result)
  }).catch((error: any) => {
    console.log(error)
  })

  // Test calling wasm here
  // const wasm = await import('wasm-lib');
  // controller.wasm = wasm;
  // console.log("wasm.run(5) = ", wasm.run(5))
}

export const GetPatientSegment: Handler = async (controller, params) => {
  console.log("GetPatientSegment", params)
  // const result = await controller.db.collection("PatientSegment")
  //   .where("diag_rule", "==", "แบบสอบถามข้อมูลเบื้องต้น สำหรับผู้รับบริการ Tele My b+")
  //   .get()
  // const result = await controller.db.collection("PatientSegment")
  //   .where("diag_rule", "==", "ConsentFormBDMS")
  //   .where("form_updated_date", ">=", "2021-04-25 12")
  //   .get()
  // const patiengSegmentList = result.docs.map((doc: any) => doc.data() )

  // Get from local for development purpose
  // const response = await axios.get("/patientSegment.json")
  // console.log(response.data)
  // controller.setState({ patientSegmentList: response.data })

  // Test calling api from gen_ts2

  controller.setState({
    isLoadingFormAnalytics: true
  })

  // const [r, e, n] = await Diagform.DiagFormList_apps_PRX.list({
  //   apiToken: controller.getState().apiToken
  // })

  // console.log(r)


  // if (e) {
  //   console.log(e)
  //   console.log(n)
  //   return
  // }
  // console.log(r)
  if (params.divisionHasUser.findIndex(division => division.id === DIVISION) === -1) {
    controller.setState({ patientSegmentList: [], isLoadingFormAnalytics: false })
    return
  }

  // Get data from GCS
  controller.storage.refFromURL('gs://mybplus-segment')
    .child(`7-${DIVISION}-แบบประเมินอาการช่วงกักตัว [Assessment Checklist during isolation]`)
    .getDownloadURL()
    .then((url: string) => {
      axios.get(url)
        .then(res => {
          console.log(res.data)
          controller.setState({ patientSegmentList: res.data, isLoadingFormAnalytics: false })
        })
        .catch(err => {
          console.log(err)
        })
    })
}

export const GetDiagFormById: Handler = async (controller, params) => {
  const [r, e, n] = await Diagform.DiagFormDetail_apps_PRX.retrieve({
    pk: params.id,
    apiToken: controller.getState().apiToken
  })
  if (e) {
    console.log(e)
    console.log(n)
    return
  }
  controller.setState({
    diagFormDetail: r
  })
}

export const GetDivisionProfile: Handler = async (controller, params) => {
  const { division, code } = params
  const paramsUrl: any = {}
  if (division) {
    paramsUrl.division = DIVISION
  }
  if (code) {
    paramsUrl.code = code
  }
  const [r, e, n] = await DivisionProfileList.list({
    params: paramsUrl,
    apiToken: controller.getState().apiToken
  })
  if (e) {
    console.log(e)
    return
  }

  controller.setState({
    divisionProfileList: r
  })
}

export const GetRoleListUsers: Handler = async (controller, params) => {
  const paramsUrl: any = {}

  const [r, e, n] = await RoleList.list({
    params: paramsUrl,
    apiToken: controller.getState().apiToken
  })
  if (e) {
    console.log(e)
    return
  }

  controller.setState({
    userRoleList: r.items
  })

}