import React, { useEffect, useImperativeHandle, KeyboardEvent, useState, forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import { Input, Button, Icon } from "semantic-ui-react";
import { formatToBEDatetime } from "../../utils";
import moment from "moment";
import TimePicker from "react-time-picker";

const TimeFreeTextBox24 = forwardRef((props: any, ref) => {

  const [time, setTime] = useState("00:00");
  const [minDate, setMinDate] = useState<Date>();
  const [maxDate, setMaxDate] = useState<Date>();

  const timeRef = useRef<any>()
  useEffect(() => {
    setTime(props.value);
  }, [props.value]);

  useImperativeHandle(ref, () => ({
    getTime: () => {
      return time;
    }
  }));

  useEffect(() => {
    if (props.minHour !== undefined && props.maxHour !== undefined ) {
      let date1 = new Date()
      let date2 = new Date()
      date1.setMinutes(0)
      date2.setMinutes(0)
      date1.setHours(props.minHour)
      date2.setHours(props.maxHour)
      setMinDate(date1)
      setMaxDate(date2)
    } 
  }, [props.minHour, props.maxHour])
  

  useEffect(() => {
    if (timeRef.current) {
      const className = ".react-time-picker__inputGroup__"
      const { wrapper } = timeRef.current
      const minElm = wrapper.querySelector(`${className}minute`)
      const hourElm = wrapper.querySelector(`${className}hour`)

      if (props.checkTimeOverflow) {
        minElm.onkeypress = (e: KeyboardEvent<HTMLInputElement>) => {
          if (e.currentTarget.value?.length === 1 && +e.currentTarget.value >= 6) {
            e.preventDefault()
          }
        }
      }

      hourElm.onchange = function (e: KeyboardEvent<HTMLInputElement>) {
        let max = 23
        if (props.use12Hours) {
          max = 12
        }
        if ((props.use12Hours || props.checkTimeOverflow) && +e.currentTarget.value > max) {
          const time = `${max}:${minElm.value}`
          setTime(time);
          props.onChange(time);
        }
      }
    }

  }, [timeRef.current])


  const timeChange = (value: any) => {
    setTime(value);
    props.onChange(value);
  };

  const setCurrentTime = () => {
    if (props.disableIconClick) {
      return
    }
    if (!props.disabledClick) {
      let dateTime = formatToBEDatetime({ date: moment() });
      let time = dateTime.split(" ")[1];
      // console.log("time1", time);
      setTime(time);
      props.onChange(time);
    } else {
      if (!time) {
        let dateTime = formatToBEDatetime({ date: moment() });
        let time = dateTime.split(" ")[1];
        setTime(time);
        props.onChange(time);
      }
    }
  };

  // console.log("time", typeof time)

  return (
    <div
      style={props.style ? props.style : {
        display: "flex",
        alignItems: "center",
      }}
    >
      <TimePicker
        // @ts-ignore
        ref={timeRef}
        style={{ border: "0px" }}
        className={props.className}
        onChange={timeChange}
        value={time}
        locale="sv-sv"
        // clearIcon={null}

        {...(props.minHour && {minTime: minDate})}
        {...(props.maxHour && {maxTime: maxDate})}

        format={"HH:mm"}
        disableClock
        autoFocus={props.autoFocus}
        onClick={props.onClick}
        disabled={props.disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        clearIcon={props.clearIcon === null || !time ? null : <Icon name="close" style={{margin: "0px"}} />}
      />

      <div onClick={setCurrentTime} style={{ paddingLeft: "4px", margin: "auto", ...props.iconClockStyle }}>
        <Icon name="clock" color="grey" disabled={props.disabled} />
      </div>
    </div>
  );
});

TimeFreeTextBox24.defaultProps = {
  disabled: false,
  onChange: () => { },
  value: "",
  className: "",
  disabledClick: false,
  onClick: () => { },
  autoFocus: true,
  disableIconClick: false,
  checkTimeOverflow: false,
  use12Hours: false,
  clearIcon: "",
  iconClockStyle: {}
};

TimeFreeTextBox24.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  disabledClick: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disableIconClick: PropTypes.bool,
  checkTimeOverflow: PropTypes.bool,
  use12Hours: PropTypes.bool,
  clearIcon: PropTypes.any,
  iconClockStyle: PropTypes.object
};

export default React.memo(TimeFreeTextBox24);

/**
 *
 *
 * <Input
      disabled={props.disabled ? true : false}
      type='time'
      name="time"
      value={time}
      onChange={timeChange}
      icon='clock'
      className={props.className}
      onClick={setCurrentTime }
    />
 *
 */
