import config from "config/config";

const pushServerPublicKey = config.WEB_PUSH_PUBLIC_KEY;

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  if (typeof Notification === "undefined") {
    return "denied";
  } else {
    return await Notification.requestPermission();
  }
}
/**
 * shows a notification
 */
function sendNotification() {
  const img = "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg";
  const text = "Take a look at this brand new t-shirt!";
  const title = "New Product Available";
  const options = {
    body: text,
    icon: "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg",
    vibrate: [200, 100, 200],
    tag: "new-product",
    image: img,
    badge: "https://spyna.it/icons/android-icon-192x192.png",
    actions: [
      {
        action: "Detail",
        title: "View",
        icon: "https://via.placeholder.com/128/ff0000",
      },
    ],
  };
  navigator.serviceWorker.ready.then(function (serviceWorker) {
    serviceWorker.showNotification(title, options);
  });
}

/**
 *
 */
function registerServiceWorker() {
  console.log("registerServiceWorker  sw.js");
  return navigator.serviceWorker.register("/sw.js");
}

function unRegisterServiceWorker() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  console.log("createNotificationSubscription");
  //wait for service worker installation to be ready
  // const serviceWorker = navigator.serviceWorker.ready
  if (!navigator || !navigator.serviceWorker) {
    // console.log(" no service worker in navigation.serviceWorker : return null")
    return null;
  }
  return navigator.serviceWorker.ready
    .then(async (serviceWorker) => {
      // subscribe and return the subscription
      // console.log(" navigator.serviceWorker.ready ")
      if (!serviceWorker || !serviceWorker.pushManager) {
        // console.error("MP No serviceWorker return null")
        return null;
      }
      return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: pushServerPublicKey,
      });
    })
    .catch((err) => {
      console.error("navigator.serviceWorker.ready.catch MP Error ", err);
      return err;
    });
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  if (!navigator || !navigator.serviceWorker) {
    return null;
  }
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      if (!serviceWorker || !serviceWorker.pushManager) {
        return null;
      }
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    });
}

export {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  unRegisterServiceWorker,
  sendNotification,
  createNotificationSubscription,
  getUserSubscription,
};
