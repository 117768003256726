import React from "react";
import { Button } from "semantic-ui-react";
import styles from "../css/styles.ts";

const Step = React.forwardRef((props, ref) => {
  const item = props.item;
  const index = props.index;
  const indent = 40;
  const rowspace = 100;
  const fontSize = "24px";
  const w = 680;
  const h = 150;

  return (
    <g ref={ref}>
      <foreignObject
        x={indent + indent * item.level}
        y={0 + rowspace * index}
        // width={w - indent * item.level}
        width={600}
        height={h}
      >
        <Button
          size="massive"
          content={item.label ? item.label : " - "}
          fluid
          color="blue"
          basic={props.selectedRowId !== props.item.id}
          style={styles.basicButton}
          onClick={e => {
            props.setSelectedRow(props.item);
            console.log(props.item)
          }}
        />
      </foreignObject>
      <foreignObject
        x = {(indent + indent * item.level) + 600}
        // x={w + indent}
        y={2 + rowspace * index}
        width={200}
        height={h}
      >
        <div className="Button-bar" width="100%">
          <Button
            size="massive"
            onClick={e => {
              props.doNode(item.id, "insert");
            }}
            circular
            icon="plus"
            color="blue"
            // basic
            style={{ ...styles.basicCircularButton, backgroundColor: "#00798A" }}
          />
          {index !== 0 && (
            <Button
              circular
              icon="minus"
              color="blue"
              size="massive"
              // basic
              style={{ ...styles.basicCircularButton, backgroundColor: "#01B2AC" }}
              onClick={e => {
                props.doNode(item.id, "delete");
              }}
            />
          )}
        </div>
      </foreignObject>
    </g>
  );
});

export default Step;
