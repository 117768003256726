import React from "react";
import PropTypes from "prop-types";
import { Modal, Input, Form, Button } from "semantic-ui-react";

const ModCreateForm = props => {
  const [name, setName] = React.useState("");

  React.useEffect(() => {
    setName("")
  }, [props.open])

  return (
    <Modal size="tiny" open={props.open} onClose={props.onClose}>
      <Modal.Header>
        กรุณาตั้งชื่อแบบฟอร์มใหม่ <span style={{ color: "red" }}>*</span>
      </Modal.Header>
      <br />
      <br />
      <Form>
        <Form.Group>
          <Form.Field width={2} />
          <Form.Field width={14}>
            <Input fluid value={name} onChange={e => setName(e.target.value)} />
          </Form.Field>
          <Form.Field width={2} />
        </Form.Group>
      </Form>
      <br />
      <br />
      <Form>
        <Form.Group inline style={{ margin: 0 }}>
          <Form.Field width={8} style={{ padding: 0 }}>
            <Button
              fluid
              color="red"
              content="ยกเลิก"
              style={{ borderRadius: 0 }}
              onClick={props.onClose}
            />
          </Form.Field>
          <Form.Field width={8} style={{ padding: 0 }}>
            <Button
              fluid
              color="green"
              content="ยืนยัน"
              style={{ borderRadius: 0 }}
              disabled={!name}
              onClick={() => props.onSave(name)}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Modal>
  );
};

ModCreateForm.defaultProps = {
  open: false,
  onClose: () => {},
  onSave: ()=> {}
};

ModCreateForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};

export default ModCreateForm;
