import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Form,
  Dropdown,
  Button,
} from "semantic-ui-react";
import CardLayout from "./CardLayout";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";

const CardPatientSearch = ({
  hnPreData,
  getPatientList,
  getNationality,
  hideCallBack,
  onSelectPatient,
  nationalitiesList,
  patientList,
  onOpenModPatientOldName,
  numberOfPage,
  currentPage,
  onPaginationChange,
  patientListLoading,
  isAPILoading,
  hideColumnsByAccessor,
  hideNationality,
  hideCitizenID,
  inputChangeAutoSearch,
  systemHn8Digit,
  formatHn8Digit
}: any) => {
  const [hn, setHn] = useState("");
  const [fullName, setFullName] = useState("");
  const [citizenId, setCitizenId] = useState("");
  const [nationality, setNationality] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [patientResult, setPatientResult] = useState([]);

  const [clickTimeout, setClickTimeout] = useState<any>(null); // to distinguish between single click and double click
  const [patientSelected, setPatientSelected] = useState<any>(null);

  const styles = {
    mainTable: { height: 420, backgroundColor: "#FFFFFF", overflowX: "auto" }
  };

  const currentChange = useRef<number>(0)
  const isMounted = useRef(false)

  const handleOnClear = () => {
    setHn("");
    setFullName("");
    setCitizenId("");
    setNationality(null);
    setPatientResult([]);
  };

  const handleOnSearch = async (setHn = "") => {
    let hnData = setHn || hn

    if (systemHn8Digit) {
      hnData = formatHn8Digit(hnData)
    }
    if (typeof hnData !== "string") {
      return
    }

    await getPatientList({
      hn: hnData,
      fullName: fullName,
      citizenId: citizenId,
      nationality: nationality,
      patientCurrentPage: 1,
    });

    setIsLoading(false)
  };

  useEffect(() => {
    getNationality();
  }, []);

  useEffect(() => {
    setPatientResult(patientList);
  }, [patientList]);

  useEffect(() => {
    isMounted.current = true
    setHn(hnPreData)
    if (hnPreData) {
      handleOnSearch(hnPreData);
    }
  }, [hnPreData])

  useEffect(() => {
    if (isMounted.current && inputChangeAutoSearch && fullName.length >= 3) {
      currentChange.current += 1
      handleAutoSearch()
    }
  }, [fullName])

  const handleAutoSearch = async (hn = "") => {
    const promise = (number: any) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(number);
        }, 500);
      });
    }
    promise(currentChange.current).then((res) => {
      if (currentChange.current === res) {
        handleOnSearch()
      }
    })

  }

  const handleOnChangeHN = (e: any) => {
    setHn(e.target.value);
  };

  const handleOnChangeFullName = (e: any, data: any) => {
    if (patientListLoading) {
      return
    }
    setFullName(data.value);
  };

  const handleOnChangeCitizenID = (e: any) => {
    setCitizenId(e.target.value);
  };

  const handleOnChangeNation = (e: any, { value }: any) => {
    setNationality(value);
  };

  const handleOnSelectPatient = () => {
    if (patientSelected) {
      onSelectPatient(
        (patientSelected).original.id,
        (patientSelected).original.hn,
        (patientSelected).original.full_name,
        (patientSelected).original.birthdate);
      // hideCallBack();
    }
  };

  const handlePageChange = async (page: any) => {
    setIsLoading(true)
    await onPaginationChange({
      hn: hn,
      fullName: fullName,
      citizenId: citizenId,
      nationality: nationality,
      patientCurrentPage: page + 1
    });
    console.log("handlePageChange False")
    setIsLoading(false)
  }

  const handleOnKeyPressHN = async (ev: any) => {
    let hn = ev.target.value.toUpperCase()
    if (ev.key === "Enter") {
      if (inputChangeAutoSearch) {
        handleOnSearch(hn)
      }
    }
  }

  const allGridColumns = useMemo(() =>
    [
      {
        Header: "HN",
        accessor: "hn",
        minWidth: 80
      },
      {
        Header: "Citizen ID / Passport No.",
        accessor: "citizen_passport",
        style: { whiteSpace: "unset" },
        minWidth: 130
      },
      {
        Header: "ชื่อ นามสกุล(ไทย)",
        accessor: "full_name_th",
        style: { whiteSpace: "unset" },
        minWidth: 180
      },
      {
        Header: "ชื่อ นามสกุล(อังกฤษ)",
        accessor: "full_name_en",
        style: { whiteSpace: "unset" },
        minWidth: 180
      },
      {
        Header: "วัน/เดือน/ปีเกิด",
        accessor: "birthdate",
        minWidth: 100
      },
      {
        Header: "ชื่อมารดา",
        accessor: "mother",
        style: { whiteSpace: "unset" },
        minWidth: 180
      },
      {
        Header: "ที่อยู่",
        accessor: "address",
        style: { whiteSpace: "unset" },
        minWidth: 220
      },
      {
        Header: "เบอร์โทรศัพท์",
        accessor: "tel_num",
        minWidth: 120
      },
      {
        Header: "ชื่อเดิม",
        accessor: "old_name",
        style: { whiteSpace: "unset", textAlign: 'center' },
        minWidth: 50,
        Cell: (data: any) => {
          if (data.original.old_name) {
            return (
              <Button
                color='blue'
                icon='search'
                onClick={() => onOpenModPatientOldName(data.original.id, data.original.hn, data.original.full_name)}
              />
            )
          }
          return ""
        }
      }
    ]
    , [])

  const gridColumns = useMemo(() => {
    const columns = [...allGridColumns]
    return columns.filter(col => !(hideColumnsByAccessor || [])?.includes(col.accessor))
  }, [])

  console.log(gridColumns)

  return (
    <CardLayout
      titleText="ค้นหาผู้ป่วย"
      onClose={hideCallBack}
      toggleable={false}
      loading={patientListLoading}
    >
      <Form>
        <Form.Group inline>
          <Form.Field width={2}>
            <b>HN</b>
          </Form.Field>
          <Form.Input
            width={4}
            onChange={handleOnChangeHN}
            value={hn}
            onKeyPress={handleOnKeyPressHN}
          />
          <Form.Field width={4}>
            <b>ชื่อ นามสกุล (ห้ามใส่คำนำหน้า)</b>
          </Form.Field>
          <Form.Input
            width={6}
            onChange={handleOnChangeFullName}
            value={fullName}
          />
        </Form.Group>
        <Form.Group inline>
          {
            hideCitizenID ? <Form.Field width={6}></Form.Field> :
              <>
                <Form.Field width={2}>
                  <b>Citizen ID</b>
                </Form.Field>
                <Form.Input
                  width={4}
                  onChange={handleOnChangeCitizenID}
                  value={citizenId}
                />
              </>
          }
          {
            hideNationality ? <Form.Field width={4}></Form.Field> :
              <>
                <Form.Field>
                  <b>สัญชาติ</b>
                </Form.Field>
                <Form.Field width={3}>
                  <Dropdown
                    className='block'
                    search
                    selection
                    clearable
                    placeholder="ทุกสัญชาติ"
                    options={nationalitiesList}
                    value={nationality}
                    onChange={handleOnChangeNation}
                  />
                </Form.Field>
              </>
          }

          <Form.Button color="blue" onClick={() => handleOnSearch()}>
            ค้นหา
          </Form.Button>
          <Form.Button color="teal" onClick={handleOnClear}>
            Clear
          </Form.Button>
        </Form.Group>
      </Form>

      <ReactTable
        style={styles.mainTable}
        manual
        data={patientResult}
        pageSize={
          patientList ? (patientList.length < 10 ? 10 : patientList.length) : 10
        }
        showPageSizeOptions={false}
        page={currentPage - 1}
        pages={numberOfPage}
        onPageChange={handlePageChange}
        loading={isLoading || isAPILoading}
        getNoDataProps={() => {
          return { style: { display: "none" } };
        }}
        getTrProps={(state: any, rowInfo: any) => {
          if (rowInfo && rowInfo.row) {
            let background =
              rowInfo.index === (patientSelected ? (patientSelected).index : null)
                ? "#CCE2FF"
                : "white";
            return {
              onClick: (e: any) => {
                setPatientSelected(rowInfo);
                if (clickTimeout !== null) {
                  if (
                    (patientSelected ? (patientSelected).index : null) ===
                    rowInfo.index
                  ) {
                    clearTimeout(clickTimeout);
                    setClickTimeout(null);
                    onSelectPatient(rowInfo.original.id, rowInfo.original.hn, rowInfo.original.full_name, rowInfo.original.birthdate);
                    // hideCallBack();
                  }
                } else {
                  setClickTimeout(
                    setTimeout(() => {
                      clearTimeout(clickTimeout);
                      setClickTimeout(null);
                    }, 500) 
                  );
                }
              },
              style: {
                background: background
              }
            };
          } else {
            return {};
          }
        }}
        columns={gridColumns}
      ></ReactTable>
      <br />
      <Button color='blue' onClick={handleOnSelectPatient}>
        เลือก
      </Button>
    </CardLayout>
  );
};

CardPatientSearch.defaultProps = {
  hnPreData: '',
  patientList: [],
  nationalitiesList: [],
  getPatientList: () => { },
  getNationality: () => { },
  hideCallBack: () => { },
  onSelectPatient: () => { },
  onOpenModPatientOldName: () => { },
  onPaginationChange: () => { },
  currentPage: 1,
  hideColumnsByAccessor: [],
  hideNationality: false,
  hideCitizenID: false,
  inputChangeAutoSearch: false,
  systemHn8Digit: false,
  formatHn8Digit: () => { }
};

CardPatientSearch.propTypes = {
  hnPreData: PropTypes.string,
  patientList: PropTypes.array,
  nationalitiesList: PropTypes.array,
  getPatientList: PropTypes.func,
  getNationality: PropTypes.func,
  hideCallBack: PropTypes.func,
  onSelectPatient: PropTypes.func,
  onOpenModPatientOldName: PropTypes.func,
  onPaginationChange: PropTypes.func,
  currentPage: PropTypes.number,
  hideColumnsByAccessor: PropTypes.array,
  hideNationality: PropTypes.bool,
  hideCitizenID: PropTypes.bool,
  inputChangeAutoSearch: PropTypes.func,
  systemHn8Digit: PropTypes.bool,
  formatHn8Digit: PropTypes.func
};

export default React.memo(CardPatientSearch);
