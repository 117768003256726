import React, { useEffect, useRef } from "react";
import PureReactTable from "react-lib/apps/common/PureReactTable";

const VaccineUpload = (props: any) => {

    const fileRef = useRef<any>()
    useEffect(() => {
        props.onEvent({
            message: "GetVaccineInfo",
            params: {}
        })
    }, []);

    const columns = [
        {
            Header: "Reference ID",
            accessor: "reference_id",
            width: 160,
        },
        {
            Header: "Name",
            accessor: "name",
            width: 160,
        },
        {
            Header: "Last Name",
            accessor: "last_name",
            width: 160,
        },
        {
            Header: "Citizen ID",
            accessor: "citizen_id",
            width: 160,
        },
        {
            Header: "Brand",
            accessor: "brand",
            width: 160,
        },
        {
            Header: "Mobile",
            accessor: "mobile",
            width: 160,
        },
        {
            Header: "Email",
            accessor: "email",
            width: 160,
        },
      ];

    console.log(props.vaccineInfo);

    return <div>
        <div>VaccineUpload1</div>
        <input 
            type="file"
            accept=".xlsx,.xls,.csv"
            ref={fileRef} 
            onClick={(event: any) => {
                event.target.value = null;
            }}
            onChange={(event: any) => {
                if (event.target.value) {
                    props.onEvent({
                        message: "ChooseVaccineFile",
                        params: {
                            file: event.target
                        }
                    });
                }
            }}
        />
         <PureReactTable
            data={props.vaccineInfo}
            columns={columns}
            showPagination={false}
            manual
            getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
              return {
                style: {
                  backgroundColor: rowInfo?.index === props.selectedRowIndex ? "rgba(93, 188, 210, 0.5)" : ""
                }
              };
            }}
          />
    </div>
}

export default VaccineUpload