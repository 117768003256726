import React, { useState } from "react";
import {
  Dimmer,
  Loader,
  Table,
  Button,
  Form,
  Divider,
  Label,
  Tab,
  Icon
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import styles from "../css/styles.ts";
import PropTypes from "prop-types";


const AfterLogin = props => {

  return (
    <Dimmer.Dimmable>
      <div style={{ padding: "1%" }}>
        <br />

        <Form>
          <Form.Group>
            <Form.Field width={2}></Form.Field>
            <Form.Field width={12}>
              <div class="ui message">
                <div class="header">
                  คุณได้ลงทะเบียนในระบบ IsHealth Studio เรียบร้อยแล้ว
                </div>
                <p>
                  กรุณาส่ง Email ที่ ishealth@mor.company โดยแจ้งข้อมูลดังนี้
                </p>
                <p>
                  1. โรงพยาบาลที่สังกัด <br />
                  2. Email ของ facebook account ดังแสดงข้างล่างนี้ <br />
                  doctor1@rama.co.th <br />
                  <br />
                  3. แนบเอกสารขอเข้าใช้งานระบบ ซึ่ง download
                  ได้จากปุ่มด้านล่างนี้ เพื่อให้เจ้าหน้าที่ทำการ Activate
                  account
                </p>
              </div>
            </Form.Field>
            <Form.Field width={2}></Form.Field>
          </Form.Group>
          <Form.Group inline style={{ justifyContent: "space-between" }}>
            <Form.Field width={4}></Form.Field>
            <Form.Field width={8}>
              <Button
                content="Download เอกสารขอเข้าใช้งานระบบ"
                fluid
                color="blue"
                basic
                style={styles.basicButton}
                onClick={() => {
                  window.open('https://showtime.sgp1.digitaloceanspaces.com/media/uploaded/Request%20Permission%20Form.pdf', '_blank');
                }}
              />
            </Form.Field>
            <Form.Field width={4}></Form.Field>
          </Form.Group>
          <Form.Group inline style={{ justifyContent: "space-between" }}>
            <Form.Field width={4}></Form.Field>
            <Form.Field width={8}>
              <Button
                content="แก้ไข Profile"
                fluid
                color="teal"
                basic
                style={styles.basicButton}
                onClick={() => {
                  props.onGoToEditProfile()
                }}
              />
            </Form.Field>
            <Form.Field width={4}></Form.Field>
          </Form.Group>
        </Form>
      </div>
    </Dimmer.Dimmable>
  );
};

AfterLogin.defaultProps = {
  onGoToEditProfile: ()=>{ },
};

AfterLogin.propTypes = {
  onGoToEditProfile: PropTypes.func,
};

export default AfterLogin;
