
import React from 'react'
import {Button} from 'semantic-ui-react'
import PropTypes from "prop-types";

const EmailSetup = props => {

  const inputRef = React.useRef();

  return (
  <> <div> กรุณากรอก Email เพิ่มเติม </div> 
  <input ref={inputRef}/>
  <Button onClick={() => { 
    console.log('text !! ', inputRef) 
    props.handleEmail(inputRef.current.value) 
    }}> OK </Button>
  </>)
}


EmailSetup.defaultProps = {
  handleEmail: () => {},
  
};

EmailSetup.propTypes = {
  handleEmail: PropTypes.func,
};


export default EmailSetup;