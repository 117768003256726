import React from "react";
import { Header, Image } from "semantic-ui-react";
import personImg from "../../common/person.png";
import moment from "moment";
import * as Util from "../../../utils/tsUtils";
// import chatStyle from "../../../../css/chatStyle.js";
// import "../css/ChatStyle.css";

interface ChatListRowProps {
  chat: any,
  onSelect: (id: number) => void,
  className: string,
  triageDes: string,
  triageColor: string,
  hideReclassify: boolean
}

const ChatListRow = (props: ChatListRowProps) => {
  if (!props.chat) {
    return <></>;
  }

  let appDate = "";
  let momentStartDate = null;
  if (props.chat && props.chat.appointment_start_at) {
    let momentAppDate = moment(props.chat.appointment_start_at);
    let estimateService = moment(props.chat.estimate_service_at);
    let thaiDate = Util.convertToThaiDate({
      date: momentAppDate,
      display: "localeDate"
    });
    let localTime = estimateService.isValid()
      ? ` ${estimateService.format("HH:mm")} น.`
      : "";
    appDate = thaiDate || localTime ? `${thaiDate}${localTime}` : "-";
  }

  if (props.chat.started) {
    momentStartDate = moment(props.chat.started);
  }
  // appointment_start_at
  return (
    <div
      id={`chat_list_row${props.chat.id}`}
      className={"row " + props.className}
      // style={chatStyle.chatListRow}
      onClick={() => props.onSelect(props.chat.id)}
    >
      <div>
        <Image
          src={props.chat.image ? props.chat.image : personImg}
          circular
          size='mini'
        />
      </div>
      {props.chat.unread_message_count > 0 ? <div className='badge' /> : null}
      <div
        className='detail'
        // style={chatStyle.chatListDetail}
      >
        <div>
          <Header>{props.chat.patient_name}</Header>
        </div>
        <div className='subDetail'>
          <div>อายุ: {props.chat.patient_age}</div>
          <div>hn: {props.chat.hn}</div>
          {momentStartDate ? (
            <div>
              เข้ารับบริการ:{" "}
              {`${Util.convertToBEDate({
                date: moment(momentStartDate).format("DD/MM/YYYY")
              })} ${moment(momentStartDate).format("HH:mm")}`}
            </div>
          ) : null}
          {props.chat.appointment_doctor && (
            <>
              <div className='app-doc-name'>
                นัดหมาย:{" "}
                {props.chat.appointment_doctor
                  ? `${props.chat.appointment_doctor_full_name}`
                  : "-"}
              </div>
              วันที่/เวลาโดยประมาณ: {`${appDate}`}
            </>
          )}
          {!props.hideReclassify && (
            <div style={{ color: props.triageColor }}>
              Classify: {props.triageDes}
            </div>
          )}
        </div>
        {/* <div>{props.chat.latest_message_text}</div> */}
      </div>
      {/* <div style={chatStyle.chatListTime}>{props.chat.latest_message_at}</div> */}
      <div
        className='time'
        // style={chatStyle.chatListTime}
      >
        {/* {props.chat.unread_message_count > 0 ?  */}
        {/* <div className="badge">100</div>  */}
        {/* : null} */}
        {/* <Icon name="venus" color="pink" size="huge" /> */}
      </div>
    </div>
  );
};

ChatListRow.defaultProps = {
  chat: {},
  onSelect: () => {},
  className: "",
  triageDes: "",
  triageColor: "black",
  hideReclassify: false
};

export default React.memo(ChatListRow);
