import React from "react";
import { Form, Icon, Image } from "semantic-ui-react";
import PropTypes from "prop-types";
import { convertToBEDate } from "../../../utils/tsUtils";

export const isValidHttpUrl = (plaintext) => {
  let url;
  try {
    url = new URL(plaintext);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

const MyMessage = (props) => {
  const generateRead = () => {
    if (props.message.user_read_count > 1) {
      return "Read";
      // return <>{`Read ${props.message.user_read_count}`}</>;
    } else if (props.message.user_read_count > 0) {
      return "Read";
    } else if (props.message.hasOwnProperty("sendFail")) {
      if (props.message.sendFail) {
        return "Failed";
      }
      // else {
      //   return "Delivering";
      // }
    }
    // else {
    //   return "Delivered";
    // }
  };

  const createMessage = () => {
    if (props.message.content_type === "file") {
      return (
        <div className="file">
          <div>
            <Icon name="file" size="big" />
          </div>
          <div className="text">Click to open file</div>
        </div>
      );
    } else if (props.message.content_type === "image") {
      if (!props.message.content_file) {
        return "Unable to get Image"
      }
      if (typeof props.message.content_file === "object") {
        return <Image src={URL.createObjectURL(props.message.content_file)} />;
      }
      return (
        <Image
          src={props.message.content_file}
          // href={props.isMobile ? props.message.content_file : ""}
          onClick={() => {
            if (!props.isMobile) {
              window.open(props.message.content_file);
            }
            // if (typeof window.iosNative !== "undefined") {
            //   window.iosNative.consoleLog("onClick");
            //   window.iosNative.saveImage(props.message.content_file);
            // }
          }}
        />
      );
    } else if (props.message.content_type === "vid_call") {
      return <Icon name="call square" size="big" color="green" />;
    } else if (props.message.content_type === "end_call") {
      return <Icon name="call square" size="big" color="grey" />;
    } else if (props.message.content_type === "navigation") {
      return props.onNavigationMessage({
        content: props.message.content,
        contentPayload: props.message.content_payload,
        contentType: props.message.content_type,
      });
    } else {
      // this is string

      if (
        props.message.content.includes("http")
      ) {
        let listMsg = props.message.content.split(" ");
        // console.log("listMsg", listMsg);
        let Linkify = listMsg.map((item) => {
          if (!item.startsWith("http") && item.includes("http")) {
            let startIdx = item.indexOf("http");
            if (startIdx > 0) {
              let normalText = item.slice(0, startIdx);
              // console.log("normalText:", normalText);
              let urlText = item.slice(startIdx);
              console.log("href urlText:", urlText);
              return (
                <>
                  <>
                    {normalText}
                    <a href={urlText} style={{ position: "relative" }} onClick={props.onClickLink} onMouseDown={props.onClickLink} onContextMenu={props.onClickLink}>{urlText}</a>
                  </>
                </>
              );
            }
          } else {
            console.log("href item", item)
            return isValidHttpUrl(item) ? (
              <a href={item} style={{ position: "relative" }} onClick={props.onClickLink} onMouseDown={props.onClickLink} onContextMenu={props.onClickLink}> {item} </a>
            ) : (
              <> {item} </>
            );
          }
        });

        return Linkify;
      } else {
        return props.message.content;
      }
    }
  };


  return (
    <Form className="MyMessage">
      <Form.Group inline style={{ margin: 0 }}>
        <Form.Field
          className={"content" + (props.isSelected ? " selected " : "") + (props.isBot ? " chatbot " : "")}
          style={{ direction: "initial" }}
          {...(props.isMobile && props.message.content_type === "file" && {href: props.message.content_file} )}
          onClick={() => {
            // props.onSelect(props.message.id)
            if (props.message.sendFail) {
              props.onResend({ message: props.message });
            }
            if (props.message.content_type == "vid_call") {
              props.onOpenVideoCall({ url: props.message.content });
            }
            if (props.message.content_type === "file") {
              window.open(props.message.content_file);
            }
            if (props.isMobile && props.message.content_type === "image") {
              props.onMobileOpenImage(props.message.content_file);
            }
          }}
        >
          {createMessage()}
        </Form.Field>
        <Form.Field className={"sendDateTime" + (props.isBot ? " chatbot " : "")}>
          {generateRead()}
          {props.message.send_at ? (
            <>
              <div style={{ margin: 0 }}>
                {props.message.send_at.split(" ")[1]}
              </div>
              <div>
                {convertToBEDate({ date: props.message.send_at.split(" ")[0] })}
              </div>
            </>
          ) : props.message.sendFail ? (
            <Icon
              name="redo"
              onClick={() => props.onResend({ message: props.message })}
            />
          ) : (
            <Icon name="reply" />
          )}
        </Form.Field>
      </Form.Group>
      {/* <Form.Group
        style={{ margin: 0, display: props.isSelected ? null : "none" }}
      >
        {generateRead()}
      </Form.Group> */}
    </Form>
  );
};

MyMessage.defaultProps = {
  isBot: false,
  isMobile: false,
  isSelected: false,
  onSelect: () => {},
  onOpenVideoCall: ({ url }) => {},
  onNavigationMessage: ({ content = "" }) => content,
  onMobileOpenImage: () => {},
  onClickLink: PropTypes.func,
};

MyMessage.propTypes = {
  isBot: PropTypes.bool,
  isMobile: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  onOpenVideoCall: PropTypes.func,
  onNavigationMessage: PropTypes.func,
  onClickLink: PropTypes.func,
};

export default React.memo(MyMessage);
