import BaseService from "./BaseService";
import { MIXIN } from '../../configs/apis';
class MixInService extends BaseService {
  /**
   * get operating order
   * @param {object} data
   */
  getPrintAPI({ printAPI, params }: { printAPI?: any, params?: any } = {}) {
    return this.client
      .get(printAPI, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  getRegisterByTokenFacebook({ params } : { params?: any } = {}){
    return this.client.get(MIXIN.REGISTER_BY_TOKEN_FACEBOOK, { params })
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }

  postRegisterByTokenFacebook({ data }: { data?: any } = {}) {
    return this.client.post(MIXIN.REGISTER_BY_TOKEN_FACEBOOK, data)
      .then(this.handleResponse)
      .catch(this.throwErrorMessage);
  }
}
export default MixInService;
