
import PRXService from "../services/PRXService";
import PRXserviceLocal from "../services/PRXServiceLocal";
import { to } from "../../utils";
import { formatDateToYYYYMMDD } from "../../utils/dateUtils";
import { identifier } from "@babel/types";
export default class PRXManager {
  // anonymous
  newPRXService = (apiToken) => {
    //
    if (apiToken === "anonymous") {
      return new PRXserviceLocal();
    } else {
      return new PRXService();
    }
  };

  getDiagRule = async ({ apiToken, division, active } = {}) => {
    let params = {};
    if (division) {
      params.division = division;
    }
    params.active = !!active;
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDiagRule({
        params,
        apiToken
      })
    );
    //
    return [response, error, network];
  };
  getDiagFormClassify = async ({ apiToken, patient } = {}) => {
    let params = {};
    if (patient) {
      params.patient = patient;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDiagFormClassify({
        params,
        apiToken
      })
    );
    //
    return [response, error, network];
  };

  getDiagForm = async ({ apiToken, patient, limit, offset, division, today } = {}) => {
    let params = {};
    if (patient) {
      params.patient = patient;
    }
    if (limit) {
      params.limit = limit;
    }
    if (offset) {
      params.offset = offset;
    }
    if (division) {
      params.division = division;
    }
    if (today) {
      params.today = 'true'
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDiagForm({
        params,
        apiToken
      })
    );
    //
    return [response, error, network];
  };
  getDiagFormDetail = async ({ apiToken, diagFormId } = {}) => {
    let params = {};

    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDiagFormDetail({
        params,
        apiToken,
        diagFormId
      })
    );
    //
    return [response, error, network];
  };
  getDivisionHasUser = async ({ apiToken } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDivisionHasUser({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getDivisionProfile = async ({ apiToken, division, divisionCode } = {}) => {
    let params = {};
    if (division) {
      params.division = division;
    }
    if (divisionCode) {
      params.code = divisionCode;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDivisionProfile({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  createDivisionHasUser = async ({ apiToken, data } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.createDivisionHasUser({
        params,
        apiToken,
        data
      })
    );
    return [response, error, network];
  };
  getDiagRuleDetail = async ({ apiToken, id } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getDiagRuleDetail({
        params,
        apiToken,
        id
      })
    );
    return [response, error, network];
  };
  postDiagRule = async ({ apiToken, data } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postDiagRule({
        params,
        apiToken,
        data
      })
    );
    return [response, error, network];
  };

  postTuhSetPatientHn = async ({ apiToken, data } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postTuhSetPatientHn({
        params,
        apiToken,
        data
      })
    );
    return [response, error, network];
  };

  postListPatientFromDiagForm = async ({ apiToken, data, divisionId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postListPatientFromDiagForm({
        params,
        apiToken,
        data,
        divisionId
      })
    );
    return [response, error, network];
  };
  postListPatientFromPatient = async ({ apiToken, data, divisionId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postListPatientFromPatient({
        params,
        apiToken,
        data,
        divisionId
      })
    );
    return [response, error, network];
  };
  getProxyPatientHasDivisionNotFollow = async ({ apiToken, divisionId, limit, offset, search } = {}) => {
    let params = {};
    if (limit) {
      params.limit = limit
    }
    if (offset) {
      params.offset = offset
    }
    if (search) {
      params.search = search
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getProxyPatientHasDivisionNotFollow({
        params,
        apiToken,
        divisionId
      })
    );
    return [response, error, network];
  };
  getProxyPatientHasDivisionPatientChat = async ({ apiToken, divisionId, patientId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getProxyPatientHasDivisionPatientChat({
        params,
        apiToken,
        divisionId,
        patientId
      })
    );
    return [response, error, network];
  };
  patchDiagRuleDetail = async ({ apiToken, id, content } = {}) => {
    let params = {};
    let data = {};
    if (content) {
      data.content = content;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchDiagRuleDetail({
        params,
        apiToken,
        data,
        id
      })
    );
    return [response, error, network];
  };
  patchDiagRuleName = async ({ apiToken, id, name } = {}) => {
    let params = {};
    let data = {};
    if (name) {
      data.name = name;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchDiagRuleDetail({
        params,
        apiToken,
        data,
        id
      })
    );
    return [response, error, network];
  };
  patchDiagRulePublish = async ({ apiToken, id, published } = {}) => {
    let params = {};
    let data = {
      published: published
    };
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchDiagRuleDetail({
        params,
        apiToken,
        data,
        id
      })
    );
    return [response, error, network];
  };
  patchDiagForm = async ({ apiToken, diagFormId, trackingStatus, triageLevel }: any = {}) => {
    let params = {};
    let data: any = {};
    if (trackingStatus) {
      data.tracking_status = trackingStatus;
    }
    if (triageLevel){
      data.triage_level = triageLevel
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchDiagForm({
        params,
        apiToken,
        data,
        diagFormId,
      })
    );
    return [response, error, network];
  };

  postDiagFormMonitor = async ({ apiToken, patient, division } = {}) => {
    let params = {};
    let data = {}
    if (patient) {
      data.patient = patient
    }
    if (division) {
      data.division = division
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postDiagFormMonitor({
        params,
        apiToken,
        data
      })
    );
    return [response, error, network];
  };

  patchDiagRuleActive = async ({ apiToken, id, active, published } = {}) => {
    let params = {};
    let data = {
      active: active
    };
    if (published !== undefined && published !== null) {
      data.published = published;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchDiagRuleDetail({
        params,
        apiToken,
        data,
        id
      })
    );
    return [response, error, network];
  };
  getPublishedDiagRule = async ({ params, apiToken, diagRuleId }) => {
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getPublishedDiagRule({
        params,
        apiToken,
        diagRuleId
      })
    );
    return [response, error, network];
  };
  postPublishedDiagRule = async ({ apiToken, content, diagRule } = {}) => {
    let params = {};
    let data = {};
    if (content) {
      data.content = content;
    }
    if (diagRule) {
      data.diag_rule = diagRule;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postPublishedDiagRule({
        params,
        apiToken,
        data
      })
    );
    return [response, error, network];
  };
  patchPublishedDiagRule = async ({ apiToken, published, id } = {}) => {
    let params = {};
    let data = {};
    if (published || published === false) {
      data.published = published;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchPublishedDiagRule({
        params,
        apiToken,
        data,
        id
      })
    );
    return [response, error, network];
  };
  getTriageLevelClassify = async ({ apiToken, divisionId } = {}) => {
    let params = {};
    let data = {};
    if (divisionId) {
      params.division = divisionId;
    }
    const prxService = this.newPRXService(apiToken);
    let [error, response, network] = await to(
      prxService.getTriageLevelClassify({
        params,
        apiToken
      })
    );
    if (error) {
      [error, response, network] = await to(
        prxService.getMasterTriageLevelClassify({
          params,
          apiToken
        })
      );
    }
    return [response, error, network];
  };
  getClassifyUser = async ({ apiToken, divisionId } = {}) => {
    let params = {};
    let data = {};
    if (divisionId) {
      params.division = divisionId;
    }
    const prxService = this.newPRXService(apiToken);
    let [error, response, network] = await to(
      prxService.getClassifyUser({
        params,
        apiToken
      })
    );
    if (error) {
      [error, response, network] = await to(
        prxService.getClassifyUser({
          params,
          apiToken
        })
      );
    }
    return [response, error, network];
  };
  getMasterTriageLevelClassify = async ({ apiToken } = {}) => {
    let params = {};
    let data = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getMasterTriageLevelClassify({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getLatestChatChannel = async ({ apiToken, userId } = {}) => {
    let params = {};
    if (userId) {
      params.user_id = userId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getLatestChatChannel({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getEncounterTriage = async ({
    apiToken,
    filter,
    patientId,
    divisionIds,
    hasPatientApp,
    orderBy,
    date,
    appDate
  } = {}) => {
    let params = {};
    if (filter) {
      params.triage_level = filter;
    }
    if (patientId) {
      params.patients = patientId;
    }
    if (divisionIds) {
      params.divisions = divisionIds;
    }
    if (hasPatientApp || hasPatientApp === false) {
      params.has_patient_appointment = hasPatientApp;
    }
    if (orderBy) {
      params.order_by = orderBy;
    }
    if (date) {
      params.date = date;
    }
    if (appDate) {
      params.app_date = appDate;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getEncounterTriage({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getEncounter = async ({ apiToken, divisionId } = {}) => {
    let params = {};
    if (divisionId) {
      params.division = divisionId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getEncounter({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getChatChannelMessageList = async ({
    apiToken,
    chatChannelId,
    last
  } = {}) => {
    console.log('getChatChannelMessageList: ');
    let params = {};
    if (last) {
      params.last = last;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getChatChannelMessageList({
        params,
        apiToken,
        chatChannelId
      })
    );
    return [response, error, network];
  };
  getMessageFromURL = async ({ apiToken, url } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getMessageFromURL({
        params,
        apiToken,
        url
      })
    );
    return [response, error, network];
  };
  postChatChannelMessage = async ({
    apiToken,
    content,
    contentType,
    chatChannelId,
    contentFile,
    divisionId
  } = {}) => {
    let params = {};
    let data = {};
    if (content && contentType !== "image" && contentType !== "file") {
      data.content = content;
    }
    if (contentType) {
      data.content_type = contentType;
    }
    if (contentFile) {
      data.content_file = contentFile;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postChatChannelMessage({
        params,
        apiToken,
        data,
        chatChannelId,
        divisionId
      })
    );
    return [response, error, network];
  };
  geNurseNoteList = async ({ apiToken, encounterId } = {}) => {
    let params = {};
    if (encounterId) {
      params.encounter = encounterId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.geNurseNoteList({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  geProgressNoteList = async ({ apiToken, encounterId } = {}) => {
    let params = {};
    if (encounterId) {
      params.encounter = encounterId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.geProgressNoteList({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getChannelDetail = async ({ apiToken, chatChannelId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getChannelDetail({
        chatChannelId,
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  geNurseNoteEncounter = async ({ apiToken, encounterId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.geNurseNoteEncounter({
        encounterId,
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getProxyPatient = async ({ apiToken, patientId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getProxyPatient({
        apiToken,
        params,
        patientId
      })
    );
    return [response, error, network];
  };

  getProxyPatientAllergy = async ({ apiToken, patientId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getProxyPatientAllergy({
        apiToken,
        params,
        patientId
      })
    );
    return [response, error, network];
  };

  getProxyPatientList = async ({ apiToken, division, search } = {}) => {
    let params = {};
    if (search) {
      params.search = search;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getProxyPatientList({
        apiToken,
        params,
        division
      })
    );
    return [response, error, network];
  };

  patchProxyPatientHasDivision = async ({
    apiToken,
    hn,
    id
  } = {}) => {
    let params = {};
    let data = {};
    if (hn) {
      data.hn = hn;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchProxyPatientHasDivision({
        apiToken,
        params,
        data,
        id
      })
    );
    return [response, error, network];
  }

  getProxyPatientHasDivisionAdmin = async ({ apiToken, division } = {}) => {
    let params = {};

    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getProxyPatientHasDivisionAdmin({
        apiToken,
        params,
        division
      })
    );
    return [response, error, network];
  };

  patchProxyPatientHasDivisionAdmin = async ({
    apiToken,
    hn,
    id,
  } = {}) => {
    let params = {};
    let data = {};
    if (hn) {
      data.hn = hn;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchProxyPatientHasDivisionAdmin({
        apiToken,
        params,
        data,
        id
      })
    );
    return [response, error, network];
  }

  patchEncounterReclassify = async ({
    apiToken,
    encounterId,
    triageLevel,
    diagFormId
  } = {}) => {
    let params = {};
    let data = {};
    data.triage_level = triageLevel;

    if (diagFormId) {
      data.diag_form = diagFormId
    }

    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchEncounterReclassify({
        apiToken,
        params,
        data,
        encounterId
      })
    );
    return [response, error, network];
  };

  getChatChannel = async ({ apiToken, userId, isPatient } = {}) => {
    let params = {};
    if (userId) {
      params.user = userId;
      params.user_type = isPatient ? "PATIENT" : "STAFF";
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getChatChannel({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };

  getProxyPatientHasDivisionIdAdmin = async ({ apiToken, division, params } = {}) => {
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getProxyPatientHasDivisionIdAdmin({
        params,
        apiToken,
        division
      })
    );
    return [response, error, network];
  };

  getTuhUsersList = async ({ apiToken, params } = {}) => {
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getTuhUsersList({
        params,
        apiToken,
      })
    );
    return [response, error, network];
  };

  getProxyPatientHasDivision = async ({ apiToken, params }) => {
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getProxyPatientHasDivision({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };

  getPatient = async ({ apiToken }) => {
    const prxService = this.newPRXService(apiToken);
    const [error, response] = await to(
      prxService.getPatient({
        apiToken
      })
    );
    return [response, error];
  };

  patchPatient = async (params) => {
    let { apiToken, address, first_name, last_name, phone, birthday, gender, ...data } = params;
    let formData = new FormData();
    formData.append("extra", JSON.stringify(data));
    console.log('formData: ');


    if (first_name) {
      formData.append("first_name", first_name);
    }
    if (last_name) {
      formData.append("last_name", last_name);
    }
    if (phone) {
      formData.append("phone_no", phone);
    }

    if (birthday) { // YYYY-MM-DD
      console.log(" data", birthday);
      // formData.append("birthdate", birthday);
    }

    if (gender) {
      console.log("data.gender", gender);
      //formData.append("gender", data.gender);
    }

    if (address) {
      formData.append("address", address);
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response] = await to(
      prxService.patchPatient({
        data: formData,
        options: { headers: { 'Content-Type': "multipart/form-data" } },
        apiToken,
      })
    );
    return [response, error];
  };

  postEncounterPatientAppointment = async ({ encounterId, apiToken } = {}) => {
    let params = {};
    let data = {};
    if (encounterId) {
      data.encounter = encounterId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response] = await to(
      prxService.postEncounterPatientAppointment({
        apiToken,
        data,
        params
      })
    );
    return [response, error];
  };
  getDoctorWorkSchedule = async ({
    apiToken,
    providerId,
    startDate,
    endDate,
    divisionId
  } = {}) => {
    let params = {};
    if (providerId) {
      params.provider = providerId;
    }
    if (startDate) {
      params.start_date = startDate;
    }
    if (endDate) {
      params.end_date = endDate;
    }
    if (divisionId) {
      params.division = divisionId;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response] = await to(
      prxService.getDoctorWorkSchedule({
        apiToken,
        params
      })
    );
    return [response, error];
  };
  getAvailableDivisionServiceBlock = async ({
    apiToken,
    division,
    employee,
    date,
    timeRange,
    providerTypeCategory,
  } = {}) => {
    console.log("getAvailableDivisionServiceBlock providerTypeCategory", providerTypeCategory)
    let params = {};
    if (division) {
      params.division = division;
    }
    if (employee) {
      params.employee = employee;
    }
    if (date) {
      params.date = date;
    }
    if (timeRange) {
      params.time_range = timeRange;
    }
    if (providerTypeCategory) {
      params.provider_type_category = "covid_vaccine";
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response] = await to(
      prxService.getAvailableDivisionServiceBlock({
        apiToken,
        params
      })
    );
    return [response, error];
  };
  postSelectDivisionServiceBlock = async ({
    apiToken,
    patientAppointmentId,
    division,
    timeRange,
    employee,
    diagFormId,
    providerTypeCategory
  } = {}) => {
    let params = {};
    let data = {};
    if (patientAppointmentId) {
      data.patient_appointment = patientAppointmentId;
    }
    if (division) {
      data.division = division;
    }
    if (employee) {
      data.employee = employee;
    }
    if (timeRange) {
      data.time_range = timeRange;
    }
    if (diagFormId) {
      data.diag_form = diagFormId;
    }
    if (providerTypeCategory) {
      data.provider_type_category = providerTypeCategory;
    }

    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postSelectDivisionServiceBlock({
        apiToken,
        data,
        params
      })
    );
    return [response, error, network];
  };
  postRequestChangeDivisionServiceBlock = async ({
    apiToken,
    remark,
    division,
    patientAppointment
  } = {}) => {
    let params = {};
    let data = {};
    if (patientAppointment) {
      data.patient_appointment = patientAppointment;
    }
    if (division) {
      data.division = division;
    }
    if (remark) {
      data.remark = remark;
    }
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postRequestChangeDivisionServiceBlock({
        apiToken,
        data,
        params
      })
    );
    return [response, error, network];
  };
  getEncounterTriageDetail = async ({ apiToken, encounterId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.getEncounterTriageDetail({
        apiToken,
        encounterId,
        params
      })
    );
    return [response, error, network];
  };

  getPatientQueue = async ({
    apiToken,
    divisionIds,
    divisionGroupCode,
    unsignedOnly = false,
    signedOnly = false,
    search = ""
  } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    if (divisionIds) {
      params.division = divisionIds;
    }
    if (divisionGroupCode) {
      params.division_group_code = divisionGroupCode;
    }
    if (unsignedOnly) {
      params.unsigned_only = unsignedOnly;
    }
    if (signedOnly) {
      params.signed_only = signedOnly;
    }
    if (search) {
      params.search = search;
    }
    const [error, response, network] = await to(
      prxService.getPatientQueue({
        apiToken,
        params
      })
    );
    return [response, error, network];
  };

  getAppointedPatientQueue = async ({
    apiToken,
    divisionIds,
    date
  } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    if (divisionIds) {
      params.division = divisionIds;
    }
    if (date) {
      params.date = date;
    }
    const [error, response, network] = await to(
      prxService.getAppointedPatientQueue({
        apiToken,
        params
      })
    );
    return [response, error, network];
  }

  getShippingAddress = async ({ apiToken, patientId, encounterId } = {}) => {
    let params = {};
    const prxService = this.newPRXService(apiToken);
    if (patientId) {
      params.patient = patientId;
    }
    if (encounterId) {
      params.encounter = encounterId;
    }
    const [error, response, network] = await to(
      prxService.getShippingAddress({
        apiToken,
        params
      })
    );
    return [response, error, network];
  };
  postShippingAddress = async ({
    apiToken,
    patientAddressId,
    encounterId,
    selfPickup,
    receiverName,
    receiverTel
  } = {}) => {
    let params = {};
    let data = {};
    const prxService = this.newPRXService(apiToken);
    if (patientAddressId) {
      data.patient_address = patientAddressId;
    }
    if (encounterId) {
      data.encounter = encounterId;
    }
    if (selfPickup || selfPickup === false) {
      data.self_pickup = selfPickup;
    }
    if (receiverName) {
      data.receiver_name = receiverName;
    }
    if (receiverTel) {
      data.receiver_tel = receiverTel;
    }
    const [error, response, network] = await to(
      prxService.postShippingAddress({
        apiToken,
        params,
        data
      })
    );
    return [response, error, network];
  };
  getInitPatientAddress = async ({ apiToken, patientId } = {}) => {
    let params = {};
    const prxService = this.newPRXService();
    const [error, response, network] = await to(
      prxService.getInitPatientAddress({
        params,
        apiToken,
        patientId
      })
    );
    return [response, error, network];
  };
  getDashboard = async ({
    apiToken,
    search,
    date,
    triageLevels,
    division,
    paymentStatus,
    organization
  } = {}) => {
    let params = {};
    if (search) {
      params.search = search;
    }
    if (date) {
      params.date = date;
    }
    if (triageLevels) {
      params.triage_levels = triageLevels;
    }
    if (division) {
      params.division = division;
    }
    if (paymentStatus) {
      params.payment_status = paymentStatus;
    }
    if(organization){
      params.organization = organization
    }
    const prxService = this.newPRXService();
    const [error, response, network] = await to(
      prxService.getDashboard({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getOfficialAccountDashboard = async ({
    apiToken,
    search,
    date,
    triageLevels,
    division,
    paymentStatus
  } = {}) => {
    let params = {};
    if (search) {
      params.search = search;
    }
    if (date) {
      params.date = date;
    }
    if (triageLevels) {
      params.triage_levels = triageLevels;
    }
    if (division) {
      params.division = division;
    }
    if (paymentStatus) {
      params.payment_status = paymentStatus;
    }
    const prxService = this.newPRXService();
    const [error, response, network] = await to(
      prxService.getOfficialAccountDashboard({
        params,
        apiToken
      })
    );
    return [response, error, network];
  };
  getDashboardAppointment = async ({
    apiToken,
    hn,
    patient_name,
    division,
    doctor_name,
    start_date,
    end_date,
    patient_dob,
    cancelToken
  }) => {
    const prxService = this.newPRXService();
    let params = {};
    if (hn) {
      params.hn = hn;
    }
    if (patient_name) {
      params.patient_name = patient_name;
    }
    if (division) {
      params.division = division;
    }
    if (doctor_name) {
      params.doctor_name = doctor_name;
    }
    if (start_date) {
      params.start_date = formatDateToYYYYMMDD(start_date);
    }
    if (end_date) {
      params.end_date = formatDateToYYYYMMDD(end_date);
    }
    if (patient_dob) {
      params.patient_dob = formatDateToYYYYMMDD(patient_dob);
    }
    params.telemed = 'y'
    const [error, response, network] = await to(
      prxService.getDashboardAppointment({ apiToken, params, cancelToken })
    );
    return [response, error, network];
  };

  getResolveChatChannel = async ({
    apiToken,
    divisionId,
    hn
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    if (hn) {
      params.hn = hn;
    }
    const [error, response, network] = await to(
      prxService.getResolveChatChannel({ apiToken, params, divisionId })
    );
    return [response, error, network];
  }

  postRequestOA = async ({
    apiToken,
    division_name,
    user_role,
    detail,
    name
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    let data = {};
    if (division_name) {
      data.division_name = division_name;
    }
    if (user_role) {
      data.user_role = user_role;
    }
    if (detail) {
      data.detail = detail;
    }
    if (name) {
      data.name = name;
    }

    const [error, response, network] = await to(
      prxService.postRequestOA({ apiToken, data, params })
    );
    return [response, error, network];

  }

  getListOA = async ({
    apiToken,
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getListOA({ apiToken, params })
    );
    return [response, error, network];
  }

  postFollowOA = async ({
    apiToken,
    id
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    let data = {};

    const [error, response, network] = await to(
      prxService.postFollowOA({ apiToken, data, params, id })
    );
    return [response, error, network];
  }


  getOAInviteLink = async ({
    apiToken,
    divisionId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getOAInviteLink({ apiToken, params, divisionId })
    );
    return [response, error, network];
  }

  postCreatejoinOARequest = async ({
    apiToken,
    token,
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    let data = {};
    if (token) {
      data.token = token;
    }

    const [error, response, network] = await to(
      prxService.postCreatejoinOARequest({ apiToken, data, params })
    );
    return [response, error, network];

  }


  getListJoinOARequest = async ({
    apiToken,
    divisionId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getListJoinOARequest({ apiToken, params, divisionId })
    );
    return [response, error, network];
  }


  postApproveJoinOARequest = async ({
    apiToken,
    id,
    divisionId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    let data = {};

    const [error, response, network] = await to(
      prxService.postApproveJoinOARequest({ apiToken, data, params, id, divisionId })
    );
    return [response, error, network];
  }

  postCreateUpdateSchedule = async ({
    apiToken,
    division,
    providerTypeCategory,
    startDate,
    endDate,
    patientPerSlot,
    items,
    workOnMonday,
    workOnTuesday,
    workOnWednesday,
    workOnThursday,
    workOnFriday,
    workOnSaturday,
    workOnSunday,
    id,
    confirmed,
    diagRule
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    let data = {};

    if (id) {
      data.id = id;
    }

    if (division) {
      data.division = division;
    }
    if (providerTypeCategory) {
      data.provider_type_category = providerTypeCategory;
    }
    if (startDate) {
      data.start_date = startDate;
    }
    if (endDate) {
      data.end_date = endDate;
    }
    if (patientPerSlot) {
      data.patient_per_slot = patientPerSlot;
    }
    if (items) {
      data.items = items;
    }
    if (diagRule) {
      data.diag_rule = diagRule;
    }
    if (workOnMonday !== undefined) {
      data.work_on_monday = workOnMonday;
    }
    if (workOnTuesday !== undefined) {
      data.work_on_tuesday = workOnTuesday;
    }
    if (workOnWednesday !== undefined) {
      data.work_on_wednesday = workOnWednesday;
    }
    if (workOnThursday !== undefined) {
      data.work_on_thursday = workOnThursday;
    }
    if (workOnFriday !== undefined) {
      data.work_on_friday = workOnFriday;
    }
    if (workOnSaturday !== undefined) {
      data.work_on_saturday = workOnSaturday;
    }
    if (workOnSunday !== undefined) {
      data.work_on_sunday = workOnSunday;
    }
    if (confirmed !== undefined) {
      data.confirmed = confirmed
    }

    const [error, response, network] = await to(
      prxService.postCreateUpdateSchedule({ apiToken, data, params })
    );
    return [response, error, network];
  }

  postPatientFromDiagRule = async ({
    apiToken,
    data
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    // let data = {};

    const [error, response, network] = await to(
      prxService.postPatientFromDiagRule({ apiToken, data, params })
    );
    return [response, error, network];
  }

  deleteJoinOARequest = async ({
    apiToken,
    id,
    divisionId,
    detail
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    let data = {};
    if (detail) {
      data.detail = detail;
    }
    const [error, response, network] = await to(
      prxService.deleteJoinOARequest({ apiToken, data, params, id, divisionId })
    );
    return [response, error, network];
  }


  deleteDivisionHasUserOA = async ({
    apiToken,
    id,
    divisionId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    let data = {};

    const [error, response, network] = await to(
      prxService.deleteDivisionHasUserOA({ apiToken, data, params, id, divisionId })
    );
    return [response, error, network];
  }

  getListDivisionHasUserOA = async ({
    apiToken,
    divisionId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getListDivisionHasUserOA({ apiToken, params, divisionId })
    );
    return [response, error, network];
  }

  getListAvailabelTraigeLevel = async ({
    apiToken,
    divisionId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getListAvailabelTraigeLevel({ apiToken, params, divisionId })
    );
    return [response, error, network];
  }


  getListUserRoleOA = async ({
    apiToken,
    divisionId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getListUserRoleOA({ apiToken, params, divisionId })
    );
    return [response, error, network];
  }

  getOADivisionProfile = async ({
    apiToken,
    divisionId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getOADivisionProfile({ apiToken, params, divisionId })
    );
    return [response, error, network];
  }

  getListOARequestCheck = async ({
    apiToken,
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getListOARequestCheck({ apiToken, params })
    );
    return [response, error, network];
  }

  patchDivisionHasUserOA = async ({ apiToken, id, divisionId, roleId } = {}) => {
    let params = {};
    let data = {
      role: roleId
    };
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchDivisionHasUserOA({
        params,
        apiToken,
        data,
        id,
        divisionId
      })
    );
    return [response, error, network];
  };

  patchOADivisionProfile = async ({ apiToken, divisionId,
    name,
    address,
    detail,
    code,
    premiumUrl,
    imageFile,
    verified,
    divisionName,
    hospitalName,
    systemName
  } = {}) => {
    let params = {};
    let data = {};
    if (name) {
      data.name = name;
    }
    if (address) {
      data.address = address;
    }
    if (detail) {
      data.detail = detail;
    }
    if (premiumUrl) {
      data.premium_url = premiumUrl;
    }
    if (code) {
      data.code = code;
    }
    if (imageFile) {
      data.image_file = imageFile;
    }
    if (verified) {
      data.verified = verified;
    }
    if (divisionName) {
      data.division_name = divisionName;
    }
    if (hospitalName) {
      data.hospital_name = hospitalName;
    }
    if (systemName) {
      data.system_name = systemName;
    }

    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchOADivisionProfile({
        params,
        apiToken,
        data,
        divisionId
      })
    );
    return [response, error, network];
  };

  getOAUserProfile = async ({
    apiToken,
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getOAUserProfile({ apiToken, params })
    );
    return [response, error, network];
  }

  getListSchedule = async ({
    apiToken,
    divisionId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    if (divisionId) {
      params.division = divisionId;
    }
    const [error, response, network] = await to(
      prxService.getListSchedule({ apiToken, params, divisionId })
    );
    return [response, error, network];
  }

  getDivisionClassify = async ({
    apiToken,
    divisionId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    if (divisionId) {
      params.division = divisionId;
    }
    const [error, response, network] = await to(
      prxService.getDivisionClassify({ apiToken, params })
    );
    return [response, error, network];
  }

  getChatChannelTriageLevel = async ({
    apiToken,
    divisionId,
    triageLevel
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getChatChannelTriageLevel({ apiToken, params, divisionId, triageLevel })
    );
    return [response, error, network];
  }

  getListChatChannelTriageLevelDiagRule = async ({
    apiToken,
    divisionId,
    triageLevel,
    diagRuleId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getListChatChannelTriageLevelDiagRule({ apiToken, params, divisionId, diagRuleId, triageLevel })
    );
    return [response, error, network];
  }


  getListAvailabelTriageLevelDiagRule = async ({
    apiToken,
    divisionId,
    diagRuleId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getListAvailabelTriageLevelDiagRule({ apiToken, params, divisionId, diagRuleId })
    );
    return [response, error, network];
  }

  getPatientFromDiagRule = async ({
    apiToken,
    patientId,
    diagRuleId,
    divisionId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getPatientFromDiagRule({ apiToken, params, patientId, diagRuleId, divisionId })
    );
    return [response, error, network];
  }

  putPatientFromDiagRule = async ({
    apiToken,
    patientId,
    diagRuleId,
    data } = {}) => {
    let params = {};
    const prxService = this.newPRXService();

    const [error, response, network] = await to(
      prxService.putPatientFromDiagRule({ apiToken, params, patientId, diagRuleId, data })
    );
    return [response, error, network];
  };

  patchOAUserProfile = async ({
    apiToken,
    email,
    first_name,
    last_name,
    dob,
    position,
    licence,
    image
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    let data = {};

    if (email) {
      data.email = email;
    }
    if (first_name) {
      data.first_name = first_name;
    }
    if (last_name) {
      data.last_name = last_name;
    }
    if (dob) {
      data.dob = formatDateToYYYYMMDD(dob);
    }
    if (position) {
      data.position = position;
    }
    if (licence) {
      data.licence = licence;
    }
    if (image) {
      data.image = image;
    }

    const [error, response, network] = await to(
      prxService.patchOAUserProfile({ apiToken, data, params })
    );
    return [response, error, network];
  }

  getVaccineHospital = async ({
    apiToken,
    keyword
  } = {}) => {
    const prxService = this.newPRXService();
    let params = { "keyword": keyword };
    const [error, response, network] = await to(
      prxService.getVaccineHospital({ apiToken, params })
    );
    return [response, error, network];
  }

  getAvailableVaccineDivision = async ({
    apiToken,
    division,
    year_month
  } = {}) => {
    const prxService = this.newPRXService();
    const params = { division: division, year_month: year_month };
    const [error, response, network] = await to(
      prxService.getAvailableVaccineDivision({ apiToken, params })
    );
    return [response, error, network];
  }

  postVaccineServiceBlock = async ({
    apiToken,
    slot
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    let data = { division_service_block: slot };

    const [error, response, network] = await to(
      prxService.postVaccineServiceBlock({ apiToken, data, params })
    );
    return [response, error, network];
  }

  postVaccineNote = async ({
    apiToken,
    data
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    // let data = {  };

    const [error, response, network] = await to(
      prxService.postVaccineNote({ apiToken, data, params })
    );
    return [response, error, network];
  }

  putVaccineServiceBlock = async ({
    apiToken,
    slot
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    let data = { division_service_block: slot };

    const [error, response, network] = await to(
      prxService.putVaccineServiceBlock({ apiToken, data, params })
    );
    return [response, error, network];
  }

  deleteVaccineServiceBlock = async ({
    apiToken,
    slot
  } = {}) => {
    const prxService = this.newPRXService();
    let params = { division_service_block: slot };
    let data = {};

    const [error, response, network] = await to(
      prxService.deleteVaccineServiceBlock({ apiToken, data, params })
    );
    return [response, error, network];
  }

  getListVaccine = async ({
    apiToken,
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getListVaccine({ apiToken, params })
    );
    return [response, error, network];
  }

  getListVaccineLot = async ({
    apiToken,
    vaccineId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    if (vaccineId) {
      params.vaccine = vaccineId
    }
    const [error, response, network] = await to(
      prxService.getListVaccineLot({ apiToken, params })
    );
    return [response, error, network];
  }

  getListVaccineNote = async ({
    apiToken,
    encounter,
    patientId,
    divisionId,
    vaccineId,
    vaccineSeq,
    severity,
    trackingStatus,
    durationVaccinated
  } = {}) => {
    const prxService = this.newPRXService();
    let params: any = {};
    if (encounter) {
      params.encounter = encounter
    }
    if (patientId) {
      params.patient = patientId
    }
    if (divisionId) {
      params.division = divisionId
    }
    if (vaccineId) {
      params.vaccine = vaccineId
    }
    if (vaccineSeq) {
      params.vaccine_seq = vaccineSeq
    }
    if (severity) {
      params.severity = severity
    }
    if (trackingStatus) {
      params.tracking_status = trackingStatus
    }
    if (durationVaccinated) {
      params.duration_vaccinated = durationVaccinated
    }

    const [error, response, network] = await to(
      prxService.getListVaccineNote({ apiToken, params, divisionId })
    );
    return [response, error, network];
  }

  getVaccineNoteDetail = async ({
    apiToken,
    vaccineId
  } = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getVaccineNoteDetail({ apiToken, params, vaccineId })
    );
    return [response, error, network];
  }

  patchVaccineNote = async ({ apiToken, data, id } = {}) => {
    let params = {};
    // let data = {};

    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.patchVaccineNote({ apiToken, data, id, params })
    );
    return [response, error, network];
  };

  getTagEconsentOptions = async ({ apiToken }) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getTagEconsentOptions({ apiToken, params })
    );
    return [response, error, network];
  }

  getV2EncounterTriage = async ({ apiToken, diagRuleId }) => {
    const prxService = this.newPRXService(apiToken);
    let params: any = {};
    if (diagRuleId) {
      params.diag_rule = diagRuleId
    }
    const [error, response, network] = await to(
      prxService.getV2EncounterTriage({ apiToken, params })
    );
    return [response, error, network];
  }

  postRequestBotMessage = async ({ apiToken, code_id, chat_channel_id } = {}) => {
    let params = {};
    let data = {}

    if (code_id) {
      data.code = code_id
    } // { code: code_id };

    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postRequestBotMessage({ apiToken, data, chat_channel_id, params })
    );
    return [response, error, network];
  };

  getOfficialAccountDoctor = async ({ apiToken, divisionId }: any = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    const [error, response, network] = await to(
      prxService.getOfficialAccountDoctor({ apiToken, divisionId, params })
    );
    return [response, error, network];
  }

  postPatientShippingOrderReceived = async ({ apiToken, pk} = {}) => {
    const prxService = this.newPRXService(apiToken);
    const [error, response, network] = await to(
      prxService.postPatientShippingOrderReceived({ apiToken, pk})
    );
    return [response, error, network];
  };

  getPatientShippingOrder = async ({ apiToken, divisionId }: any = {}) => {
    const prxService = this.newPRXService();
    let params = {};
    if (division) {
      params.division = division;
    }
    const [error, response, network] = await to(
      prxService.getPatientShippingOrder({ apiToken, params })
    );
    return [response, error, network];
  }

}

