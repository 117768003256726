import React, {
  useRef,
  MutableRefObject,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  CSSProperties,
  useMemo
} from 'react'
import {
  Form,
  Dimmer,
  Loader,
  Icon,
  Button,
  Input,
  Dropdown,
  Popup
} from "semantic-ui-react"
import { useHistory, useLocation } from "react-router-dom";
import axios from 'axios';

// Controller
import ChatController from "react-lib/apps/IsHealth/Chat/ChatController"

// Manager
import REGManager from "react-lib/apis/manager/REGManager"


// Common
import {
  ModInfo,
  ErrorMessage,
} from "react-lib/apps/common";

import config from "../../../../config/config";

import { NEW_PATIENT_DATA, DropdownType, ModInfoType, modInfoInitial, MONITOR_STATUS_OPTIONS } from "./QAChatTable"
import { PopupCopyLink } from "./CardPatientQueueChat"
import { getFlowSheetArterial } from 'HIS/Interface/ICUI';
import { setStateAsync } from 'react-lib/utils';

type CardPatientQueueChatAirflowProps = {
  setState: any;
  getState: any;
  noReactRouter: boolean;
  diag_rule: any;
  division: number;
  controller: ChatController & {
    regManager: REGManager;
    prxManager: ChatController["prxManager"] & { getV2EncounterTriage: any };
  };
  apiToken: string;
  selectedPatient: any;
  match: any;
  chatBoxRef: any;
  storage?: any;

  // Callback
  getParamsHistory: (
    item: any,
    triage?: any
  ) => { pathname: string; search: string };
  onSelectedPatient: (item: any) => void;
  onActiveItem: (is: boolean) => void;
  onSetEncounterId: (id: string) => void;
  onSetEncounterData: (data: {
    patient: any;
    triage_level: string | null;
  }) => void;
  onSetLoadCardChatBox: (loading: boolean) => void;
  onSetActiveItem: (value: any) => void;
  onSetHideContentChat: (value: boolean) => void;
  onGetPatientDetail?: () => void;
};

type SelectedTriageType = {
  triageLevel?: string,
  description?: string,
  total?: 0,
  items: any[];
  codeColor?: string;
  active?: boolean;
  diagFormId?: number[];
  totalMonitor?: number;
  totalAssessment?: number;
  hideTotalTracking?: boolean
}

type DisplayTrackingStatusProps = {
  flex?: boolean;
  totalMonitor: number;
  totalAssessment: number;
}

type DisplayVitalSignStatusProps = {
  type: "1" | "2",
  value: number | string,
  status: "D" | "W" | "N",
  style: CSSProperties;
  tagOptions: TagOptionType[];
  tagId?: string | number;
}

type TagOptionType = {
  key: string;
  value: string;
  text: string;
}

const NEW_PATIENT = "new_patient"

const TRIAGE_LEVEL_END = "end"

const COLOR = {
  orange: "#FF7033",
  red: "#EB001B",
  apple_red: "#E90B27",
  yellow: "#FDB03C",
  white: "#FFFFFF"
}

const COLOR_TRANSFORM = {
  D: COLOR.apple_red,
  W: COLOR.yellow,
  N: COLOR.white
}

const styles = {
  circle: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    border: "2px solid white",
    marginTop: "1px",
    marginRight: "3px"
  } as CSSProperties,
  tagContent: {
    padding: "1px 5px",
    color: "white",
    borderRadius: "4px",
    marginLeft: "3px",
    width: "160px"
  } as CSSProperties,
  boxVitalSign: {
    display: "flex",
    borderRadius: "4px",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0
  } as CSSProperties,
  labelVitalSign: {
    fontSize: "0.7em",
    fontWeight: "bold",
    margin: 0,
    lineHeight: "13px",
    width: "min-content"
  } as CSSProperties,
  resultVitalSign: {
    // fontSize: "1.02em",
    fontWeight: "bold",
    textAlign: "right",
    overflow: 'hidden',
    fontSize: "0.9em",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  } as CSSProperties
}

type GetLatestPatientForm = { data: any[], diagRuleId: number }

export type CardPatientQueueChatAirflowRef = {
  getSelectedTriage: () => SelectedTriageType;
  getDiagRuleId: () => number;
  getIsReclassify: () => boolean;
  getClassifyList?: () => SelectedTriageType[],
  getIsDiagFormUpdate?: () => boolean;
  getMaxDiagFormId?: () => number | null;
  getClassifyFromDiagRule: () => any[];
  updateDiagForm?: (params: { patientId: number }) => void;
  setSelectedTriage: (data: SelectedTriageType) => void;
  setClassifyListByTraige?: (traigeLevel: string, data: SelectedTriageType) => void;
  setClassifyList?: (data: SelectedTriageType[]) => void;
  handleEventReclassify: () => void;
  handleGetChatChannel: (params?: { newPatientId?: number | null, triage?: any }) => void;

}
/* ------------------------------------------------------ */

/*                 DisplayTrackingStatus;                 */

/* ------------------------------------------------------ */
const DisplayTrackingStatus: React.FunctionComponent<DisplayTrackingStatusProps> = (props) => {

  return <div style={props.flex ? { alignItems: "baseline", position: "absolute", margin: "-7px 0 0 35px" } : { }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ ...styles.circle, backgroundColor: COLOR.red }}>
      </div>
      <label style={{ color: "white" }}>{props.totalMonitor}</label>
    </div>

    <div style={{ display: "flex", alignItems: "center", marginTop: "-5px", marginBottom: "-4px" }}>
      <div style={{ ...styles.circle, backgroundColor: COLOR.orange }}>
      </div>
      <label style={{ color: "white" }}>{props.totalAssessment}</label>
    </div>
  </div>
}

/* ------------------------------------------------------ */

/*                 DisplayVitalSignStatus;                */

/* ------------------------------------------------------ */
const DisplayVitalSignStatus: React.FunctionComponent<DisplayVitalSignStatusProps> = (props) => {

  const label = useMemo(() => {
    const text: string = props.tagOptions.find((option) => option.value === props.tagId)?.text || ""
    let [first, second] = text.split('(')
    first = first.trim()
    second = second ? `(${second}` : ""

    return second ? [first, second] : first
  }, [props.tagOptions, props.tagId])

  return <>
    <Form style={{ ...styles.boxVitalSign, padding: props.status === "D" ? "6px 0px 6px 4px" : "6px 2px 6px 4px", backgroundColor: COLOR_TRANSFORM[props.status] || "white", fontWeight: "bold", color: props.status === "N" || !props.status ? "black" : "white", ...props.style }}>
      {
        Array.isArray(label) ?
          <div style={{ display: 'grid', lineHeight: "10px", marginBottom: "-3px" }}>
            <div style={{ fontSize: "0.9em" }}>{label[0]}</div>
            <div>{"\u00a0"}
              <div style={{ fontSize: "0.67em", position: "absolute", top: "22px" }}>{label[1]}</div>
            </div>
          </div> :
          <div style={styles.labelVitalSign}>{label}</div>
      }
      <div style={{ ...styles.resultVitalSign, width: props.type === "1" ? "47px" : "", margin: props.type === "1" ? "0 3px 0px 0.1em" : "-10px 3px 0px 0.1em" }}>
        {props.value ?? `-${"\u00a0\u00a0"}`}
        {props.status === "D" && <Icon name="info circle" style={{ margin: "-1px -1px 5px 1px", fontSize: "0.95em" }} />}
      </div>
      {/* {props.type === "1" ?
        <div style={styles.resultVitalSign}>{props.value ?? `-${"\u00a0\u00a0"}`}</div> :
        <div style={{ marginRight: "3px", fontSize: "14.5px" }} >{props.value ?? `-${"\u00a0\u00a0"}`}</div>
      }
       */}
    </Form>
  </>
}

/* ------------------------------------------------------ */

/*              CardPatientQueueChatAirflow;              */

/* ------------------------------------------------------ */
const CardPatientQueueChatAirflow = React.forwardRef<any, CardPatientQueueChatAirflowProps>((props, ref) => {
  const history = useHistory();
  const location = useLocation();

  // Loading
  const [isLoadingTriage, setIsLoadingTriage] = useState(false)
  const [isLoadingNextPatient, setIsLoadingNextPatient] = useState(false)
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)

  // Open
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial)
  const [openModalShareLink, setOpenModalShareLink] = useState<boolean>(false)

  const [isTogglePin, setIsTogglePin] = useState(false)
  const [keepPrevTriage, setKeepPrevTriage] = useState<any>({ })
  const [prevDivision, setPrevDivision] = useState<number | null>(null)
  const [hasWSReclassify, setHasWSReclassify] = useState(false);
  const [cloneChatList, setCloneChatList] = useState<any[]>([])
  const [diagRuleList, setDiagRuleList] = useState<any[]>([])
  const [diagRule, setDiagRule] = useState<any>("")
  const [textSearch, setTextSearch] = useState("");
  const [isNewPatient, setIsNewPatient] = useState<boolean>(false)
  const [typingTimeout, setTypingTimeout] = useState<any>(null)
  const [monitorStatus, setMonitorStatus] = useState<any>(0)
  const [page, setPage] = useState(1)
  const [pageSearch, setPageSearch] = useState<number>(1)
  const [tagOptions, setTagOptions] = useState<TagOptionType[]>([])

  // List
  const [classifyList, setClassifyList] = useState<any>([])

  const [latestPatientFormList, setLatestPatientFormList] = useState<any[]>([])

  // Select
  const [selectedTriage, setSelectedTriage] = useState<SelectedTriageType>({
    triageLevel: "",
    description: "",
    total: 0,
    items: [] as any[],
  })

  // Ref Element
  const classifyRef = useRef() as MutableRefObject<HTMLDivElement>
  const triageLevelRef = useRef() as MutableRefObject<HTMLDivElement>
  const chatListRef = useRef() as MutableRefObject<HTMLDivElement>
  const formRef = useRef<any>() as MutableRefObject<HTMLDivElement>
  const inputSearchRef = useRef<any>()

  const isClickPin = useRef(false)
  const scrollHeight = useRef<number>()
  const isMountChatListScroll = useRef(true)
  const isMountTriageScroll = useRef(true)
  const createNewPatientRef = useRef(false)
  const isLaxyLoad = useRef(false)
  const isReclassify = useRef(false)
  const isDiagFormUpdate = useRef(false)
  const maxDiagFormId = useRef<number | null>(null)
  const diagRuleRef = useRef<any>(null)
  const selectedPatientRef = useRef(null)
  const endOfData = useRef(false)
  const allChatList = useRef<any[] | null>(null)
  const triageRef = useRef("")//เพื่อ get ค่า triage_level เมื่อมีการหน่วงเวลา

  // เพื่อให้ refresh reclassify แล้ว ยัง active ที่ diagform เดิม และ menu เดิม
  const isActiveItem = useRef<any>(true)

  const limit = 50

  useImperativeHandle<CardPatientQueueChatAirflowRef, CardPatientQueueChatAirflowRef>(ref, () => ({
    // chatBoxRef: chatBoxRef.current,
    getSelectedTriage: () => selectedTriage,
    getIsReclassify: () => isReclassify.current,
    getIsDiagFormUpdate: () => isDiagFormUpdate.current,
    getMaxDiagFormId: () => maxDiagFormId.current,
    getClassifyList: () => classifyList,
    getClassifyFromDiagRule: ()=> JSON.parse(
        diagRuleList.find((item) => item.id === diagRule)?.classify || "[]"
      ),
    setSelectedTriage: (data) =>
      setSelectedTriage({
        ...selectedTriage,
        ...data
      }),
    setClassifyListByTraige: (triageLevel, data) =>
      setClassifyList((prev: any) => prev.map((item: any) => item.triage_level === triageLevel ? data : item)),
    setClassifyList: (data) => setClassifyList(data),
    handleGetChatChannel: (params) => handleGetChatChannel(params),
    handleEventReclassify: () => handleEventReclassify(),
    getDiagRuleId: () => diagRule,
    updateDiagForm: (params) => handleUpdateDiagForm(params)

  }));

  const getChatChannel = useCallback(
    async (params: Record<string, any> = { }) => {
      const { triage, newPatientId = null, diagRuleId } = params

      if (!triage) {
        return
      }

      const { triage_level, description, total, diagFormId } = triage

      let chatList: any[] = triage?.items || []
      // // setClassifyList((classify: any[]) => classify.map((list: { triage_level: any; }) => list.triage_level === triage_level ? { ...list, total: chatList.length } : list))

      if (params.patient?.id === NEW_PATIENT && !isActiveItem.current && !newPatientId) {
        chatList = [NEW_PATIENT_DATA, ...chatList]
      }

      const data: any = {
        triageLevel: triage_level,
        ...triage,
        items: chatList
      }

      const textSearch: string = await new Promise((resolve, reject) => {
        setTextSearch((prev) => { resolve(prev); return prev })
      });
      if (textSearch && triage_level === TRIAGE_LEVEL_END) {//แสดง list ตามการค้นหา
        const pageSearch: number = await new Promise((resolve, reject) => {
          setPageSearch((prev) => { resolve(prev); return prev })
        });

        const res = await getPatientNotFollow({ nextPage: pageSearch, noOffset: true, search: textSearch })
        data.items = res.items
      }

      setSelectedTriage(data)
      if (!chatList[0]) {
        isMountChatListScroll.current = false
        setIsLoadingTriage(false)
      } else {
        if (isActiveItem.current) {
          props.onSetLoadCardChatBox(true)
        }
      }

      // เลื่อน scroll bar ไปยัง item ที่ active
      if (isMountTriageScroll.current && triageLevelRef.current) {
        const focused = triageLevelRef.current.querySelector(".classify-group.is_active") as HTMLElement

        if (focused) {
          triageLevelRef.current.scrollTop = focused.offsetTop - (triageLevelRef.current.offsetHeight / 2)
        }
        isMountTriageScroll.current = false
      }

      setCloneChatList(chatList)

      if (params.patient && !isActiveItem.current) {
        if (params.patient?.id === NEW_PATIENT && !newPatientId) {
          props.onSelectedPatient(NEW_PATIENT_DATA)
          return
        }

        const findChat = await recursiveFindChat({ list: chatList, id: params.patient.patient?.id, triage_level, page: 1, isChat: true })

        if (findChat.find) {
          console.log("**********   history.replace  ***************")
          // history.replace(props.getParamsHistory(findChat.find, { ...triage, triageLevel: triage.triage_level }))
          histroyReplaceWrapper(!props.noReactRouter && history,  props.getParamsHistory(findChat.find, { ...triage, triageLevel: triage.triage_level }), props.setState)
        } else {
          props.onSelectedPatient(null)
        }
      }

      // เมื่อ create ผู้ป่วยใหม่
      if (newPatientId) {
        createNewPatientRef.current = true
        setIsNewPatient(false)
        const findChat = chatList.find((item: any) => item?.patient?.id === newPatientId)

        console.log("**********   history.push  ***************")
        // history.push(props.getParamsHistory(findChat, triage))
        histroyPushWrapper(!props.noReactRouter && history,  props.getParamsHistory(findChat, triage), props.setState)
        setTimeout(() => {
          console.log("**********   history.replace  ***************")
          // history.replace(props.getParamsHistory(findChat, triage))
          histroyReplaceWrapper(!props.noReactRouter && history,  props.getParamsHistory(findChat, triage), props.setState)
        });
      }
    },
    [props.division],
  )

  const getListTriageLevel = useCallback(
    async ({ diagRuleId = null, isCheckPrevTriage = true, diagRuleItem = [] as any[], latestList = [] as any[] } = { }) => {

      diagRuleId = diagRuleId || diagRule

      if (!isCheckPrevTriage) {

      }

      const diagFormList = latestList.map((list: any) => list.diag_form_id).filter(Boolean)

      if (diagFormList?.[0]) {
        maxDiagFormId.current = Math.max(...diagFormList)
      } else {
        maxDiagFormId.current = null
      }

      let classify = JSON.parse(diagRuleItem.find((item: any) => item.id === diagRuleId)?.classify || '[]')
      let triageLevelList = classify || [] //(classifyItem || []).filter((item: any) => item.diag_rule_id === diagRuleId)

      triageLevelList = triageLevelList.map(async (item: any) => {
        const filterDiagForm: any[] = latestList.filter((acc: any) => acc.triage_level === item.triage_level && acc.diag_rule_id === diagRuleId && acc.diag_form_id)
        const filterPatient: any[] = latestList.filter((acc: any) => acc.triage_level === item.triage_level && acc.diag_rule_id === diagRuleId && !acc.diag_form_id)
        const diagFormId = filterDiagForm.map((item) => item.diag_form_id)
        // const patientId = filterPatient.map((item) => item.patient_id)
        const postListDiagForm = props.controller.prxManager.postListPatientFromDiagForm({
          apiToken: props.apiToken,
          data: {
            diag_form_id: diagFormId
          },
          divisionId: props.division
        })
        // const postListPatient = props.controller.prxManager.postListPatientFromPatient({
        //   apiToken: props.apiToken,
        //   data: {
        //     patient_id: patientId
        //   },
        //   divisionId: props.division
        // })

        console.log(filterDiagForm)
        const [resDiagForm] = await Promise.all([postListDiagForm])

        const fromDiagFormList: any[] = resDiagForm?.[0]?.items || []
        // const fromPatientList: any[] = resPatient?.[0]?.items || []
        const items = [...formatPatient(fromDiagFormList, filterDiagForm)]

        return {
          ...item,
          total: filterDiagForm.length + filterPatient.length,
          diagFormId: diagFormId,
          filterDiagForm,
          items,
          totalAssessment: items.filter((item: any) => item.expired).length,
          totalMonitor: items.filter((item: any) => item.tracking_status_name === "WAITING").length
        }
      })
      triageLevelList = await Promise.all(triageLevelList)

      const page: number = await new Promise((resolve, reject) => {
        setPage((prev) => { resolve(prev); return prev })
      });

      let paramsGet: any = { nextPage: 1 }
      if (!isCheckPrevTriage && props?.match?.params?.triageLevel === TRIAGE_LEVEL_END) {
        paramsGet = { nextPage: page, noOffset: true, }
      }

      const notFollowList = await getPatientNotFollow(paramsGet)

      triageLevelList.push({
        triage_level: TRIAGE_LEVEL_END,
        description: "จบการรักษา",
        total: notFollowList.total,
        items: notFollowList.items,
        codeColor: "rgb(242, 242, 242)",
        active: true,
        diagFormId: [],
        hideTotalTracking: true
      })


      setClassifyList(triageLevelList)

      const prev = keepPrevTriage[props?.division]

      if (prev && isCheckPrevTriage && (prev?.triageLevel || prev?.diagFormId)) {
        const search = diagRuleId ? `?diag_rule=${diagRuleId}` : ""
        const triageLevel = prev?.triageLevel
        const diagFormId = prev?.diagFormId

        if (triageLevel && diagFormId) {
          // 
          console.log("**********   history.push  ***************")
          // history.push({
          //   pathname: `/${history.location.pathname.split("/")[1]}/${triageLevel}/${diagFormId}/`,
          //   search
          // });
          histroyPushWrapper(!props.noReactRouter && history,  {
            pathname: `/${history.location.pathname.split("/")[1]}/${triageLevel}/${diagFormId}/`,
            search
          }, props.setState);
          return

          // 
     
        }
        if (triageLevel) {
          console.log("**********   history.push  ***************")
          // history.push({
          //   pathname: `/${history.location.pathname.split("/")[1]}/${triageLevel}/`,
          //   search
          // });
          histroyPushWrapper(!props.noReactRouter && history,  {
            pathname: `/${history.location.pathname.split("/")[1]}/${triageLevel}/`,
            search
          }, props.setState);
        }
      } else {
        // setIsLoadingTriage(false)
      }

      if (!triageLevelList?.[0]) {
        setIsLoadingTriage(false)
      }

      return triageLevelList

    },
    [props.division],
  )

  useEffect(() => {
    const getTagOptions = async () => {
      try {
        var httpsReference = await props.storage.refFromURL(`${config.TAGOPTIONS_URL}`).getDownloadURL();
        const res = await axios.get(httpsReference)
        setTagOptions(res.data)
      } catch (error) { }
    }
    getTagOptions()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      handleEventReclassify({ isInterval: true })
    }, 180000) // in milliseconds
    return () => clearInterval(interval)
  }, [props?.match?.params?.triageLevel, classifyList, history.location.search])

  useEffect(() => {
    const chatRef = chatListRef.current
    chatRef.onscroll = async () => {
      // lazyload on scroll to bottom
      if (!endOfData.current && props?.match?.params?.triageLevel === TRIAGE_LEVEL_END && !isLaxyLoad.current && chatRef.scrollTop + chatRef.offsetHeight + 15 >= chatRef.scrollHeight) {
        const textSearch: string = await new Promise((resolve, reject) => {
          setTextSearch((prev) => { resolve(prev); return prev })
        });
        isLaxyLoad.current = true
        getNextPatientNotFollow({ search: textSearch })
      }
    };
    return () => {
      // scrollRef.current = false;
      chatRef.onscroll = null;
    };
  }, [chatListRef.current, props?.match?.params?.triageLevel])

  useEffect(() => {
    if (!isTogglePin) {
      isClickPin.current = false
      if (scrollHeight.current !== undefined) {
        triageLevelRef.current.scrollTop = +scrollHeight.current
      }
    }
  }, [isTogglePin])

  useEffect(() => {
    if (props.division && typeof props.division === "number") {
      const cloneObj = { ...keepPrevTriage }
      if (prevDivision) {
        cloneObj[prevDivision] = {
          ...selectedTriage,
          diagFormId: props.selectedPatient?.id,
          pin: isClickPin.current,
          diagRuleId: diagRule
        }
        setKeepPrevTriage(cloneObj)
      }

      if (keepPrevTriage[props.division]?.pin) {
        isClickPin.current = true
        setIsTogglePin(true)
      } else {
        setIsTogglePin(false)
        isClickPin.current = false
      }

      props.onSelectedPatient(null)
      setSelectedTriage({
        triageLevel: "",
        description: "",
        items: [],
        total: 0,
      })
      setClassifyList([])
      setPrevDivision(props.division)
    }
  }, [props.division])

  useEffect(() => {
    const getListDiagRule = async () => {

      setIsLoadingTriage(true)

      let [resDiagRule, error] = await props.controller.prxManager.getDiagRule({
        apiToken: props.apiToken,
        division: props.division,
        active: true
      })

      resDiagRule = resDiagRule?.items || []

      setDiagRuleList(resDiagRule)

      if (resDiagRule[0]) {
        let diagRuleId = resDiagRule[0].id
        const { diag_rule } = getQueryParams()
        let findDiagRule = -1

        if (diag_rule !== null) {
          findDiagRule = resDiagRule.findIndex((option: { id: number; }) => option.id === +diag_rule)
        }

        if (diag_rule && findDiagRule !== -1) {
          diagRuleId = +diag_rule
        }

        const prev = keepPrevTriage?.[props.division]

        if (prev?.diagRuleId) {
          diagRuleId = prev.diagRuleId
        }

        onSetHistoryReplace(diagRuleId)

        diagRuleRef.current = diagRuleId
        setDiagRule(diagRuleId)

        const latestList = await getLatestPatientForm();

        setLatestPatientFormList(latestList);

        console.log('getListTriageLevel latestList: ', latestList);
        console.log('getListTriageLevel resDiagRule: ', resDiagRule);
        console.log('getListTriageLevel diagRuleId: ', diagRuleId);

        await getListTriageLevel({ diagRuleId, diagRuleItem: resDiagRule, latestList })
      } else {
        setIsLoadingTriage(false)
      }
    }

    if (props.division && typeof props.division === "number") {
      getListDiagRule()
      // setTriageLevelList([])
    }
  }, [props.division])

  useEffect(() => {
    let triageLevel = props?.match?.params?.triageLevel
    triageRef.current = triageLevel
    const { diag_rule } = getQueryParams()

    if (triageLevel && classifyList[0] && triageLevel !== selectedTriage.triageLevel && diag_rule) {

      const findClassify = classifyList.find((list: { triage_level: any; }) => list.triage_level === triageLevel)
      props.onSelectedPatient(null)
      setMonitorStatus(0)

      setIsNewPatient(false)
      if (findClassify) {
        getChatChannel(
          { triage: findClassify, diagRuleId: diagRule }
        )
      } else {
        const page = getCurrentPageURL()
        console.log("**********   history.replace  ***************")

        // history.replace({
        //   pathname: page + `${classifyList[0].triage_level}/`,
        //   search: diagRule ? `?diag_rule=${diagRule}` : ""
        // });
        histroyReplaceWrapper(!props.noReactRouter && history,  {
          pathname: page + `${classifyList[0].triage_level}/`,
          search: diagRule ? `?diag_rule=${diagRule}` : ""
        }, props.setState);
      }
    }

    if ((!triageLevel || !diag_rule) && classifyList[0]) {
      const page = getCurrentPageURL()
      console.log("**********   history.replace  *************** page: ", page)
      // history.replace({
      //   pathname: page + `${classifyList[0].triage_level}/`,
      //   search: diagRule ? `?diag_rule=${diagRule}` : ""
      // });
      histroyReplaceWrapper(!props.noReactRouter && history,  {
        pathname: page + `${classifyList[0].triage_level}/`,
        search: diagRule ? `?diag_rule=${diagRule}` : ""
      }, props.setState);
    }
  }, [props?.match?.params?.triageLevel, classifyList])

  useEffect(() => {
    const diagFormId = props?.match?.params?.diagFormId

    if (+diagFormId !== props.selectedPatient?.id && selectedTriage.items[0] && diagFormId) {
      handleSetSelectedPatient(selectedTriage.items, +diagFormId)
    } else if (!diagFormId && selectedTriage.triageLevel) {
      isMountChatListScroll.current = false
      setIsLoadingTriage(false)
    }

    if (selectedTriage.items?.[0]?.id === NEW_PATIENT && !diagFormId) {
      props.onSelectedPatient(NEW_PATIENT_DATA)
      return
    }

    if (props.selectedPatient?.id && selectedTriage.items[0] && !diagFormId) {
      props.onSelectedPatient(null)
    }

    setTimeout(() => {
      props.onSetLoadCardChatBox(false)
    });
    endOfData.current = false

  }, [props?.match?.params?.diagFormId, selectedTriage.items])

  useEffect(() => {
    console.log(props.selectedPatient, isActiveItem.current, "isActiveItem")

    selectedPatientRef.current = props.selectedPatient

    if (props.selectedPatient && !isActiveItem.current) {
      props.onActiveItem(createNewPatientRef.current)
    }

    if (!props.selectedPatient || !isActiveItem.current) {
      isActiveItem.current = true
      return
    }

    props.onActiveItem(createNewPatientRef.current)
    if (props.selectedPatient.id === NEW_PATIENT || createNewPatientRef.current) {
      createNewPatientRef.current = false
    }

    if (isMountChatListScroll.current && chatListRef.current) {
      const focused = chatListRef.current.querySelector(".item.active") as HTMLElement
      if (focused) {
        chatListRef.current.scrollTop = focused.offsetTop - chatListRef.current.offsetHeight + (focused.offsetHeight / 2)
      }
      isMountChatListScroll.current = false
    }

  }, [props.selectedPatient])

  const filterChatList = async () => {
    const text = textSearch.toUpperCase()

    if (props?.match?.params?.triageLevel === TRIAGE_LEVEL_END) {
      const selectedTriage: SelectedTriageType = await new Promise((resolve, reject) => {
        setSelectedTriage((prev) => { resolve(prev); return prev })
      });
      if (!selectedTriage.triageLevel) {
        return
      }
      setIsLoadingSearch(true)
      if (!text) {
        allChatList.current = null
        await getNextPatientNotFollow({ noOffset: true })
        const focused = chatListRef.current?.querySelector(".item.active") as HTMLElement
        if (focused) {
          chatListRef.current.scrollTop = focused.offsetTop - chatListRef.current.offsetHeight + (focused.offsetHeight / 2)
        }
      } else {
        allChatList.current = selectedTriage.items
        setPageSearch(1);
        await getNextPatientNotFollow({ search: textSearch, isSearch: true })
      }
      setIsLoadingSearch(false)

      return
    }

    const filter = cloneChatList.filter((list: any) => {
      const status = !monitorStatus ? true : list.tracking_status_name === monitorStatus
      return (list.patient?.fullname?.toUpperCase()?.includes(text) ||
        list.patient?.hn?.toUpperCase()?.includes(text)) &&
        status;
    })

    setHasWSReclassify(false);
    setSelectedTriage({ ...selectedTriage, items: filter })
    // props.onReceiveChatList({ chatList: filter });
  }

  useEffect(() => {
    filterChatList()
  }, [textSearch, monitorStatus])

  const getNextPatientNotFollow = async ({ search, isSearch, noOffset }: any = { }) => {
    const selectedTriage: SelectedTriageType = await new Promise((resolve, reject) => {
      setSelectedTriage((prev) => { resolve(prev); return prev })
    });
    const page: number = await new Promise((resolve, reject) => {
      setPage((prev) => { resolve(prev); return prev })
    });
    const pageSearch: number = await new Promise((resolve, reject) => {
      setPageSearch((prev) => { resolve(prev); return prev })
    });
    if (selectedTriage.items.length >= selectedTriage.total && !search) {
      return
    }

    if (!isSearch) {
      setIsLoadingNextPatient(true)
    }

    const nextPage = isSearch ? 1 : search ? pageSearch + 1 : noOffset ? page : page + 1
    const notFollow = await getPatientNotFollow({ nextPage, search, noOffset })

    if (!notFollow.items[0]) {
      endOfData.current = true
    }

    setIsLoadingNextPatient(false)
    if (isSearch || search) {
      setPageSearch(nextPage)
    } else {
      setPage(nextPage)
    }
    isLaxyLoad.current = false
    if (notFollow.items[0] || isSearch) {
      setSelectedTriage((prev) => ({
        ...prev,
        items: [...(isSearch || noOffset ? [] : prev.items), ...notFollow.items]
      }))
    }

  }

  // เนื่องจาก triage_level end เป็น lazyload จึง recursive หา item ที่ active อยู่
  const recursiveFindChat = async ({ list, id, triage_level, page, isChat }: any = { }): Promise<{ find: any, list: any[] }> => {
    const find = list.find((item: any) => item?.patient?.id === id)
    if (triage_level === TRIAGE_LEVEL_END) {
      if (!find) {
        page += 1
        const res = await getPatientNotFollow({ nextPage: page })
        if (!res.items[0]) {
          return { find: null, list: [] }
        }
        if (!isChat) {
          setPage(page)
        }
        return await recursiveFindChat({ list: [...list, ...res.items], id, triage_level, page, isChat })
      } else {
        return { find, list }
      }
    } else {
      return { find, list }
    }

  }

  const getLatestPatientForm = async () => {
    let latestList = []

    try {
      if (config.AIRFLOW_CHAT_BY_DJANGO) {
        const [res] = await props.controller.prxManager.getV2EncounterTriage({
          apiToken: props.apiToken,
          diagRuleId: diagRuleRef.current,
        });

        latestList = res || [];
      }else{
        const httpsReference = await props.storage.refFromURL(`https://firebasestorage.googleapis.com/v0/b/${config.GCS_BUCKET_NAME}/o/${props.division}-latest-patient-form.json`).getDownloadURL();
        const res = await axios.get(httpsReference);

        latestList = res.data || [];
      }
    } catch (error) { }
    return latestList
  }

  const getPatientNotFollow = async ({ nextPage = 1, search = "", noOffset = false }) => {
    const [resNotFollow, notFollowError] = await props.controller.prxManager.getProxyPatientHasDivisionNotFollow({
      apiToken: props.apiToken,
      divisionId: props.division,
      limit: noOffset ? limit * nextPage : limit,
      offset: noOffset ? 0 : limit * (nextPage - 1),
      search
    })

    const notFollowList: any[] = formatPatient(resNotFollow?.items || [])

    return {
      items: notFollowList,
      total: resNotFollow?.total || 0
    }
  }

  // Handle Change
  const handleChangeDiagRule = async (value: DropdownType) => {
    setIsLoadingTriage(true)
    props.onSelectedPatient(null)

    setClassifyList([])
    setSelectedTriage({
      triageLevel: "",
      description: "",
      items: [],
      total: 0
    })

    diagRuleRef.current = value
    setDiagRule(value)

    onSetHistoryReplace(value, true)

    let latestList: any[] = latestPatientFormList

    if (config.AIRFLOW_CHAT_BY_DJANGO) {
      latestList = await getLatestPatientForm();

      setLatestPatientFormList(latestList);
    }

    getListTriageLevel({ diagRuleId: value as any, diagRuleItem: diagRuleList, latestList })
  }

  const formatPatient = (list: any[] = [], filterDiagForm: any[] = []) => {
    return list.map((item: any, index: any) => {
      const findDiagForm = filterDiagForm.find((acc) => acc.patient_id === item.patient_id)
      let chat: any = null
      if (item.chat_channel_id) {
        chat = { }
        chat.id = item.chat_channel_id
        chat.unread_message_count = item.has_new_messages ? 1 : 0
      }

      return {
        ...item,
        id: item.id ? item.id : item.patient_id ? item.patient_id : index + 1,
        chat_channel: chat,
        patient: {
          age: item.age,
          fullname: item.full_name,
          hn: item.hn,
          id: item.patient_id
        },
        ...(findDiagForm || { }),
        tracking_status_name: findDiagForm?.expired ? "NONE" : item.tracking_status_name,
      }
    })
  }

  const handleSearch = (e: { target: { value: any; }; }) => {
    const { value } = e.target;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setTextSearch(value);
      }, 800)
    );
  };

  const handleEventReclassify = async ({ isInterval }: any = { }) => {
    const { vid_call } = getQueryParams()

    if (vid_call) {
      isReclassify.current = true
      return
    }

    const myPromise = async (diagRuleId: number) => {
      return new Promise<GetLatestPatientForm>((resolve, reject) => {
        setTimeout(
          async () => {
            const latestPatient = await getLatestPatientForm();
            resolve({
              data: latestPatient,
              diagRuleId,
            });
          },
          isInterval || config.AIRFLOW_CHAT_BY_DJANGO ? 0 : 60000
        );
      });
    }
    const latestPatient: GetLatestPatientForm = await myPromise(diagRule)
    let latestList = latestPatientFormList;

    if (JSON.stringify(latestPatient.data) === JSON.stringify(latestList) && !isInterval) {
      console.log("2nd")
      const latestPatient: GetLatestPatientForm = await myPromise(diagRule)
      latestList = latestPatient.data
    } else {
      latestList = latestPatient.data
      console.log("1st")
    }
    console.log(latestPatient, latestList, diagRuleRef.current, "LATEST")
    setLatestPatientFormList(latestList)

    if (latestPatient.diagRuleId !== diagRuleRef.current) {
      return
    }

    const classifyList = await getListTriageLevel({ diagRuleId: diagRule, isCheckPrevTriage: false, diagRuleItem: diagRuleList, latestList })

    isActiveItem.current = false
    isReclassify.current = false
    let triageLevel = triageRef.current//props?.match?.params?.triageLevel
    const findClassify = classifyList.find((list: { triage_level: any; }) => list.triage_level === triageLevel)

    handleGetChatChannel({ triage: findClassify })
  }

  const handleUpdateDiagForm = async (params: any) => {
    const { vid_call } = getQueryParams()
    if (vid_call) {
      isDiagFormUpdate.current = true
      return
    }
    let triageLevel = props?.match?.params?.triageLevel
    const findClassify = classifyList.find((list: { triage_level: any; }) => list.triage_level === triageLevel)
    const updateList = classifyList.map(async (list: any) => {
      let check = false
      if (params.isInterval) {
        check = true
      } else if (Boolean(list.items.find((item: any) => item.patient_id === params.patientId))) {
        check = true
      }
      if (check && list.triage_level !== TRIAGE_LEVEL_END) {
        const [resDiagForm] = await props.controller.prxManager.postListPatientFromDiagForm({
          apiToken: props.apiToken,
          data: {
            diag_form_id: list.diagFormId
          },
          divisionId: props.division
        })
        const fromDiagFormList: any[] = resDiagForm?.items || []
        const items = [...formatPatient(fromDiagFormList, list.filterDiagForm)]
        list.items = items
        list.totalAssessment = items.filter((item: any) => item.expired).length
        list.totalMonitor = items.filter((item: any) => item.tracking_status_name === "WAITING").length
      }
      return list
    })
    const triageLevelList = await Promise.all(updateList)
    setClassifyList(triageLevelList)
    isActiveItem.current = false
    isDiagFormUpdate.current = false
    handleGetChatChannel({ triage: findClassify })
  }

  const handleGetChatChannel = async ({ newPatientId = null, triage = null }: { newPatientId?: number | null, triage?: any } = { }) => {
    triage = triage ? triage : selectedTriage
    console.log(props.selectedPatient, "PATIENT", selectedPatientRef.current)
    await getChatChannel({
      triage: {
        ...triage,
        triage_level: triage.triageLevel || triage.triage_level
      },
      diagRuleId: diagRule,
      newPatientId,
      patient: selectedPatientRef.current,
    })
  }

  // Handle

  const handleAddNewPatient = () => {

    if (isNewPatient) {
      setOpenModInfo({ open: true, title: "กรุณาบันทึกข้อมูลผู้ป่วยใหม่", color: "yellow" })
      return
    }

    setSelectedTriage({
      ...selectedTriage,
      items: [NEW_PATIENT_DATA, ...selectedTriage.items]
    })

    props.onSelectedPatient(NEW_PATIENT_DATA)
    props.onSetActiveItem("PROFILE")
    setIsNewPatient(true)

    const page = getCurrentPageURL()

    console.log("**********   history.push  ***************")
    // history.push({
    //   pathname: page + `${selectedTriage.triageLevel}/`,
    //   search: diagRule ? `?diag_rule=${diagRule}` : ""
    // });
    histroyPushWrapper(!props.noReactRouter && history,  {
      pathname: page + `${selectedTriage.triageLevel}/`,
      search: diagRule ? `?diag_rule=${diagRule}` : ""
    }, props.setState);

    handleCloseModInfo()
  }

  // Handle Click
  const handleClickTriageLevel = async (item: any) => {
    if (!isTogglePin && !isClickPin.current) {

      const page = getCurrentPageURL();
      const { diag_rule } = getQueryParams()

      props.onSelectedPatient(null)
      props.onSetEncounterId("")
      setIsNewPatient(false)
      setPage(1)
      setPageSearch(1)
      setTextSearch("")
      if (inputSearchRef.current?.inputRef?.current) {
        inputSearchRef.current.inputRef.current.value = ""
      }
      setMonitorStatus(0)
      props.onSetEncounterData({
        patient: null,
        triage_level: null
      })

      console.log("**********   history.push  ***************")
      // history.push({
      //   pathname: page + `${item.triage_level}/`,
      //   search: diag_rule ? `?diag_rule=${diag_rule}` : ""
      // });
      histroyPushWrapper(!props.noReactRouter && history,  {
        pathname: page + `${item.triage_level}/`,
        search: diag_rule ? `?diag_rule=${diag_rule}` : ""
      }, props.setState);
    }
  }

  const handleClickPin = async (item: any) => {

    if (!isTogglePin) {
      scrollHeight.current = triageLevelRef.current.scrollTop
    }
    const triageLevel = item.triageLevel || item.triage_level

    if (selectedTriage.triageLevel !== triageLevel) {
      const page = getCurrentPageURL()
      const { diag_rule } = getQueryParams()

      props.onSelectedPatient(null)

      setSelectedTriage({
        ...selectedTriage,
        ...item,
        // items: []
      })

      console.log("**********   history.push  ***************")
      // history.push({
      //   pathname: page + `${triageLevel}/`,
      //   search: diag_rule ? `?diag_rule=${diag_rule}` : ""
      // });
      histroyPushWrapper(!props.noReactRouter && history,  {
        pathname: page + `${triageLevel}/`,
        search: diag_rule ? `?diag_rule=${diag_rule}` : ""
      }, props.setState);
    }
    setIsTogglePin(!isTogglePin)
  }

  const handleSetSelectedPatient = async (chatList: any[], diagFormId: number) => {
    let findChat = chatList.find((item: { id: number; }) => item.id === +diagFormId)

    if (findChat) {

      const [patientChat, error] = await props.controller.prxManager.getProxyPatientHasDivisionPatientChat({
        apiToken: props.apiToken,
        divisionId: props.division,
        patientId: findChat.patient?.id
      })

      setIsLoadingTriage(false)
      if (patientChat?.patient_id) {
        findChat = {
          ...findChat,
          chat_channel: {
            id: patientChat.chat_channel,
            unread_message_count: patientChat.unread_message_count
          },
          encounters: patientChat?.encounter?.id ? [{
            id: patientChat?.encounter?.id,
            label: `[${patientChat?.encounter?.id} - ]  ${patientChat?.encounter?.label}`,
            default: true
          }] : []
        }
      }
      setTimeout(() => {
        console.log("**********   history.replace  ***************")
        // history.replace(props.getParamsHistory(findChat, { ...selectedTriage }))
        histroyReplaceWrapper(!props.noReactRouter && history,  props.getParamsHistory(findChat, { ...selectedTriage }), props.setState)
      });

      props.onSelectedPatient(findChat)
      const encounterDefault = findChat.encounters?.find((encounter: { default: boolean; }) => encounter.default === true)
      props.onSetEncounterId(encounterDefault?.id || "")
    } else {
      props.onSelectedPatient(null)
      // lazy load get หา patient ให้ตรงกัน
      if (props?.match?.params?.triageLevel === TRIAGE_LEVEL_END && isMountChatListScroll.current) {
        setIsLoadingTriage(true)
        getNextPatientNotFollow()
      }
      else {
        setIsLoadingTriage(false)
      }
      // setIsLoadingTriage(false)
    }

    props.onSetHideContentChat(false)
  }

  const handleClickPatientList = async (item: any, itemIndex: number) => {
    if (item.id === props.selectedPatient?.id) {
      return
    }
    props.onSetHideContentChat(true)
    // props.onSetLoadCardChatBox(true)
    const encounterDefault = item.encounters?.find((encounter: { default: boolean; }) => encounter.default === true)
    props.onSetEncounterId(encounterDefault?.id || "")

    const triageList = {
      ...selectedTriage,
      items: selectedTriage.items.map((triage) => triage.chat_channel?.id === item.chat_channel?.id || triage.chat_channel?.id === props.selectedPatient?.chat_channel?.id ?
        { ...triage, has_new_messages: false, chat_channel: { ...triage.chat_channel, unread_message_count: 0 } } : triage)
    }

    setSelectedTriage(triageList)
    setClassifyList((prev: any) => prev.map((item: any) => item.triage_level === selectedTriage.triageLevel ? triageList : item))

    console.log("**********   history.push  ***************")
    //
    // history.push(props.getParamsHistory(item))
    histroyPushWrapper(!props.noReactRouter && history,  props.getParamsHistory(item), props.setState);
    // setSTate 

    if (triageList.triageLevel === TRIAGE_LEVEL_END) {
      const chatList = await recursiveFindChat({ list: allChatList.current ? allChatList.current : triageList.items, id: item.id, triage_level: triageList.triageLevel, page })
      if (!inputSearchRef.current?.inputRef?.current?.value) {
        setSelectedTriage((prev) => ({ ...prev, items: chatList.list }))
      }
    }
  }

  const handleOnKeyPress = async (ev: { key: string; preventDefault: () => void; }) => {
    if (ev.key === "Enter") {
      ev.preventDefault()
      filterChatList()
    }
  }

  const handleCloseModInfo = () => {
    setOpenModInfo(modInfoInitial)
  }

  // Utils
  const onSetHistoryReplace = (diagRuleId: any, isClearParams = false) => {
    let pathname = location.pathname.replace(/\/$/i, "") + "/"
    if (isClearParams) {
      pathname = `/${history.location.pathname.split("/")[1]}/`
    }

    console.log("**********   history.replace  *************** props.controller:", props.controller)
    // history.replace({
    //   pathname,
    //   search: `?diag_rule=${diagRuleId}`
    // })
    histroyReplaceWrapper(!props.noReactRouter && history,  {
      pathname,
      search: `?diag_rule=${diagRuleId}`
    }, props.setState)

  }

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(history.location.search)
    const diag_rule = props.diag_rule || queryParams.get("diag_rule") || diagRule
    const vid_call = JSON.parse(queryParams.get("vid_call") || "false");

    return { diag_rule, vid_call }
  }

  const getCurrentPageURL = () => {
    const re = new RegExp(/^(\/[^/]*).*/);
    let match = re.exec(history.location.pathname);
    console.log('history.location.pathname: ', history.location.pathname);
    console.log('match: ', match);
    if (match) {
      return match[1] + "/";
    }
    return `/${history.location.pathname.split("/")[1]}/`;
  };

  const diagRuleOptions = useMemo(() => {
    const mapOption = (item: { id: any; name: any; }, index: any) => ({ key: index, value: item.id, text: item.name })
    return diagRuleList ? diagRuleList.map(mapOption) : []
  }, [diagRuleList])

  console.log(selectedTriage, classifyList)

  return <>
    <div ref={formRef}>
      <Form>
        <Form.Group>
          <Form.Field inline width={14}>
            <Dropdown
              selection
              fluid
              options={diagRuleOptions}
              value={diagRule}
              onChange={(e, { value }) => handleChangeDiagRule(value)}
            />
          </Form.Field>
          <Form.Field>
            {/* <Button
                style={{ backgroundColor: "#56CCF2", color: "white", width: "55px" }}
                onClick={() => setOpenModalShareLink(true)}
                disabled={!diagRule}
              >
                <Icon name="linkify" style={{ transform: "rotate(45deg)" }} />
              </Button> */}
            <PopupCopyLink
              open={openModalShareLink}
              diagRuleList={diagRuleList}
              diagRule={diagRule}
              onClose={() => setOpenModalShareLink(false)}
              onOpen={() => setOpenModalShareLink(true)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field inline width={13}>
            <Dropdown
              selection
              fluid
              options={MONITOR_STATUS_OPTIONS}
              value={monitorStatus}
              onChange={(e, { value }) => setMonitorStatus(value)}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <Form.Field inline width={!config.HIDE_CLASSIFY_BUTTON_NEW_PATIENT ? 14 : 13}>
            <Input
              ref={inputSearchRef}
              fluid
              placeholder="Search HN/ชื่อ นามสกุล"
              onChange={handleSearch}
              icon="search"
              loading={isLoadingSearch}
              onKeyPress={handleOnKeyPress}
            />
          </Form.Field>
          <Form.Field>
            {
              !config.HIDE_CLASSIFY_BUTTON_NEW_PATIENT &&
              <Button
                icon="plus"
                style={{ backgroundColor: "#0072bc", color: "white", width: "55px" }}
                onClick={handleAddNewPatient}
                disabled={!selectedTriage?.triageLevel}
              />
            }
          </Form.Field>
        </Form.Group>
      </Form>
    </div>

    <div className={`chat-classify-list${isTogglePin ? " full-screen" : ""}`} ref={classifyRef} style={{ height: `calc(100vh - ${formRef.current?.offsetHeight ? formRef.current?.offsetHeight + 70 : 175}px)` }}>
      <div className="left-list" ref={triageLevelRef} >
        {/* Triage */}
        {
          classifyList.map((item: any, index: number) =>
            item.active &&
            <Form
              key={"classify" + index}
              className={`classify-group${item.triage_level === selectedTriage.triageLevel ? " is_active" : ""}`}
              onClick={() => handleClickTriageLevel(item)}
              style={index === 0 && isTogglePin ? { width: "95%" } : { }}
            >
              <div style={{
                // transform: "rotate(45deg)",
                marginTop: "-5px",
                marginRight: "-3px",
                right: "5px",
                position: "absolute"
              }}
                onClick={() => {
                  isClickPin.current = true

                  // scrollHeightRef
                  handleClickPin(index === 0 && isTogglePin ? selectedTriage : item)
                }}
              >
                <svg style={{ transform: "scale(0.5)" }} width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.8271 8.11993L18.8696 0.529915C18.4296 0.0499146 17.7421 0.0499146 17.3021 0.529915C17.2196 0.619915 15.2121 2.86991 15.9821 5.80991L12.8471 9.22991C11.1146 8.65991 6.6871 7.66994 3.4146 11.2399C2.9746 11.7199 2.9746 12.4699 3.4146 12.9499L8.14459 18.1099L1.15958 25.7299C0.719583 26.2099 0.719583 26.9599 1.15958 27.4399C1.37958 27.6799 1.6546 27.7999 1.9296 27.7999C2.2046 27.7999 2.47958 27.6799 2.69958 27.4399L9.68458 19.8199L14.4146 24.9799C14.6346 25.2199 14.9096 25.3399 15.1846 25.3399C15.4596 25.3399 15.7346 25.2199 15.9546 24.9799C19.2271 21.41 18.3196 16.5499 17.7971 14.6899L20.9321 11.2699C23.6271 12.1099 25.6621 9.91991 25.7721 9.82991C26.2671 9.34991 26.2671 8.59993 25.8271 8.11993ZM21.2071 8.77991C20.7946 8.56991 20.2996 8.65993 19.9696 9.01993L15.8446 13.5199C15.5421 13.8499 15.4321 14.3899 15.5971 14.8099C15.5971 14.8099 16.0921 16.1599 16.1746 17.8999C16.2571 19.6399 15.9271 21.1099 15.1846 22.3399L10.5096 17.2399L5.83458 12.1399C8.77708 9.97994 12.5721 11.5999 12.7371 11.6899C13.1496 11.8699 13.6171 11.7799 13.9471 11.4199L18.0721 6.91993C18.4021 6.55993 18.4846 6.01993 18.2921 5.56993C17.9071 4.66993 18.0446 3.85991 18.2921 3.22991L23.3521 8.74992C22.7746 9.04992 22.0046 9.19991 21.2071 8.77991ZM14.6621 15.2899C14.6896 15.3799 15.0471 16.25 15.0196 17.1799C14.9921 17.75 14.5796 18.1999 14.0571 18.1999H14.0296C13.5071 18.1699 13.0671 17.6899 13.0946 17.1199C13.0946 16.7299 12.9571 16.2799 12.9021 16.1299C12.6821 15.5899 12.9021 14.9899 13.3971 14.7499C13.8921 14.5399 14.4696 14.7499 14.6621 15.2899Z"
                    fill={index === 0 && isTogglePin ? "rgb(255,224,0)" : "white"} />
                </svg>

              </div>
              <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                <div
                  className="content"
                  style={{ color: index === 0 && isTogglePin ? selectedTriage.codeColor : item.codeColor }}
                >
                  {/* 77 */}
                  {index === 0 && isTogglePin ? selectedTriage.description : item.description}
                  <div style={{ display: "grid", gridTemplateColumns: "52% 48%" }}>
                    <div style={{ paddingLeft: index === 0 && isTogglePin ? "5px" : "" }}>
                      ({index === 0 && isTogglePin ? selectedTriage.total : item.total})
                    </div>

                    {!(index === 0 && isTogglePin ? selectedTriage.hideTotalTracking : item.hideTotalTracking) &&
                      <DisplayTrackingStatus
                        flex={index === 0 && isTogglePin ? true : false}
                        totalMonitor={index === 0 && isTogglePin ? selectedTriage.totalMonitor : item.totalMonitor}
                        totalAssessment={index === 0 && isTogglePin ? selectedTriage.totalAssessment : item.totalAssessment}
                      />}

                  </div>
                </div>
              </div>
            </Form>
          )
        }
      </div>


      {/* Patient List */}
      <div className="items" ref={chatListRef}>
        <Dimmer active={isLoadingTriage} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        {
          !selectedTriage.items[0] || (selectedTriage?.triageLevel === TRIAGE_LEVEL_END && monitorStatus) ?
            <div
              style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
              <div style={{ background: "#f6f7f7", padding: "13px" }}>ไม่พบข้อมูล</div>
            </div> :

            selectedTriage.items.map((item: any, index) =>
              <>
                <div
                  key={"triage" + index}
                  className={`item${item.id === props.selectedPatient?.id ? " active" : ""}`}
                  onClick={() => handleClickPatientList(item, index)}
                  style={{ padding: "10px 10px 15px 5px" }}
                >
                  <div style={{ display: "grid", gridTemplateColumns: "10px auto" }}>
                    <div>
                      {item.chat_channel?.unread_message_count > 0 ? <div className='badge' /> : null}
                    </div>

                    <div>
                      <label>
                        {item.patient.fullname}
                      </label>
                      <div style={{ marginTop: "5px" }}>อายุ : {item.patient.age}</div>
                      <div>{item.classify ? `Classify : ${item.classify}` : `HN : ${item.patient.hn || ""}`}</div>
                    </div>
                  </div>
                  {selectedTriage.triageLevel !== TRIAGE_LEVEL_END &&
                    <div style={{ display: 'grid', gridTemplateColumns: "36% 64.1%", columnGap: "5px", marginTop: "-5px", marginBottom: ["WAITING", "NONE"].includes(item.tracking_status_name) ? "10px" : "", rowGap: "5px" }}>
                      <DisplayVitalSignStatus
                        value={item.bt}
                        type="1"
                        status={item.bt_status}
                        style={isTogglePin ? { padding: "6px 10px" } : { }}
                        tagOptions={tagOptions}
                        tagId="1"
                      />

                      <DisplayVitalSignStatus
                        value={item.oxygen_sat}
                        type="2"
                        status={item.oxygen_sat_status}
                        style={isTogglePin ? { padding: "6px 10px" } : { }}
                        tagOptions={tagOptions}
                        tagId="3"
                      />

                      <DisplayVitalSignStatus
                        value={item.pr}
                        type="1"
                        status={item.pr_status}
                        style={isTogglePin ? { padding: "6px 10px" } : { }}
                        tagOptions={tagOptions}
                        tagId="2"
                      />

                      <DisplayVitalSignStatus
                        value={item.oxygen_sat_step_test}
                        type="2"
                        status={item.oxygen_sat_step_test_status}
                        style={isTogglePin ? { padding: "6px 10px" } : { }}
                        tagOptions={tagOptions}
                        tagId="4"
                      />
                    </div>
                  }

                  {
                    ["WAITING", "NONE"].includes(item.tracking_status_name) &&
                    <div style={{ margin: "0px 0px", display: "flex", justifyContent: "flex-end", alignItems: "baseline" }}>
                      <Icon name="tag" style={{ color: item.tracking_status_name === "WAITING" ? COLOR.red : COLOR.orange }} />
                      <label style={{ ...styles.tagContent, backgroundColor: item.tracking_status_name === "WAITING" ? COLOR.red : COLOR.orange }}>{item.tracking_status_name === "WAITING" ? "ยังไม่ได้ monitor" : "ยังไม่ได้ทำแบบคัดกรอง"}</label>
                    </div>
                  }
                </div>
                {
                  index === selectedTriage.items.length - 1 &&
                  <div style={{ padding: "25px 0", position: "relative", display: isLoadingNextPatient ? "" : "none" }}>
                    <Loader active={isLoadingNextPatient} />
                  </div>
                }
              </>
            )
        }
      </div>
    </div>

    <ModInfo
      open={openModInfo.open}
      titleColor={openModInfo.color || "red"}
      titleName={openModInfo.title}
      onApprove={handleCloseModInfo}
      onClose={handleCloseModInfo}
    >
      {
        openModInfo.error === "string" ?
          <div>{openModInfo.error}</div> :
          <ErrorMessage error={openModInfo.error} />
      }
    </ModInfo>
  </>;
})


/// Util 

export const histroyPushWrapper = (history: any, params: any, setState: any) => {
  console.log('histroyPushWrapper history: ', history);
  console.log('histroyPushWrapper params: ', params);


  var query: any= {}
  if (params?.search) {
    const urlSearchParams = new URLSearchParams(params?.search);
    query = Object.fromEntries(urlSearchParams.entries());
  }

  let path = params?.pathname?.split("/")
  console.log('histroyPushWrapper path: ', path);


  let diag_rule = query?.diag_rule 
  let triageLevel = path?.[2] || null
  let diagFormId = path?.[3] || null

  if (history) {
    history?.replace(params);
  } else {

    console.log(" histroyPushWrapper setState ", {diag_rule,triageLevel,diagFormId}  )
    if (diag_rule || triageLevel || diagFormId ) {
      setState({ QAChatTable: {
        ...(diag_rule && { diag_rule: diag_rule}),
        ...(triageLevel && { triageLevel: triageLevel}),
        ...(diagFormId && { diagFormId: diagFormId})
      }})
    }
  }

}

export const histroyReplaceWrapper = (history: any, params: any, setState: any) => {
  console.log('histroyReplaceWrapper history: ', history);
  console.log('histroyReplaceWrapper params: ', params);


  var query: any= {}
  if (params?.search) {
    const urlSearchParams = new URLSearchParams(params?.search);
    query = Object.fromEntries(urlSearchParams.entries());
  }

  let path = params?.pathname?.split("/")
  console.log('histroyReplaceWrapper path: ', path);


  let diag_rule = query?.diag_rule 
  let triageLevel = path?.[2] || null
  let diagFormId = path?.[3] || null

  if (history) {
    history?.replace(params);
  } else {

    console.log(" histroyReplaceWrapper setState ", {diag_rule,triageLevel,diagFormId}  )
    if (diag_rule || triageLevel || diagFormId ) {
      setState({ QAChatTable: {
        ...(diag_rule && { diag_rule: diag_rule}),
        ...(triageLevel && { triageLevel: triageLevel}),
        ...(diagFormId && { diagFormId: diagFormId})
      }})
    }
  }
}

export default React.memo(CardPatientQueueChatAirflow)
