import CoreService from "../services/CoreService";
import { to, formatDropdown } from "../../utils";
import _ from "../../compat/lodashplus";
import { TPD } from "../../configs/apis";

export default class CoreManager {

  apiToken: String | null;

  constructor(apiToken: String | null) {
    this.apiToken = apiToken
  }

  // Encounter
  getEncounterStatus = async () => {
    var encounterStatusData = [];
    var encounterStatusError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getEncounterStatus());
    if (response) {
      encounterStatusData = response.items;
    } else {
      encounterStatusError = error;
    }
    return [encounterStatusData, encounterStatusError];
  };
  /**
   * get EN detail
   */
  getEncounter = async (encounterId: any, apiToken: any) => {
    var encounterData = [];
    var encounterError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getEncounter(encounterId, apiToken)
    );
    if (response) {
      encounterData = response;
    } else {
      encounterError = error;
    }
    return [encounterData, encounterError];
  };

  putEncounter = async ({
    apiToken,
    action,
    patientId,
    encounterId,
    type,
    triageLevel
  }: any= {}) => {
    let data:any = {};
    let params = {};
    var encounterData = [];
    var encounterError = null;
    const coreService = new CoreService(this.apiToken);
    if (type) {
      data.type = type;
    }
    if (action) {
      data.action = action;
    }
    if (patientId) {
      data.patient = patientId;
    }
    if (triageLevel) {
      data.triage_level = triageLevel;
    }

    const [error, response] = await to(
      coreService.putEncounterDetail({ data, params, encounterId, apiToken })
    );
    if (response) {
      encounterData = response;
    } else {
      encounterError = error;
    }
    return [encounterData, encounterError];
  };
  // National
  getNationalities = async ({ apiToken } : any = {}) => {
    const params = {}
    var nationalitiesData = [];
    var nationalitiesError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getnationalities(params, apiToken));
    if (response) {
      nationalitiesData = response.items;
    } else {
      nationalitiesError = error;
    }
    return [nationalitiesData, nationalitiesError];
  };
  // District
  getDistrict = async ({ apiToken, area }: any = {}) => {
    var districtData = [];
    var districtError = null;
    const coreService = new CoreService(this.apiToken);
    let params: any = {};
    if (area) {
      params.area = area;
    }
    const [error, response] = await to(coreService.getDistrict({ params, apiToken }));
    if (response) {
      districtData = response.items;
    } else {
      districtError = error;
    }
    return [districtData, districtError];
  };
  // City
  getCity = async ({ apiToken, area } = {}) => {
    var cityData = [];
    var cityError = null;
    const coreService = new CoreService(this.apiToken);
    let params = {};
    if (area) {
      params.area = area;
    }
    const [error, response] = await to(coreService.getCity({ params, apiToken }));
    if (response) {
      cityData = response.items;
    } else {
      cityError = error;
    }
    return [cityData, cityError];
  };

  getListPreName = async ({ apiToken, language, offset = null, limit = null } = {}) => {
    var data = [];
    var err = null;
    const coreService = new CoreService(this.apiToken);
    let params = {};
    if (language) {
      params.language = language;
    }
    if (offset) {
      params.offset = offset;
    }
    if (limit) {
      params.limit = limit;
    }
    const [error, response] = await to(coreService.getListPreName({ params, apiToken }));
    if (response) {
      data = response.items;
    } else {
      err = error;
    }
    return [data, err];
  };

  // Province
  getProvince = async ({ apiToken } = {}) => {
    var provinceData = [];
    var provinceError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getProvince({ apiToken }));
    if (response) {
      provinceData = response.items;
    } else {
      provinceError = error;
    }
    return [provinceData, provinceError];
  };
  // Country
  getCountry = async ({ apiToken } = {}) => {
    var countryData = [];
    var countryError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getCountry({ apiToken }));
    if (response) {
      countryData = response.items;
    } else {
      countryError = error;
    }
    return [countryData, countryError];
  };
  // Religion
  getReligion = async ({ apiToken } = {}) => {
    var religionData = [];
    var religionError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getReligion({ apiToken }));
    if (response) {
      religionData = response.items;
    } else {
      religionError = error;
    }
    return [religionData, religionError];
  };
  getConstance = async ({ list } = {}) => {
    let params = {};
    var constanceData = [];
    var constanceError = null;
    if (list) {
      params.list = list;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getConstance({ params }));
    if (response) {
      constanceData = response;
    } else {
      constanceError = error;
    }
    return [constanceData, constanceError];
  };
  // Choice
  getChoices = async ({ model, field, nameAsId, withEmptyItem } = {}) => {
    let params = {};
    var identificationDocData = [];
    var identificationDocError = null;
    const coreService = new CoreService(this.apiToken);
    if (model) {
      params.model = model;
    }
    if (field) {
      params.field = field;
    }
    if (nameAsId) {
      params.name_as_id = true;
    }
    if (withEmptyItem) {
      params.withEmptyItem = withEmptyItem;
    }
    const [error, response] = await to(coreService.getChoices({ params }));
    if (response) {
      const choices = _.map(response.items, item => ({
        id: item.id,
        text: item.label === undefined ? item.value : item.label,
        value: item.value
      }));
      if (withEmptyItem) {
        identificationDocData = [{ id: 0, text: "-", value: "" }, ...choices];
      } else {
        identificationDocData = choices;
      }
    } else {
      identificationDocError = error;
    }
    return [identificationDocData, identificationDocError];
  };
  /**
   * Get choices of episode type
   */
  getChoicesEpisodeType = async () => {
    var episodeTypeData = [];
    var episodeTypeError = null;
    let params = {
      model: "core.Episode",
      field: "episode_type",
      name_as_id: true
    };
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getChoices(params));
    if (error) {
      episodeTypeError = error;
    } else {
      if (response) {
        const choices = _.map(response.items, item => ({
          id: item.id,
          text: item.label === undefined ? item.value : item.label,
          value: item.value
        }));
        episodeTypeData = choices;
      }
    }
    return [episodeTypeData, episodeTypeError];
  };
  // Episode
  /**
   * Create episode
   */
  createEpisode = async (
    patientId,
    name,
    { episodeType = "", description = "" } = {}
  ) => {
    let params = {
      patient: patientId,
      episode_type: episodeType,
      name: name,
      description: description
    };
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.createEpisode(params));
    return [response, error];
  };
  /** Get Episode with episode id */
  getEpisode = async episodeId => {
    var episodeData = [];
    var episodeError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getEpisode(episodeId));
    if (response) {
      episodeData = response;
    } else {
      episodeError = error;
    }
    return [episodeData, episodeError];
  };
  /**
   * Get Episode with encounter ID
   */
  getEpisodeFromEN = async encounterId => {
    var episodeData = {};
    var episodeError = null;
    if (!encounterId) {
      return [episodeData, episodeError];
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getEpisodeFromEN(encounterId)
    );
    if (response) {
      episodeData = response;
    } else {
      episodeError = error;
    }
    return [episodeData, episodeError];
  };
  /**
   * Search list of episode
   */
  getEpisodeList = async ({
    patientId = "",
    createdStartDate = "",
    createdEndDate = "",
    doctorNameCode = "",
    id = "",
    name = ""
  }) => {
    var params = {
      patient: patientId,
      created_start_date: createdStartDate,
      created_end_date: createdEndDate,
      doctor_name_code: doctorNameCode,
      id: id,
      name: name
    };
    var episodeData = [];
    var episodeError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getEpisodeList(params));
    if (response) {
      episodeData = response.items;
    } else {
      episodeError = error;
    }
    return [episodeData, episodeError];
  };
  /**
   * Change episode
   */
  changeENToEpisode = async (encounterId, episodeId) => {
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.changeEncounterToEpisode(encounterId, episodeId)
    );
    return [response, error];
  };
  /**
   * Cancel encounter from episode
   */
  cancelENFromEpisode = async encounterId => {
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.changeEncounterToEpisode(encounterId, null)
    );
    return [response, error];
  };
  /**
   * Get encounter list with episode ID
   */
  getENListFromEpisode = async episodeId => {
    var enListData = [];
    var enError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getENListFromEpisode(episodeId)
    );
    if (response) {
      enListData = response;
    } else {
      enError = error;
    }
    return [enListData, enError];
  };
  // Doctor
  /**
   * Search list of doctor
   */
  getDoctorList = async ({
    nameCode,
    specialty,
    offset,
    limit,
    search
  } = {}) => {
    let params = {};
    if (nameCode) {
      params.name_code = nameCode;
    }
    if (specialty) {
      params.specialty = specialty;
    }
    if (offset) {
      params.offset = offset;
    }
    if (limit) {
      params.limit = limit;
    }
    var doctorListData = [];
    var doctorListError = null;
    var numberOfPage = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getDoctor(params));
    if (response) {
      doctorListData = response.items;
      if (params.limit) {
        numberOfPage = Math.ceil(response.total / params.limit);
      }
    } else {
      doctorListError = error;
    }
    return [doctorListData, doctorListError, numberOfPage];
  };
  /**
   * Search list of doctor, show only NameCode and id only
   */
  getDoctorNameCodeList = async ({ nameCode, limit = 20 } = {}) => {
    let params = {};
    if (nameCode) {
      params.name_code = nameCode;
    }
    if (limit) {
      params.limit = limit;
    }
    var doctorListData = [];
    var doctorListError = null;
    var numberOfPage = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(coreService.getDoctor(params));
    // transform data
    if (response) {
      const addKey = e => {
        let a = _.set(e, "key", e.id);
        let b = _.set(a, "title", e.name_code);
        let c = _.set(b, "value", e.name_code);
        return c;
      };
      doctorListData = _.map(response.items, addKey);
      if (params.limit) {
        numberOfPage = Math.ceil(response.total / params.limit);
      }
    } else {
      doctorListError = error;
    }
    return [doctorListData, doctorListError, numberOfPage, network];
  };
  // Get doctor's specialty
  getSpeciality = async () => {
    console.log("getSpeciality");
    var specialtyData = {};
    var specialtyError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(coreService.getSpeciality());
    if (response) {
      specialtyData = response.items;
    } else {
      specialtyError = error;
    }
    return [specialtyData, specialtyError, network];
  };
  // Progression Cycle
  getProgressionCycleByEmr = async params => {
    var progressionData = [];
    var progressionError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getProgressionCycle(params));
    if (response) {
      progressionData = response;
    } else {
      progressionError = error;
    }
    return [progressionData, progressionError];
  };
  getProgressionCycle = async ({ emrId } = {}) => {
    var progressionData = [];
    var progressionError = null;
    let params = {};
    const coreService = new CoreService(this.apiToken);
    if (emrId) {
      params.emr = emrId;
    }
    const [error, response] = await to(coreService.getProgressionCycle(params));
    if (response) {
      progressionData = response;
    } else {
      progressionError = error;
    }
    return [progressionData, progressionError];
  };
  // ClinicalTerm Set
  getClinicalTermSet = async ({ questionList }) => {
    let data = {};
    var clinicalTermSet = [];
    var clinicalTermSetError = null;
    const coreService = new CoreService(this.apiToken);
    if (questionList) {
      data.question = questionList;
    }
    const [error, response] = await to(
      coreService.postClinicalTermSet({ data })
    );
    if (response) {
      clinicalTermSet = response;
    } else {
      clinicalTermSetError = error;
    }
    return [clinicalTermSet, clinicalTermSetError];
  };
  getClinicalTerm = async ({ type }) => {
    let params = {};
    var clinicalTerm = [];
    var clinicalTermError = null;
    const coreService = new CoreService(this.apiToken);
    if (type) {
      params.type = type;
    }
    const [error, response] = await to(coreService.getClinicalTerm(params));
    if (response) {
      clinicalTerm = response.items;
    } else {
      clinicalTermError = error;
    }
    return [clinicalTerm, clinicalTermError];
  };

  putEncounter = async ({ type, action, encounterId, patientId } = {}) => {
    let data = {};
    let params = {};
    var encounterData = [];
    var encounterError = null;
    const coreService = new CoreService(this.apiToken);
    if (type) {
      data.type = type;
    }
    if (action) {
      data.action = action;
    }
    if (patientId) {
      data.patient = patientId;
    }
    const [error, response] = await to(
      coreService.putEncounterDetail({ data, params, encounterId })
    );
    if (response) {
      encounterData = response;
    } else {
      encounterError = error;
    }
    return [encounterData, encounterError];
  };

  // Barcode
  getBarcodePrefix = async () => {
    var barcodePrefixData = [];
    var barcodePrefixError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getBarcodePrefix());
    if (response) {
      barcodePrefixData = response;
    } else {
      barcodePrefixError = error;
    }
    return [barcodePrefixData, barcodePrefixError];
  };
  getDefaultBarcode = async barcode => {
    var defaultBarcodeData = [];
    var defaultBarcodeError = null;
    if (barcode) {
      const coreService = new CoreService(this.apiToken);
      const [error, response] = await to(
        coreService.getDefaultBarcode(barcode)
      );
      if (response) {
        defaultBarcodeData = response;
      } else {
        defaultBarcodeError = error;
      }
    }
    return [defaultBarcodeData, defaultBarcodeError];
  };
  getRejectedOrder = async () => {
    var rejectOrderData = [];
    var rejectOrderError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getRejectedOrder());
    if (response) {
      rejectOrderData = response;
    } else {
      rejectOrderError = error;
    }
    return [rejectOrderData, rejectOrderError];
  };
  getCleaningChoices = async () => {
    var cleaningData = [];
    var cleaningError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getCleaningChoice());
    if (response) {
      cleaningData = response.items;
    } else {
      cleaningError = error;
    }
    return [cleaningData, cleaningError];
  };
  getCareer = async () => {
    var careerData = [];
    var careerError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getCareer());
    if (response) {
      careerData = response.items;
    } else {
      careerError = error;
    }
    return [careerData, careerError];
  };
  // ICD 10
  /**
   * Get ICD10 list with ICD_CODE
   */
  getICD10ListWithCode = async icdCode => {
    var icd10Data = [];
    var icd10Error = null;
    var params = { icdcode: icdCode };
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getICD10ListWithCode(params)
    );
    if (response) {
      icd10Data = response;
    } else {
      icd10Error = error;
    }
    return [icd10Data, icd10Error];
  };
  getICD9CMListWithCode = async icdCode => {
    var icd9cmData = [];
    var icd9cmError = null;
    var params = { icdcode: icdCode };
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getICD9CMListWithCode(params)
    );
    if (response) {
      icd9cmData = response;
    } else {
      icd9cmError = error;
    }
    return [icd9cmData, icd9cmError];
  };
  /**
   * Get ICD10 list with ICD_TERM
   */
  getICD10ListWithTerm = async icdTerm => {
    var icd10Data = [];
    var icd10Error = null;
    var params = { icdterm: icdTerm };
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getICD10ListWithTerm(params)
    );
    if (response) {
      icd10Data = response;
    } else {
      icd10Error = error;
    }
    return [icd10Data, icd10Error];
  };
  getICD9CMListWithTerm = async icdTerm => {
    var icd9cmData = [];
    var icd9cmError = null;
    var params = { icdterm: icdTerm };
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getICD9CMListWithTerm(params)
    );
    if (response) {
      icd9cmData = response;
    } else {
      icd9cmError = error;
    }
    return [icd9cmData, icd9cmError];
  };
  /**
   * Get ICD10 list with ICD_TERM
   */
  getICD10ListWithMedTerm = async medTerm => {
    var icd10Data = [];
    var icd10Error = null;
    var params = { medterm: medTerm };
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getICD10ListWithMedTerm(params)
    );
    if (response) {
      icd10Data = response;
    } else {
      icd10Error = error;
    }
    return [icd10Data, icd10Error];
  };
  getICD9CMListWithMedTerm = async medTerm => {
    var icd9cmData = [];
    var icd9cmError = null;
    var params = { medterm: medTerm };
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getICD9CMListWithMedTerm(params)
    );
    if (response) {
      icd9cmData = response;
    } else {
      icd9cmError = error;
    }
    return [icd9cmData, icd9cmError];
  };
  getMiscellaneous = async ({
    groupCode,
    name,
    encounterId,
    limit,
    offset
  }) => {
    let miscellaneousData = [];
    let miscellaneousError = null;
    let params = {};
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (name) {
      params.name = name;
    }
    if (groupCode) {
      params.group_code = groupCode;
    }
    if (limit) {
      params.limit = limit;
    }
    if (offset) {
      params.offset = offset;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getMiscellaneous(params));
    if (response) {
      miscellaneousData = response;
    } else {
      miscellaneousError = error;
    }
    return [miscellaneousData, miscellaneousError];
  };
  /**
   * Get Sublevel list of ICD10 with ICD_CODE
   */
  getICD10SublevelListWithCode = async icdCode => {
    var icd10Data = [];
    var icd10Error = null;
    var params = { icdcode: icdCode };
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getICD10SublevelListWithCode(params)
    );
    if (response) {
      icd10Data = response;
    } else {
      icd10Error = error;
    }
    return [icd10Data, icd10Error];
  };
  getICD9CMSublevelListWithCode = async icdCode => {
    var icd9cmData = [];
    var icd9cmError = null;
    var params = { icdcode: icdCode };
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getICD9CMSublevelListWithCode(params)
    );
    if (response) {
      icd9cmData = response;
    } else {
      icd9cmError = error;
    }
    return [icd9cmData, icd9cmError];
  };
  postMiscellaneousOrderPreview = async ({ params, data } = {}) => {
    var miscellaneousOrderPreviewData = [];
    var miscellaneousOrderPreviewError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.postMiscellaneousOrderPreview({ params, data })
    );
    if (response) {
      miscellaneousOrderPreviewData = response.items;
    } else {
      miscellaneousOrderPreviewError = error;
    }
    return [miscellaneousOrderPreviewData, miscellaneousOrderPreviewError];
  };
  getMiscellaneousOrder = async ({
    encounterId,
    active,
    orderBy,
    fromDate,
    toDate,
    offset,
    limit,
    apiToken
  }) => {
    var miscellaneousOrderData = [];
    var miscellaneousOrderError = null;
    var params = {};
    if (encounterId) {
      params.encounter_id = encounterId;
    }
    if (active) {
      params.active = active;
    }
    if (orderBy) {
      params.order_by = orderBy;
    }
    if (fromDate) {
      params.from_date = fromDate;
    }
    if (toDate) {
      params.to_date = toDate;
    }
    if (offset) {
      params.offset = offset;
    }
    if (limit) {
      params.limit = limit;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getMiscellaneousOrder({ apiToken, params })
    );
    if (response) {
      miscellaneousOrderData = response;
    } else {
      miscellaneousOrderError = error;
    }
    return [miscellaneousOrderData, miscellaneousOrderError];
  };

  deleteMiscellaneousOrder = async ({ id, apiToken } = {}) => {
    var miscellaneousOrderData = [];
    var miscellaneousOrderError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.deleteMiscellaneousOrder({ id, apiToken })
    );
    if (response) {
      miscellaneousOrderData = response.items;
    } else {
      miscellaneousOrderError = error;
    }
    return [miscellaneousOrderData, miscellaneousOrderError];
  };

  patchMiscellaneousOrder = async ({ id, apiToken, data } = {}) => {
    let params = {}
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.patchMiscellaneousOrder({ id, apiToken, data, params })
    );
    return [response, error];
  };

  postMiscellaneousOrderCreate = async ({ data } = {}) => {
    var saveMiscellaneousOrderData = [];
    var saveMiscellaneousOrderError = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.postMiscellaneousOrderCreate({ data })
    );
    if (response) {
      saveMiscellaneousOrderData = response;
    } else {
      saveMiscellaneousOrderError = error;
    }
    return [saveMiscellaneousOrderData, saveMiscellaneousOrderError];
  };
  /**
   * Get division list
   */
  getDivision = async ({ limit, parent, apiToken, isTelemed, forIpdEncounter, organization }: any = {}) => {
    let params = {};
    if (limit) {
      params.limit = limit;
    }
    if (parent) {
      params.parent = parent;
    }
    if (isTelemed || isTelemed === false) {
      params.is_telemed = isTelemed;
    }
    if (forIpdEncounter) {
      params.for_ipd_encounter = forIpdEncounter;
    }
    if (organization){
      params.organization = organization
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getDivision(params, apiToken));
    return [response, error];
  };

  getDivisionDetail = async ({ divisionId, apiToken } = {}) => {
    let params = {};
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getDivisionDetail({ divisionId, params, apiToken }))
    return [response, error];
  }

  /**
   * Get division for OPD encounter
   */
  getDivisionForOPD = async () => {
    const coreService = new CoreService(this.apiToken);
    var params = { for_opd_encounter: true };
    const [error, response] = await to(coreService.getDivision(params));
    return [response, error];
  };
  /**
   * Convert division list to choice list
   */
  getDivisionChoice = async () => {
    const [data, error] = await this.getDivision();
    var choices = [];
    if (data.items) {
      choices = _.map(data.items, item => ({
        id: item.id,
        text: item.name,
        value: item.code
      }));
    }
    return [choices, error];
  };
  /**
   * Convert division list for OPD encounter to choice list
   */
  getDivisionForOPDChoice = async () => {
    const [data, error] = await this.getDivisionForOPD();
    var choices = [];
    if (data.items) {
      choices = _.map(data.items, item => ({
        id: item.id,
        text: item.name_code,
        value: item.code
      }));
    }
    return [choices, error];
  };
  /**
   * Get doctor list
   */
  getDoctorChoice = async () => {
    const [data, error, numberOfPage] = await this.getDoctorList();
    var choices = [];
    if (data) {
      choices = _.map(data, item => ({
        id: item.id,
        text: item.name_code,
        value: item.code
      }));
    }
    return [choices, error];
  };
  getCurrentDoctor = async () => {
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getCurrentDoctor());
    return [response, error];
  };
  deleteProgressionCycle = async ({ progressionId } = {}) => {
    var data = [];
    var errorData = null;
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.deleteProgressionCycle({ progressionId })
    );
    if (response) {
      data = response;
    } else {
      errorData = error;
    }
    return [data, errorData];
  };
  postProgressionCycle = async ({ emrId } = {}) => {
    var progressionData = [];
    var errorData = null;
    const coreService = new CoreService(this.apiToken);
    let data = {};
    if (emrId) {
      data.emr = emrId;
    }
    const [error, response] = await to(
      coreService.postProgressionCycle({ data })
    );
    if (response) {
      progressionData = response;
    } else {
      errorData = error;
    }
    return [progressionData, errorData];
  };
  putProgressionCycle = async ({
    emrId,
    progressionNote,
    progressionId
  } = {}) => {
    var progressionData = [];
    var errorData = null;
    const coreService = new CoreService(this.apiToken);
    let data = {};
    if (emrId) {
      data.emr = emrId;
    }
    if (progressionNote) {
      data.progression_note = progressionNote;
    }
    const [error, response] = await to(
      coreService.putProgressionCycle({ data, progressionId })
    );
    if (response) {
      progressionData = response;
    } else {
      errorData = error;
    }
    return [progressionData, errorData];
  };
  getEncounterSearch = async ({
    search,
    encounterId,
    patientId,
    limit,
    offset
  } = {}) => {
    let params = {};
    if (search) {
      params.search = search;
    }
    if (encounterId) {
      params.id = encounterId;
    }
    if (patientId) {
      params.patient = patientId;
    }
    if (limit) {
      params.limit = limit;
    }
    if (offset) {
      params.offset = offset;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.getEncounterSearch({ params })
    );
    return [response, error];
  };
  getDocumentType = async ({
    limit,
    category,
    name,
    division,
    printable,
    offset,
    code
  } = {}) => {
    let params = {};
    if (limit) {
      params.limit = limit;
    }
    if (offset) {
      params.offset = offset;
    }
    if (category) {
      params.category = category;
    }
    if (name) {
      params.name = name;
    }
    if (division) {
      params.division = division;
    }
    if (printable) {
      params.printable = printable;
    }
    if (code) {
      params.code = code;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getDocumentType(params));
    return [response, error];
  };
  getDocumentCategory = async ({ limit } = {}) => {
    let params = {};
    if (limit) {
      params.limit = limit;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getDocumentCategory(params));
    return [response, error];
  };
  getDoctor = async ({ search } = {}) => {
    let params = {};
    if (search) {
      params.search = search;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getDoctor(params));
    return [response, error];
  };
  getScannedDocument = async ({
    patientHn,
    encounterId,
    documentType,
    documentNo,
    scanDivision,
    scanUser,
    ownerDivision,
    encounterDoctor,
    docFromDate,
    docToDate,
    scanFromDate,
    scanToDate,
    expiryFromDate,
    expiryToDate,
    offset,
    limit
  } = {}) => {
    let params = {};
    if (patientHn) {
      params.patient__hn = patientHn;
    }
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (documentType) {
      params.document_type = documentType;
    }
    if (documentNo) {
      params.document_no = documentNo;
    }
    if (scanDivision) {
      params.scan_division = scanDivision;
    }
    if (scanUser) {
      params.scan_user = scanUser;
    }
    if (ownerDivision) {
      params.owner_division = ownerDivision;
    }
    if (encounterDoctor) {
      params.encounter__doctor = encounterDoctor;
    }
    if (docFromDate) {
      params.doc_date_from = docFromDate;
    }
    if (docToDate) {
      params.doc_date_to = docToDate;
    }
    if (scanFromDate) {
      params.scan_date_from = scanFromDate;
    }
    if (scanToDate) {
      params.scan_date_to = scanToDate;
    }
    if (expiryFromDate) {
      params.expiry_date_from = expiryFromDate;
    }
    if (expiryToDate) {
      params.expiry_date_to = expiryToDate;
    }
    if (offset) {
      params.offset = offset;
    }
    if (limit) {
      params.limit = limit;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getScannedDoc(params));
    return [response, error];
  };
  putScannedDocumentUpdateSecret = async ({
    username,
    password,
    items
  } = {}) => {
    let data = {};
    let params = {};
    if (username) {
      data.username = username;
    }
    if (password) {
      data.password = password;
    }
    if (items) {
      data.items = items;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.putScannedDocumentUpdateSecret({ params, data })
    );
    return [response, error];
  };
  patchScannedDocument = async ({
    username,
    password,
    cancelNote,
    scanDocId,
    active,
    documentType,
    documentNo,
    version,
    ownerDivision,
    documentDate,
    expiryDate,
    remark
  } = {}) => {
    let data = {};
    let params = {};
    if (username) {
      data.username = username;
    }
    if (password) {
      data.password = password;
    }
    if (cancelNote) {
      data.cancel_note = cancelNote;
    }
    if (active || active === false) {
      data.active = active;
    }
    if (documentType) {
      data.document_type = documentType;
    }
    if (documentNo) {
      data.document_no = documentNo;
    }
    if (version) {
      data.version = version;
    }
    if (ownerDivision) {
      data.owner_division = ownerDivision;
    }
    if (documentDate) {
      data.document_date = documentDate;
    }
    if (expiryDate) {
      data.expiry_date = expiryDate;
    }
    if (remark) {
      data.remark = remark;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.patchScannedDocument({ params, data, scanDocId })
    );
    return [response, error];
  };
  postScannedDocument = async ({ data } = {}) => {
    let saveData = {};
    let params = {};
    if (data) {
      saveData = data;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.postScannedDocument({ params, data })
    );
    return [response, error];
  };
  /**
   * DPO Checkout choices
   */
  getChoicesCheckoutCause = async () => {
    let params = {
      model: "core.MedicalRecord",
      field: "checkout_cause",
      nameAsId: false
    };
    return await this.getChoices(params);
  };
  /**
   * Get zone with division ID
   */
  getZoneOfDivision = async divisionId => {
    var zoneData = [];
    var zoneError = null;
    let params = {
      type: "ZONE",
      parent: divisionId
    };
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(coreService.getDivision(params));
    if (error) {
      zoneError = error;
    } else {
      zoneData = response.items;
    }
    return [zoneData, zoneError];
  };
  changeZone = async (encounterId, { zoneId } = {}) => {
    var data = { encounter: encounterId };
    if (zoneId) {
      data.zone = zoneId;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response] = await to(
      coreService.changeZone(encounterId, data)
    );
    return [response, error];
  };
  getEncounterSearchDetail = async ({ encounterId } = {}) => {
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.getEncounterSearchDetail({ encounterId })
    );
    return [response, error, network];
  };
  getTaskTrackingResult = async ({ taskId, action } = {}) => {
    let params = {};
    if (taskId) {
      params.task_id = taskId;
    }
    if (action) {
      params.action = action;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.getTaskTrackingResult({ params })
    );
    return [response, error, network];
  };
  /**
   * Choices of bill transaction type for IPD (sent claim)
   */
  getChoicesBillTransactionTypeIPD = async () => {
    let params = {
      model: "INF.BillTransactionIPD",
      field: "submit_type",
      nameAsId: false
    };
    return await this.getChoices(params);
  };
  getChoicesAdmitOrderType = async () => {
    let params = {
      model: "ADM.AdmitOrder",
      field: "admit_type",
      nameAsId: false
    };
    return await this.getChoices(params);
  };

  getEncounterPatient = async ({
    fromDate,
    toDate,
    hn,
    name,
    excludeCanceled
  }) => {
    let params = {};
    if (excludeCanceled) {
      params.exclude_canceled = excludeCanceled;
    }
    if (fromDate) {
      params.from_date = fromDate;
    }
    if (toDate) {
      params.to_date = toDate;
    }
    if (hn) {
      params.patient_hn = hn;
    }
    if (name) {
      params.patient_name = name;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.getEncounterPatient({ params })
    );
    return [response, error, network];
  };

  getProduct = async ({
    apiToken,
    name,
    code,
  }) => {
    let params = {};
    if (name) {
      params.name__icontains = name;
    }
    if (code) {
      params.code__icontains = code;
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.getProduct({ params, apiToken })
    );
    return [response, error, network];
  };

  postMiscellaneousOrderListCreate = async ({
    apiToken,
    orderList,
    division
  }) => {
    let params = {};
    let data = {};
    if (orderList) {
      data.miscellaneous_orders = orderList
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.postMiscellaneousOrderListCreate({ params, apiToken, data, division })
    );
    return [response, error, network];
  };

  postPatientAddress = async ({ apiToken, data } = {}) => {
    let params = {}
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.postPatientAddress({ params, apiToken, data })
    );
    return [response, error, network];
  }

  getPatientAddressList = async ({ apiToken, patientId, defaultAddress } = {}) => {
    let params = {}
    if (patientId) {
      params.patient = patientId
    }
    if (defaultAddress) {
      params.default = defaultAddress
    }
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.getPatientAddressList({ params, apiToken })
    );
    return [response, error, network];
  }

  getPatientAddressDetail = async ({ apiToken, patientAddressId } = {}) => {
    let params = {}
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.getPatientAddressDetail({ params, apiToken, patientAddressId })
    );
    return [response, error, network];
  }

  putPatientAddressDetail = async ({ apiToken, data, patientAddressId } = {}) => {
    let params = {}
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.putPatientAddressDetail({ params, apiToken, data, patientAddressId })
    );
    return [response, error, network];
  }

  deletePatientAddressDetail = async ({ apiToken, data, patientAddressId } = {}) => {
    let params = {}
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.deletePatientAddressDetail({ params, apiToken, patientAddressId })
    );
    return [response, error, network];
  }

  /** For ANS Module */
  getChoicesANSOrderStatus = async () => {
    let params = {
      model: "ANS.AnesthesiaOrder",
      field: "status",
      nameAsId: true
    };
    return await this.getChoices(params);
  };
  getDoctorOrderDetail = async ({ id } = {}) => {
    let params = {};
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.getDoctorOrderDetail({ params, id })
    );
    return [response, error, network];
  };

  getEncounterPatientOptimized = async ({ params }) => {
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.getEncounterPatientOptimized({ params })
    );
    return [response, error, network];
  }

  getPatientDiagnosisList = async (params) => {
    const coreService = new CoreService(this.apiToken);
    const [error, response, network] = await to(
      coreService.getPatientDiagnosisList(params)
    );
    return [response, error, network];
  }
}
