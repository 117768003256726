import moment from "moment";

// Util for typescript
export const convertToThaiDate = ({
  date,
  display,
  options
}: {
  date: string | moment.Moment;
  display?: string;
  options?: {
    weekday?: string;
    year?: string;
    month?: string;
    day?: string;
  };
}): string => {
  let newDate: any = date;

  if (moment.isMoment(date)) {
    newDate = new Date(date.format("MM/DD/YYYY"));
  } else {
    return newDate.toString();
  }
  if (display === "time" && newDate instanceof Date) {
    return (
      newDate
        .getHours()
        .toString()
        .padStart(2, "0") +
      ":" +
      newDate
        .getMinutes()
        .toString()
        .padStart(2, "0")
    );
  } else if (display === "monthDate" && newDate instanceof Date) {
    return newDate
      .toDateString()
      .split(" ")
      .slice(1, -1)
      .join(" ");
  } else if (display === "date" && newDate instanceof Date) {
    return newDate.toDateString();
  } else if (display === "localeDate" && newDate instanceof Date) {
    return newDate.toLocaleDateString("th-TH", options);
  } else if (display === "localeDay" && newDate instanceof Date) {
    return newDate.toLocaleDateString("th-TH", {
      weekday: "short"
    });
  } else {
    return newDate.toString();
  }
};

export const convertToBEDate = ({
  date,
  endFormat = "DD/MM/YYYY",
  startFormat = "DD/MM/YYYY"
}: {
  date: string;
  endFormat?: string;
  startFormat?: string;
}): string => {
  // This function only use to convert BE to AD
  return moment(date, startFormat)
    .add(543, "years")
    .format(endFormat);
};

export const convertToADDate = ({
  date,
  endFormat = "DD/MM/YYYY",
  startFormat = "DD/MM/YYYY"
}: {
  date: string;
  endFormat?: string;
  startFormat?: string;
}): string => {
  // This function only use to convert BE to AD
  return moment(date, startFormat)
    .subtract(543, "years")
    .format(endFormat);
};

// SERIAL
const TIMEZERO = Date.parse(new Date("2017-01-01T00:00:00Z").toString()); // 1483228800000
const SERIAL_ROUNDING = 1000 * 60 * 15;

export const convertBEDateToSerial = ({
  date,
  time,
  format = "DD/MM/YYYY",
}: {
  date: string;
  time?: string;
  format: string;
}) => {
  let beDate = moment(date, format).format("DD/MM/YYYY").split("/")
  let year = parseInt(beDate[2]);
  let month = parseInt(beDate[1]) - 1;
  let day = parseInt(beDate[0]);
  let hour = 0;
  let min = 0;
  let sec = 0;
  let ms = 0;
  if (time) {
    let timeArr = time.split(":");
    hour = parseInt(timeArr[0]);
    min = timeArr[1] ? parseInt(timeArr[1]) : 0;
    sec = timeArr[2] ? parseInt(timeArr[2]) : 0;
    ms = timeArr[3] ? parseInt(timeArr[3]) : 0;
  }
  let newDate = Date.parse(
    new Date(year, month, day, hour, min, sec, ms).toString()
  );
  return Math.round((newDate - TIMEZERO) / SERIAL_ROUNDING);
};

export const convertSerialToMomentDate = ({ serial }: { serial: number }) => {
  return moment(new Date(TIMEZERO + serial * 1000 * 60 * 15))
}

export function formatComma(val: string) {
  return !isNaN(parseFloat(val)) ? parseFloat(val).toFixed(2).toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",") : val;
}