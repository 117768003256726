import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ModConfirm, ErrorMessage } from "../common";
import { Form, Input } from "semantic-ui-react";

const ModAuthen = React.forwardRef((props: any, ref) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(null)



  useEffect(() => {
    if (props.error) {
      setError(props.error);
    }
  }, [props.error]);


  useEffect(() => {
    if (props.open) {
      setUsername("");
      setPassword("");
      setError(null);
    }
  }, [props.open]);


  React.useImperativeHandle(ref, () => ({
    clear: () => {
      setUsername("");
      setPassword("");
      setError(null);
    }
  }));

  return (
    <ModConfirm
      titleColor={props.titleColor}
      backgroundColor={props.backgroundColor}
      openModal={props.open}
      titleName={props.titleName}
      onDeny={props.onDeny}
      onCloseWithDimmerClick={props.onCloseWithDimmerClick}
      onApprove={() => props.onApprove({ username, password })}
      loading={props.loading}
      approveButtonText={props.approveButtonText}
      denyButtonText={props.denyButtonText}
      modalStyle={props.style}
      content={
        <Form error={error ? true : false} onSubmit={props.approveButtonText}>
          <ErrorMessage error={error} />
          <Form.Field label="Username" error={error ? true : false} />
          <Form.Field error={error ? true : false}>
            <Input
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Field>
          <Form.Field label="Password" error={error ? true : false} />
          <Form.Field error={error ? true : false}>
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Field>
          {props.children}
        </Form>
      }
    />
  );
});

ModAuthen.defaultProps = {
  open: false,
  titleName: "กรุณายืนยัน Username และ Password อีกครั้ง",
  onDeny: () => {},
  error: null,
  onApprove: () => {},
  loading: false,
  style: {},
};

ModAuthen.propTypes = {
  open: PropTypes.bool,
  titleName: PropTypes.string,
  onDeny: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onApprove: PropTypes.func,
  headerColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  loading: PropTypes.bool,
  style: PropTypes.object,
};

export default React.memo(ModAuthen);
