import React, {
  useRef,
  MutableRefObject,
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  useMemo
} from 'react'
import {
  Form,
  Dimmer,
  Loader,
  Button,
  Input,
  Dropdown,
  Popup,
  Icon
} from "semantic-ui-react"
import { useHistory, useLocation } from "react-router-dom";

// Controller
import ChatController from "react-lib/apps/IsHealth/Chat/ChatController"

import config from "../../../../config/config";

// Manager
import REGManager from "react-lib/apis/manager/REGManager"

// Common
import {
  ModInfo,
  ErrorMessage,
} from "react-lib/apps/common";

import { NEW_PATIENT_DATA, DropdownType, ModInfoType, modInfoInitial, MONITOR_STATUS_OPTIONS } from "./QAChatTable"

type CardPatientQueueChatProps = {
  setState: any;
  getState: any;
  division: number;
  controller: ChatController & {
    regManager: REGManager
  };
  apiToken: string;
  selectedPatient: any
  match: any;
  chatBoxRef: any;
  storage?: any;

  // Callback
  getParamsHistory: (item: any, triage?: any) => { pathname: string; search: string };
  onSelectedPatient: (item: any) => void;
  onActiveItem: (is: boolean) => void;
  onSetEncounterId: (id: string) => void;
  onSetEncounterData: (data: { patient: any; triage_level: string | null }) => void;
  onSetLoadCardChatBox: (loading: boolean) => void;
  onSetActiveItem: (value: any) => void;
  onSetHideContentChat: (value: boolean) => void;
  onGetPatientDetail?: () => void;
}

type PopupCopyLinkProps = {
  open: boolean;
  diagRuleList: any[];
  diagRule: number;
  onOpen?: () => void;
  onClose?: () => void
}

type SelectedTriageType = {
  triageLevel?: string,
  description?: string,
  total?: 0,
  items: any[]
  codeColor?: string;
  active?: boolean
}

const NEW_PATIENT = "new_patient"

export type CardPatientQueueChatRef = {
  getSelectedTriage: () => SelectedTriageType;
  getDiagRuleId: () => number;
  getIsReclassify: () => boolean;
  getClassifyFromDiagRule: () => any[];
  setSelectedTriage: (data: SelectedTriageType) => void;
  handleEventReclassify: () => void;
  handleGetChatChannel: (params?: { newPatientId?: number | null, triage?: any }) => void;
}

/* ------------------------------------------------------ */

/*                      PopupCopyLink                     */

/* ------------------------------------------------------ */
export const PopupCopyLink = (props: PopupCopyLinkProps) => {

  const popupTimeout = useRef<any>()

  return <Popup
    trigger={
      <Button
        style={{ backgroundColor: "#56CCF2", color: "white", width: "55px" }}
        onClick={(e) => {
          const input = document.createElement("input");
          input.value = `${config.CLASSIFY_CHAT_FORM_HOST}${props.diagRuleList.find(list => list.id === props.diagRule)?.link || ""}`;
          document.body.appendChild(input);
          var copyText = input;
          copyText.select();
          document.execCommand("copy");
          input.remove();
        }}
        disabled={!props.diagRule}
      >
        <Icon
          name="linkify"
          style={{ transform: "rotate(45deg)" }}
        />
      </Button>
    }
    content={"คัดลอกลิงก์สำเร็จ"}
    on="click"
    open={props.open}
    onClose={() => {
      props.onOpen?.()
      clearTimeout(popupTimeout.current);
    }}
    onOpen={() => {
      props.onOpen?.()

      popupTimeout.current = setTimeout(() => {
        props.onClose?.()
      }, 1500);
    }}
    position="right center"
  />
}

/* ------------------------------------------------------ */

/*                  CardPatientQueueChat                  */

/* ------------------------------------------------------ */
const CardPatientQueueChat = React.forwardRef<any, CardPatientQueueChatProps>((props, ref) => {
  const history = useHistory();
  const location = useLocation();
  // Loading
  const [isLoadingTriage, setIsLoadingTriage] = useState(false)

  // Open
  const [openModInfo, setOpenModInfo] = useState<ModInfoType>(modInfoInitial)
  const [openModalShareLink, setOpenModalShareLink] = useState<boolean>(false)

  const [isTogglePin, setIsTogglePin] = useState(false)
  const [keepPrevTriage, setKeepPrevTriage] = useState<any>({})
  const [prevDivision, setPrevDivision] = useState<number | null>(null)
  const [hasWSReclassify, setHasWSReclassify] = useState(false);
  const [cloneChatList, setCloneChatList] = useState<any[]>([])
  const [diagRuleList, setDiagRuleList] = useState<any[]>([])
  const [diagRule, setDiagRule] = useState<any>("")
  const [textSearch, setTextSearch] = useState("");
  const [isNewPatient, setIsNewPatient] = useState<boolean>(false)
  const [typingTimeout, setTypingTimeout] = useState<any>(null)
  const [monitorStatus, setMonitorStatus] = useState<any>(0)

  // List
  const [classifyList, setClassifyList] = useState<any>([])

  // Select
  const [selectedTriage, setSelectedTriage] = useState<SelectedTriageType>({
    triageLevel: "",
    description: "",
    total: 0,
    items: [] as any[]
  })

  // Ref Element
  const classifyRef = useRef() as MutableRefObject<HTMLDivElement>
  const triageLevelRef = useRef() as MutableRefObject<HTMLDivElement>
  const chatListRef = useRef() as MutableRefObject<HTMLDivElement>
  const formRef = useRef<any>() as MutableRefObject<HTMLDivElement>


  const isClickPin = useRef(false)
  const scrollHeight = useRef<number>()
  const isMountChatListScroll = useRef(true)
  const isMountTriageScroll = useRef(true)
  const createNewPatientRef = useRef(false)
  const isReclassify = useRef(false)
  // เพื่อให้ refresh reclassify แล้ว ยัง active ที่ diagform เดิม และ menu เดิม
  const isActiveItem = useRef<any>(true)


  useImperativeHandle<CardPatientQueueChatRef, CardPatientQueueChatRef>(ref, () => ({
    // chatBoxRef: chatBoxRef.current,
    getSelectedTriage: () => selectedTriage,
    getIsReclassify: () => isReclassify.current,
    getClassifyFromDiagRule: ()=> JSON.parse(
        diagRuleList.find((item) => item.id === diagRule)?.classify || "[]"
      ),
    setSelectedTriage: (data) =>
      setSelectedTriage({
        ...selectedTriage,
        ...data
      }),
    handleGetChatChannel: (parmas) => handleGetChatChannel(parmas),
    handleEventReclassify: () => handleEventReclassify(),
    getDiagRuleId: () => diagRule,
  }));

  const getChatChannel = useCallback(
    async (params = {}) => {
      const { triage, newPatientId = null, diagRuleId, isReclassify = false } = params

      if (!triage) {
        return
      }
      const { triage_level, description, total } = triage



      if (!isReclassify) {
        setIsLoadingTriage(true)
      }

      const [response, error, network] = await props.controller.prxManager.getListChatChannelTriageLevelDiagRule(
        {
          apiToken: props.apiToken,
          diagRuleId,
          triageLevel: triage_level,
          divisionId: props.division
        }
      );

      setIsLoadingTriage(false)

      if (response?.items) {
        setHasWSReclassify(false);
      }

      let chatList: any[] = (response?.items || []).map((item: { id: any; }, index: any) => ({
        ...item, id: item.id ? item.id : index
      }))

      // setClassifyList((classify: any[]) => classify.map((list: { triage_level: any; }) => list.triage_level === triage_level ? { ...list, total: chatList.length } : list))

      if (params.patient?.id === NEW_PATIENT && !isActiveItem.current && !newPatientId) {
        chatList = [NEW_PATIENT_DATA, ...chatList]
      }

      setSelectedTriage({
        triageLevel: triage_level,
        ...triage,
        items: chatList
      })

      if (!chatList[0]) {
        isMountChatListScroll.current = false
      } else {
        if (isActiveItem.current) {
          props.onSetLoadCardChatBox(true)
        }
      }


      // เลื่อน scroll bar ไปยัง item ที่ active
      if (isMountTriageScroll.current && triageLevelRef.current) {
        const focused = triageLevelRef.current.querySelector(".classify-group.is_active") as HTMLElement

        if (focused) {
          triageLevelRef.current.scrollTop = focused.offsetTop - (triageLevelRef.current.offsetHeight / 2)
        }
        isMountTriageScroll.current = false
      }

      setCloneChatList(chatList)

      if (params.patient && !isActiveItem.current) {
        if (params.patient?.id === NEW_PATIENT && !newPatientId) {
          props.onSelectedPatient(NEW_PATIENT_DATA)
          return
        }
        const findChat = chatList.find((item: any) => item?.patient?.id === params.patient.patient?.id)

        if (findChat) {
          history.replace(props.getParamsHistory(findChat, { ...triage, triageLevel: triage.triage_level }))
        } else {
          props.onSelectedPatient(null)
        }
      }

      if (newPatientId) {
        createNewPatientRef.current = true
        setIsNewPatient(false)
        const findChat = chatList.find((item: any) => item?.patient?.id === newPatientId)

        history.push(props.getParamsHistory(findChat, triage))
        setTimeout(() => {
          history.replace(props.getParamsHistory(findChat, triage))
        });
      }
    },
    [props.division],
  )

  const getListTriageLevel = useCallback(
    async ({ diagRuleId = null, isCheckPrevTriage = true, diagRuleItem = [] } = {}) => {
      diagRuleId = diagRuleId || diagRule
      // return
      const [resTriagelevel, error] = await props.controller.prxManager.getListAvailabelTriageLevelDiagRule({
        apiToken: props.apiToken,
        divisionId: props.division,
        diagRuleId: diagRuleId
      })
      const classify = JSON.parse(diagRuleItem.find((item: any) => item.id === diagRuleId)?.classify || '[]')
      let triageLevelList = resTriagelevel || []
      triageLevelList = triageLevelList.map((list: any) => ({
        ...list,
        ...(classify?.find((item: any) => item.triage_level === list.triage_level) || {})
      }))

      setClassifyList(triageLevelList)

      const prev = keepPrevTriage[props?.division]
      if (prev && isCheckPrevTriage && (prev?.triageLevel || prev?.diagFormId)) {
        const search = diagRuleId ? `?diag_rule=${diagRuleId}` : ""
        const triageLevel = prev?.triageLevel
        const diagFormId = prev?.diagFormId

        if (triageLevel && diagFormId) {
          history.push({
            pathname: `/${history.location.pathname.split("/")[1]}/${triageLevel}/${diagFormId}/`,
            search
          });
          return
        }
        if (triageLevel) {
          history.push({
            pathname: `/${history.location.pathname.split("/")[1]}/${triageLevel}/`,
            search
          });
        }
      } else {
        setIsLoadingTriage(false)
      }

      if (!triageLevelList?.[0]) {
        setIsLoadingTriage(false)
      }
    },
    [props.division],
  )

  useEffect(() => {
    if (!isTogglePin) {
      isClickPin.current = false
      if (scrollHeight.current !== undefined) {
        triageLevelRef.current.scrollTop = +scrollHeight.current
      }
    }
  }, [isTogglePin])

  useEffect(() => {
    if (props.division && typeof props.division === "number") {
      const cloneObj = { ...keepPrevTriage }
      if (prevDivision) {
        cloneObj[prevDivision] = {
          ...selectedTriage,
          diagFormId: props.selectedPatient?.id,
          pin: isClickPin.current,
          diagRuleId: diagRule
        }
        setKeepPrevTriage(cloneObj)
      }

      if (keepPrevTriage[props.division]?.pin) {
        isClickPin.current = true
        setIsTogglePin(true)
      } else {
        setIsTogglePin(false)
        isClickPin.current = false
      }

      props.onSelectedPatient(null)
      setSelectedTriage({
        triageLevel: "",
        description: "",
        items: [],
        total: 0
      })
      setClassifyList([])
      setPrevDivision(props.division)
    }
  }, [props.division])

  useEffect(() => {
    const getListDiagRule = async () => {

      setIsLoadingTriage(true)

      let [resDiagRule, error] = await props.controller.prxManager.getDiagRule({
        apiToken: props.apiToken,
        division: props.division,
        active: true
      })

      resDiagRule = resDiagRule?.items || []

      setDiagRuleList(resDiagRule)

      if (resDiagRule[0]) {
        let diagRuleId = resDiagRule[0].id
        const { diag_rule } = getQueryParams()
        let findDiagRule = -1

        if (diag_rule !== null) {
          findDiagRule = resDiagRule.findIndex((option: { id: number; }) => option.id === +diag_rule)
        }

        if (diag_rule && findDiagRule !== -1) {
          diagRuleId = +diag_rule
        }

        const prev = keepPrevTriage?.[props.division]

        if (prev?.diagRuleId) {
          diagRuleId = prev.diagRuleId
        }

        onSetHistoryReplace(diagRuleId)
        setDiagRule(diagRuleId)

        await getListTriageLevel({ diagRuleId, diagRuleItem: resDiagRule })

      } else {
        setIsLoadingTriage(false)
      }
    }

    if (props.division && typeof props.division === "number") {
      getListDiagRule()
      // setTriageLevelList([])
    }
  }, [props.division])

  useEffect(() => {
    let triageLevel = props?.match?.params?.triageLevel

    const { diag_rule } = getQueryParams()

    if (triageLevel && classifyList[0] && triageLevel !== selectedTriage.triageLevel && diag_rule) {

      const findClassify = classifyList.find((list: { triage_level: any; }) => list.triage_level === triageLevel)
      props.onSelectedPatient(null)
      setIsNewPatient(false)

      if (findClassify) {
        getChatChannel(
          { triage: findClassify, diagRuleId: diagRule }
        )
      } else {
        const page = getCurrentPageURL()
        history.replace({
          pathname: page + `${classifyList[0].triage_level}/`,
          search: diagRule ? `?diag_rule=${diagRule}` : ""
        });
      }
    }

    if ((!triageLevel || !diag_rule) && classifyList[0]) {
      const page = getCurrentPageURL()
      history.replace({
        pathname: page + `${classifyList[0].triage_level}/`,
        search: diagRule ? `?diag_rule=${diagRule}` : ""
      });
    }
  }, [props?.match?.params?.triageLevel, classifyList])

  useEffect(() => {
    const diagFormId = props?.match?.params?.diagFormId

    if (+diagFormId !== props.selectedPatient?.id && selectedTriage.items[0] && diagFormId) {
      handleSetSelectedPatient(selectedTriage.items, +diagFormId)
    }

    if (selectedTriage.items?.[0]?.id === NEW_PATIENT && !diagFormId) {
      props.onSelectedPatient(NEW_PATIENT_DATA)
      return
    }

    if (props.selectedPatient?.id && selectedTriage.items[0] && !diagFormId) {
      props.onSelectedPatient(null)
    }

    setTimeout(() => {
      props.onSetLoadCardChatBox(false)
    });

  }, [props?.match?.params?.diagFormId, selectedTriage.items])

  useEffect(() => {

    if (!props.selectedPatient || !isActiveItem.current) {
      isActiveItem.current = true
      return
    }

    props.onActiveItem(createNewPatientRef.current)
    if (props.selectedPatient.id === NEW_PATIENT || createNewPatientRef.current) {
      createNewPatientRef.current = false
    }

    if (isMountChatListScroll.current && chatListRef.current) {
      const focused = chatListRef.current.querySelector(".item.active") as HTMLElement

      if (focused) {
        chatListRef.current.scrollTop = focused.offsetTop - (chatListRef.current.offsetHeight / 2)
      }
      isMountChatListScroll.current = false
    }

  }, [props.selectedPatient])

  useEffect(() => {

    const filterChatList = () => {
      const text = textSearch.toUpperCase()
      const filter = cloneChatList.filter((list: any) => {
        return (list.patient?.fullname?.toUpperCase()?.includes(text) ||
          list.patient?.hn?.toUpperCase()?.includes(text));
      })

      setHasWSReclassify(false);
      setSelectedTriage({ ...selectedTriage, items: filter })
      // props.onReceiveChatList({ chatList: filter });
    }

    filterChatList()
  }, [textSearch])

  // Handle Change
  const handleChangeDiagRule = async (value: DropdownType) => {
    setIsLoadingTriage(true)
    props.onSelectedPatient(null)

    setClassifyList([])
    setSelectedTriage({
      triageLevel: "",
      description: "",
      items: [],
      total: 0
    })

    setDiagRule(value)
    onSetHistoryReplace(value, true)

    getListTriageLevel({ diagRuleId: value, diagRuleItem: diagRuleList })
  }

  const handleSearch = (e: { target: { value: any; }; }) => {
    const { value } = e.target;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setTextSearch(value);
      }, 800)
    );
  };

  const handleEventReclassify = () => {
    const { vid_call } = getQueryParams()

    if (vid_call) {
      isReclassify.current = true
      return
    }

    getListTriageLevel({ diagRuleId: diagRule, isCheckPrevTriage: false, diagRuleItem: diagRuleList })

    isActiveItem.current = false
    isReclassify.current = false
    let triageLevel = props?.match?.params?.triageLevel
    const findClassify = classifyList.find((list: { triage_level: any; }) => list.triage_level === triageLevel)

    handleGetChatChannel({ triage: findClassify, isReclassify: true })
  }

  const handleGetChatChannel = async ({ newPatientId = null, triage = null, isReclassify = false }: { newPatientId?: number | null, triage?: any, isReclassify?: boolean } = {}) => {
    triage = triage ? triage : selectedTriage

    await getChatChannel({
      triage: {
        ...triage,
        triage_level: triage.triageLevel || triage.triage_level
      },
      diagRuleId: diagRule,
      newPatientId,
      patient: props.selectedPatient,
      isReclassify
    })
  }

  // Handle

  const handleAddNewPatient = () => {

    if (isNewPatient) {
      setOpenModInfo({ open: true, title: "กรุณาบันทึกข้อมูลผู้ป่วยใหม่", color: "yellow" })
      return
    }

    setSelectedTriage({
      ...selectedTriage,
      items: [NEW_PATIENT_DATA, ...selectedTriage.items]
    })

    props.onSelectedPatient(NEW_PATIENT_DATA)
    props.onSetActiveItem("PROFILE")
    setIsNewPatient(true)

    const page = getCurrentPageURL()

    history.push({
      pathname: page + `${selectedTriage.triageLevel}/`,
      search: diagRule ? `?diag_rule=${diagRule}` : ""
    });

    handleCloseModInfo()
  }

  // Handle Click
  const handleClickTriageLevel = async (item: any) => {
    if (!isTogglePin && !isClickPin.current) {

      const page = getCurrentPageURL();
      const { diag_rule } = getQueryParams()

      props.onSelectedPatient(null)
      props.onSetEncounterId("")
      setIsNewPatient(false)
      props.onSetEncounterData({
        patient: null,
        triage_level: null
      })

      history.push({
        pathname: page + `${item.triage_level}/`,
        search: diag_rule ? `?diag_rule=${diag_rule}` : ""
      });
    }

  }

  const handleClickPin = async (item: any) => {

    if (!isTogglePin) {
      scrollHeight.current = triageLevelRef.current.scrollTop
    }
    const triageLevel = item.triageLevel || item.triage_level

    if (selectedTriage.triageLevel !== triageLevel) {
      const page = getCurrentPageURL()
      const { diag_rule } = getQueryParams()

      props.onSelectedPatient(null)
      setSelectedTriage({
        ...selectedTriage,
        description: item.description,
        total: item.total,
        active: item.active,
        codeColor: item.codeColor,
        items: []
      })

      history.push({
        pathname: page + `${triageLevel}/`,
        search: diag_rule ? `?diag_rule=${diag_rule}` : ""
      });
    }
    setIsTogglePin(!isTogglePin)

  }

  const handleSetSelectedPatient = (chatList: any[], diagFormId: number) => {
    const findChat = chatList.find((item: { id: number; }) => item.id === +diagFormId)

    if (findChat) {
      if (!props.chatBoxRef.current && findChat.chat_channel?.id) {
        setTimeout(() => {
          history.replace(props.getParamsHistory(findChat, { ...selectedTriage }))
        });
      }

      if (findChat.chat_channel?.unread_message_count > 0) {
        const triageList = {
          ...selectedTriage,
          items: selectedTriage.items.map((triage) => triage.chat_channel?.id === findChat.chat_channel?.id ?
            { ...triage, chat_channel: { ...triage.chat_channel, unread_message_count: 0 } } : triage)
        }
        findChat.chat_channel.unread_message_count = 0
        setSelectedTriage(triageList)
      }

      props.onSelectedPatient(findChat)
      const encounterDefault = findChat.encounters?.find((encounter: { default: boolean; }) => encounter.default === true)
      props.onSetEncounterId(encounterDefault?.id || "")
    } else {
      props.onSelectedPatient(null)
    }
    props.onSetHideContentChat(false)
  }

  const handleClickPatientList = (item: any, itemIndex: number) => {
    if (item.id === props.selectedPatient?.id) {
      return
    }
    props.onSetHideContentChat(true)

    const encounterDefault = item.encounters?.find((encounter: { default: boolean; }) => encounter.default === true)
    props.onSetEncounterId(encounterDefault?.id || "")

    const triageList = {
      ...selectedTriage,
      items: selectedTriage.items.map((triage) => triage.chat_channel?.id === item.chat_channel?.id ?
        { ...triage, chat_channel: { ...triage.chat_channel, unread_message_count: 0 } } : triage)
    }

    setSelectedTriage(triageList)

    history.push(props.getParamsHistory(item))
    setTimeout(() => {
      history.replace(props.getParamsHistory(item))
    });

  }

  const handleOnKeyPress = async (ev: { key: string; preventDefault: () => void; }) => {
    if (ev.key === "Enter") {
      ev.preventDefault()
    }
  }

  const handleCloseModInfo = () => {
    setOpenModInfo(modInfoInitial)
  }

  // Utils

  const onSetHistoryReplace = (diagRuleId: any, isClearParams = false) => {
    let pathname = location.pathname.replace(/\/$/i, "") + "/"
    if (isClearParams) {
      pathname = `/${history.location.pathname.split("/")[1]}/`
    }
    history.replace({
      pathname,
      search: `?diag_rule=${diagRuleId}`
    })
  }

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(history.location.search)
    const diag_rule = queryParams.get("diag_rule") || diagRule
    const vid_call = JSON.parse(queryParams.get("vid_call") || "false");

    return { diag_rule, vid_call }
  }

  const getCurrentPageURL = () => {
    const re = new RegExp(/^(\/[^/]*).*/);
    let match = re.exec(history.location.pathname);
    if (match) {
      return match[1] + "/";
    }
    return `/${history.location.pathname.split("/")[1]}/`;
  };

  const diagRuleOptions = useMemo(() => {
    const mapOption = (item: { id: any; name: any; }, index: any) => ({ key: index, value: item.id, text: item.name })
    return diagRuleList ? diagRuleList.map(mapOption) : []
  }, [diagRuleList])

  // console.log("diagRuleList", diagRule, diagRuleList);

  return (
    <>
      <div ref={formRef}>
        <Form>
          <Form.Group>
            <Form.Field inline width={14}>
              <Dropdown
                selection
                fluid
                options={diagRuleOptions}
                value={diagRule}
                onChange={(e, { value }) => handleChangeDiagRule(value)}
              />
            </Form.Field>
            <Form.Field>
              {/* <Button
                  style={{ backgroundColor: "#56CCF2", color: "white", width: "55px" }}
                  onClick={() => setOpenModalShareLink(true)}
                  disabled={!diagRule}
                >
                  <Icon name="linkify" style={{ transform: "rotate(45deg)" }} />
                </Button> */}
              <PopupCopyLink
                open={openModalShareLink}
                diagRuleList={diagRuleList}
                diagRule={diagRule}
                onClose={() => setOpenModalShareLink(false)}
                onOpen={() => setOpenModalShareLink(true)}
              />

            </Form.Field>
          </Form.Group>

          {/* <Form.Group>
            <Form.Field inline width={13}>
              <Dropdown
                selection
                fluid
                options={MONITOR_STATUS_OPTIONS}
                value={monitorStatus}
                onChange={(e, { value }) => setMonitorStatus(value)}
              />
            </Form.Field>
          </Form.Group> */}

          <Form.Group>
            <Form.Field inline width={!config.HIDE_CLASSIFY_BUTTON_NEW_PATIENT ? 14 : 13}>
              <Input
                fluid
                placeholder="Search HN/ชื่อ นามสกุล"
                onChange={handleSearch}
                icon="search"
                onKeyPress={handleOnKeyPress}
              />
            </Form.Field>
            <Form.Field>
              {
                !config.HIDE_CLASSIFY_BUTTON_NEW_PATIENT &&
                <Button
                  icon="plus"
                  style={{ backgroundColor: "#0072bc", color: "white", width: "55px" }}
                  onClick={handleAddNewPatient}
                  disabled={!selectedTriage?.triageLevel}
                />
              }
            </Form.Field>
          </Form.Group>
        </Form>
      </div>

      <div className={`chat-classify-list${isTogglePin ? " full-screen" : ""}`} ref={classifyRef} style={{ height: `calc(100vh - ${formRef.current?.offsetHeight ? formRef.current?.offsetHeight + 70 : 175}px)` }}>
        <div className="left-list" ref={triageLevelRef} >
          {/* Triage */}
          {
            classifyList.map((item: any, index: number) =>
              item.active &&
              <Form
                key={"classify" + index}
                className={`classify-group${item.triage_level === selectedTriage.triageLevel ? " is_active" : ""}`}
                onClick={() => handleClickTriageLevel(item)}
              >
                <div style={{
                  // transform: "rotate(45deg)",
                  marginTop: "-5px",
                  marginRight: "-3px",
                  right: "5px",
                  position: "absolute"
                }}
                  onClick={() => {
                    isClickPin.current = true

                    // scrollHeightRef
                    handleClickPin(index === 0 && isTogglePin ? selectedTriage : item)
                  }}
                >
                  <svg style={{ transform: "scale(0.5)" }} width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.8271 8.11993L18.8696 0.529915C18.4296 0.0499146 17.7421 0.0499146 17.3021 0.529915C17.2196 0.619915 15.2121 2.86991 15.9821 5.80991L12.8471 9.22991C11.1146 8.65991 6.6871 7.66994 3.4146 11.2399C2.9746 11.7199 2.9746 12.4699 3.4146 12.9499L8.14459 18.1099L1.15958 25.7299C0.719583 26.2099 0.719583 26.9599 1.15958 27.4399C1.37958 27.6799 1.6546 27.7999 1.9296 27.7999C2.2046 27.7999 2.47958 27.6799 2.69958 27.4399L9.68458 19.8199L14.4146 24.9799C14.6346 25.2199 14.9096 25.3399 15.1846 25.3399C15.4596 25.3399 15.7346 25.2199 15.9546 24.9799C19.2271 21.41 18.3196 16.5499 17.7971 14.6899L20.9321 11.2699C23.6271 12.1099 25.6621 9.91991 25.7721 9.82991C26.2671 9.34991 26.2671 8.59993 25.8271 8.11993ZM21.2071 8.77991C20.7946 8.56991 20.2996 8.65993 19.9696 9.01993L15.8446 13.5199C15.5421 13.8499 15.4321 14.3899 15.5971 14.8099C15.5971 14.8099 16.0921 16.1599 16.1746 17.8999C16.2571 19.6399 15.9271 21.1099 15.1846 22.3399L10.5096 17.2399L5.83458 12.1399C8.77708 9.97994 12.5721 11.5999 12.7371 11.6899C13.1496 11.8699 13.6171 11.7799 13.9471 11.4199L18.0721 6.91993C18.4021 6.55993 18.4846 6.01993 18.2921 5.56993C17.9071 4.66993 18.0446 3.85991 18.2921 3.22991L23.3521 8.74992C22.7746 9.04992 22.0046 9.19991 21.2071 8.77991ZM14.6621 15.2899C14.6896 15.3799 15.0471 16.25 15.0196 17.1799C14.9921 17.75 14.5796 18.1999 14.0571 18.1999H14.0296C13.5071 18.1699 13.0671 17.6899 13.0946 17.1199C13.0946 16.7299 12.9571 16.2799 12.9021 16.1299C12.6821 15.5899 12.9021 14.9899 13.3971 14.7499C13.8921 14.5399 14.4696 14.7499 14.6621 15.2899Z"
                      fill={index === 0 && isTogglePin ? "rgb(255,224,0)" : "white"} />
                  </svg>

                </div>
                <div style={{ display: "grid", gridTemplateColumns: "auto" }}>
                  <div
                    className="content"
                    style={{ color: item.codeColor }}
                  >
                    {/* 77 */}
                    {index === 0 && isTogglePin ? selectedTriage.description : item.description}
                    <div style={{ paddingLeft: index === 0 && isTogglePin ? "5px" : "" }}>
                      ({index === 0 && isTogglePin ? selectedTriage.total : item.total})
                    </div>
                  </div>
                </div>
              </Form>
            )
          }
        </div>

        {/* Patient List */}
        <div className="items" ref={chatListRef}>
          <Dimmer active={isLoadingTriage} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          {
            !selectedTriage.items[0] ? <div
              style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
              <div style={{ background: "#f6f7f7", padding: "13px" }}>ไม่พบข้อมูล</div>
            </div> :
              selectedTriage.items.map((item: any, index) =>
                <div
                  key={"triage" + index}
                  className={`item${item.id === props.selectedPatient?.id ? " active" : ""}`}
                  onClick={() => handleClickPatientList(item, index)}
                >
                  <div style={{ display: "grid", gridTemplateColumns: "10px auto" }}>
                    <div>
                      {item.chat_channel?.unread_message_count > 0 ? <div className='badge' /> : null}
                    </div>

                    <div>
                      <label>
                        {item.patient.fullname}
                      </label>
                      <div style={{ marginTop: "5px" }}>อายุ : {item.patient.age}</div>
                      <div>{item.classify ? `Classify : ${item.classify}` : `HN : ${item.patient.hn || ""}`}</div>

                    </div>
                  </div>

                </div>
              )
          }
        </div>
      </div>

      <ModInfo
        open={openModInfo.open}
        titleColor={openModInfo.color || "red"}
        titleName={openModInfo.title}
        onApprove={handleCloseModInfo}
        onClose={handleCloseModInfo}
      >
        {
          openModInfo.error === "string" ?
            <div>{openModInfo.error}</div> :
            <ErrorMessage error={openModInfo.error} />
        }
      </ModInfo>
    </>
  )
})

export default React.memo(CardPatientQueueChat)
