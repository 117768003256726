const getPdfMake = async () => {

  let make = await import("pdfmake/build/pdfmake");
  let font = await import("../../assets/fonts/pdfFonts")
  let pdfMake = make.default
  pdfMake.vfs = font.default 
  pdfMake.fonts = {
      THSarabunNew: {
          normal: 'THSarabunNew.ttf',
          bold: 'THSarabunNew-Bold.ttf',
          italics: 'THSarabunNew-Italic.ttf',
          bolditalics: 'THSarabunNew-BoldItalic.ttf'
      },
      Roboto: {
          normal: 'Roboto-Regular.ttf',
          bold: 'Roboto-Medium.ttf',
          italics: 'Roboto-Italic.ttf',
          bolditalics: 'Roboto-MediumItalic.ttf'
      }
  }
  return pdfMake
}

export default getPdfMake;