import React from "react";
import Step from "./Step";

const Tree = props => {
  const stepRef = React.useRef([]);
  const horRef = React.useRef([]);
  const [height, setHeight] = React.useState(500)
  const [width, setWidth] = React.useState(500)

  const indent = 40;
  const rowspace = 100;
  const h = 60;
  const strokeWidth = "2";
  var vertical = props.content.map((item, index) => {
    return {
      x1: 1 + indent + indent * item.level,
      y1: 0 + h / 2 + rowspace * index,
      x2: 1 + indent + indent * item.level,
      y2: 0 + h / 2 + rowspace * (index + item.span)
    };
  });
  var horizontal = props.content.map((item, index) => {
    return {
      x1: 1 + indent + indent * Math.max(0, item.level - 1),
      y1: 0 + h / 2 + rowspace * index,
      x2: 1 + indent + indent * item.level,
      y2: 0 + h / 2 + rowspace * index
    };
  });

  const getElementPosition = (el) => {
    var rect = el.getBoundingClientRect()
    return rect
  }

  React.useEffect(() => {
    const startEl = getElementPosition(stepRef.current[0])
    const lastEl = getElementPosition(stepRef.current[props.content.length - 1])
   
    let maxLevel = Math.max.apply(Math, props.content.map(function(item) { return item.level; }))
    let maxLevelIndex = props.content.findIndex((item) => item.level === maxLevel)
    let maxLevelEl = getElementPosition(stepRef.current[maxLevelIndex])
    let newWidth = maxLevelEl.right - startEl.left
    let newHeight = lastEl.bottom - startEl.top
    setWidth(newWidth > 500 ? newWidth : 500)
    setHeight(newHeight > 500 ? newHeight : 500)

  }, [props.content, stepRef.current])

  return (
    <>
      <svg
        width={width} // width
        height={height} // height
        viewBox={`${width/2} 0 ${width} ${height * 2}`} // width/2, 0, width, height*2
        xmlns="http://www.w3.org/2000/svg"
        style={{ backgroundColor: "white" }}
      >
        {vertical.map((item, index) => (
          <line
            key={index}
            x1={item.x1}
            y1={item.y1}
            x2={item.x2}
            y2={item.y2}
            style={{ stroke: "grey", strokeWidth: strokeWidth }}
          />
        ))}
        {horizontal.map((item, index) => (
          <line
            ref={el => (horRef.current[index] = el)}
            key={index}
            x1={item.x1}
            y1={item.y1}
            x2={item.x2}
            y2={item.y2}
            style={{ stroke: "grey", strokeWidth: strokeWidth }}
          />
        ))}
        {props.content.map((item, index) => (
          <Step
            ref={el => (stepRef.current[index] = el)}
            item={item}
            key={index}
            index={index}
            doNode={props.doNode}
            setSelectedRow={props.setSelectedRow}
            selectedRowId={props.selectedRowId}
          />
        ))}
      </svg>
    </>
  );
};

export default Tree;
