export default  [
  { 
    apiKey: "AIzaSyD3-V5j5bAty2eEL-QkKNXm4WyhqpEmu7w",
    authDomain: "penguin-dev-e0943.firebaseapp.com",
    databaseURL: "https://penguin-dev-e0943.firebaseio.com",
    projectId: "penguin-dev-e0943",
    storageBucket: "penguin-dev-e0943.appspot.com",
    messagingSenderId: "614639654931",
    appId: "1:614639654931:web:e478ca06d3cf634ea287e6",
    measurementId: "G-B46QQ4H7YL",
    functionRegion: "us-central1"
  },
  {
    apiKey: "AIzaSyAe3pnU46ECR3VFcvyrmkeH8X25bIrcTMk",
    authDomain: "issara-platform.firebaseapp.com",
    projectId: "issara-platform",
    storageBucket: "issara-platform.appspot.com",
    messagingSenderId: "103198969033",
    appId: "1:103198969033:web:02b7ccda83f18916af9118",
    measurementId: "G-0PKPDN28WY",
    functionRegion: "us-central1"
  },
  {
    apiKey: "AIzaSyBCd9NzXfdhFROKNaRTfU794XWndD_vnVo",
    authDomain: "my-b-plus-staging.firebaseapp.com",
    projectId: "my-b-plus-staging",
    storageBucket: "my-b-plus-staging.appspot.com",
    messagingSenderId: "289561767544",
    appId: "1:289561767544:web:9b8d407aa5aa45656fc78d",
    measurementId: "G-RRX11V87RF",
    functionRegion: "us-central1"
  }
]