import PTMService from "../../apis/services/PTMService";
import { to, setStateAsync } from "../../utils";
export default class PTMManager {
  apiToken?: string | null;
  ptmService: PTMService;

  constructor(apiToken: string | null = null) {
    this.ptmService = new PTMService(apiToken);
    this.apiToken = apiToken
  }
  // ---------- REG
  getPatientData = () => {
    return [null, null];
  };
  getPatientWardData = () => {
    return [null, null];
  };
  periodWorkerOptions = () => {
    return [null, null];
  };
  getFlowSheet = () => {
    return [null, null];
  };
  getPatientAssessment = async patientId => {
    var patientAssessmentData = {};
    var patientAssessmentError = null;
    let params = {
      patient: patientId,
      form_version: 0.1,
      form_code: "CardPatientAssessment"
    };
    const [error, response] = await to(
      this.ptmService.getFormDataLatest(params)
    );
    if (response) {
      patientAssessmentData = response;
    } else {
      patientAssessmentError = error;
    }
    return [patientAssessmentData, patientAssessmentError];
  };
  getFormDataLatest = async ({ encounterId, formCode, formVersion }) => {
    let params = {};
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (formCode) {
      params.form_code = formCode;
    }
    if (formVersion) {
      params.form_version = formVersion;
    }
    const [error, response] = await to(
      this.ptmService.getFormDataLatest(params)
    );
    return [response, error];
  };
  getFormDataActionLog = async formId => {
    const [error, response, network] = await to(
      this.ptmService.getFormDataActionLog(formId)
    );
    return [response, error, network];
  };
  // TODO: Remove this fucntion use getVitalSignValue instead.
  getPatientWeightAndHeight = async (patientId, typeCode) => {
    if (!typeCode) {
      typeCode = "Weight";
    }
    var weightData = {};
    var weightError = null;
    let params = {
      patient: patientId,
      type_code: typeCode,
      latest_measurement: true
    };
    const [error, response] = await to(
      this.ptmService.getVitalSignValue(params)
    );
    if (response) {
      weightData = response.items[0];
    } else {
      weightError = error;
    }
    return [weightData, weightError];
  };
  getVitalSignValue = async ({
    patientId,
    latestMeasurement,
    typeCode
  } = {}) => {
    var vitalSignValueData = {};
    var vitalSignValueError = null;
    let params = {};
    if (patientId) {
      params.patient = patientId;
    }
    if (latestMeasurement) {
      params.latest_measurement = latestMeasurement;
    }
    if (typeCode) {
      params.type_code = typeCode;
    }
    const [error, response] = await to(
      this.ptmService.getVitalSignValue(params)
    );
    if (response) {
      vitalSignValueData = response.items;
    } else {
      vitalSignValueError = error;
    }
    return [vitalSignValueData, vitalSignValueError];
  };

  getVitalSignValueRaw = async (params) => {
    var vitalSignValueData = {};
    var vitalSignValueError = null;
    const [error, response] = await to(
      this.ptmService.getVitalSignValue(params)
    );
    if (response) {
      vitalSignValueData = response;
    } else {
      vitalSignValueError = error;
    }
    return [vitalSignValueData, vitalSignValueError];
  };
  getMeasurement = async ({ encounterId, usePatient, fromDate } = {}) => {
    let params = {};
    var measurementData = [];
    var measurementError = null;
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (usePatient) {
      params.use_patient = usePatient;
    }
    if (fromDate) {
      params.from_date = fromDate;
    }
    const [error, response] = await to(
      this.ptmService.getMeasurement({ params })
    );
    if (response) {
      measurementData = response;
    } else {
      measurementError = error;
    }
    return [measurementData, measurementError];
  };
  getVitalSignType = async ({ encounterId, divFromEncounter, group } = {}) => {
    let params = {};
    var vitalSignType = [];
    var vitalSignTypeError = null;
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (divFromEncounter) {
      params.div_from_encounter = divFromEncounter;
    }
    if (group) {
      params.group = group;
    }
    const [error, response] = await to(
      this.ptmService.getVitalSignType(params)
    );
    if (response) {
      vitalSignType = response.items;
    } else {
      vitalSignTypeError = error;
    }
    return [vitalSignType, vitalSignTypeError];
  };
  getVitalSign = async params => {
    var vitalSignData = [];
    var vitalSignError = null;
    console.log("getVitalSign manager got params: ", params);
    const [error, response] = await to(this.ptmService.getMeasurement(params));
    if (response) {
      vitalSignData = response;
    } else {
      vitalSignError = error;
    }
    return [vitalSignData, vitalSignError];
  };
  getLastHeight = async patientId => {
    var data = {};
    var dataError = null;
    const [error, response] = await to(
      this.ptmService.getLastHeight(patientId)
    );
    if (response) {
      data = response.height;
    } else {
      dataError = error;
    }
    return [data, dataError];
  };
  getIntakeOutput = async params => {
    return ["ok", null, null];
  };
  postMeasurement = async params => {
    var vsResponse = {};
    var vsError = null;
    const [error, response, network] = await to(
      this.ptmService.postMeasurement(params)
    );
    if (response) {
      vsResponse = response;
    } else {
      vsError = error;
    }
    return [vsResponse, vsError, network];
  };
  patchMeasurement = async (measurement_id, params) => {
    var vsResponse = {};
    var vsError = null;
    const [error, response] = await to(
      this.ptmService.patchCancelMeasurement(measurement_id, params)
    );
    if (response) {
      vsResponse = response;
    } else {
      vsError = error;
    }
    return [vsResponse, vsError];
  };
  postVitalSignUpdate = async params => {
    var vsResponse = {};
    var vsError = null;
    const [error, response, network] = await to(
      this.ptmService.postVitalSignUpdate(params)
    );
    if (response) {
      vsResponse = response;
    } else {
      vsError = error;
    }
    return [vsResponse, vsError, network];
  };
  deleteVitalSign = async ({ id }) => {
    var vsResponse = {};
    var vsError = null;
    const [error, response, network] = await to(
      this.ptmService.deleteVitalSign({ id })
    );
    if (response) {
      vsResponse = response;
    } else {
      vsError = error;
    }
    return [vsResponse, vsError, network];
  };
  getMultidisciplinary = async params => {
    var multidisciplinaryData = [];
    var multidisciplinaryError = null;
    var numberOfPage = null;
    const [error, response] = await to(
      this.ptmService.getMultidisciplinaryItem(params)
    );
    if (response) {
      multidisciplinaryData = response.items;
      if (params.limit) {
        numberOfPage = Math.ceil(response.total / params.limit);
      }
    } else {
      multidisciplinaryError = error;
    }
    return [multidisciplinaryData, multidisciplinaryError, numberOfPage];
  };
  saveMultidisciplinary = async params => {
    var vsResponse = {};
    var vsError = null;
    const [error, response] = await to(
      this.ptmService.postMultidisciplinaryItem(params)
    );
    if (response) {
      vsResponse = response;
    } else {
      vsError = error;
    }
    return [vsResponse, vsError];
  };
  updateMultidisciplinary = async params => {
    var vsResponse = {};
    var vsError = null;
    const [error, response] = await to(
      this.ptmService.putMultidisciplinaryItem(params)
    );
    if (response) {
      vsResponse = response;
    } else {
      vsError = error;
    }
    return [vsResponse, vsError];
  };
  printMultidisciplinary = async params => {
    var vsResponse = {};
    var vsError = null;
    const [error, response] = await to(
      this.ptmService.printMultidisciplinaryItem(params)
    );
    if (response) {
      vsResponse = response;
    } else {
      vsError = error;
    }
    return [vsResponse, vsError];
  };
  getFormType = async () => {
    const [error, response] = await to(this.ptmService.getFormType());
    return [response, error];
  };
  getFormDataNurseNote = async ({
    encounterType,
    encounterNo,
    formType,
    doctor,
    startDate,
    endDate,
    patient
  }) => {
    let params = {};
    if (patient) {
      params.patient = patient;
    }
    if (encounterType) {
      params.encounter_type = encounterType;
    }
    if (encounterNo) {
      params.encounter_number = encounterNo;
    }
    if (formType) {
      params.form_type = formType;
    }
    if (doctor) {
      params.doctor = doctor;
    }
    if (startDate) {
      params.from_date = startDate;
    }
    if (endDate) {
      params.to_date = endDate;
    }
    const [error, response] = await to(
      this.ptmService.getFormDataNurseNote({ params })
    );
    return [response, error];
  };
  deleteMultidisciplinary = async (item_id, params) => {
    var vsResponse = {};
    var vsError = null;
    const [error, response] = await to(
      this.ptmService.deleteMultidisciplinaryItem(item_id, params)
    );
    if (response) {
      vsResponse = response;
    } else {
      vsError = error;
    }
    return [vsResponse, vsError];
  };
  getNurseDiagnosisResultGroup = async ({
    encounterId,
    startDate,
    endDate,
    divisionId
  } = {}) => {
    var data = [];
    var error = null;
    var params = {};
    if (encounterId) {
      params.encounter = encounterId;
    }
    if (startDate) {
      params.start_date = startDate;
    }
    if (endDate) {
      params.end_date = endDate;
    }
    if (divisionId) {
      params.division = divisionId;
    }
    const [responseError, response] = await to(
      this.ptmService.getNurseDiagnosisResultGroup(params)
    );
    if (response) {
      data = response;
    } else {
      error = responseError;
    }
    return [data, error];
  };
  putNurseDiagPrintPhv = async ({ documentNo, pdf } = {}) => {
    var data = [];
    var error = null;
    var data = {};
    if (documentNo) {
      data.document_no = documentNo;
    }
    if (pdf) {
      data.pdf = pdf;
    }
    const [responseError, response] = await to(
      this.ptmService.putNurseDiagPrintPhv({ data })
    );
    if (response) {
      data = response;
    } else {
      error = responseError;
    }
    return [data, error];
  };
  getNurseNoteLatest = async ({ encounterId } = {}) => {
    let params = {};
    if (encounterId) {
      params.encounter = encounterId;
    }
    const [error, response] = await to(
      this.ptmService.getNurseNoteLatest({ params })
    );
    return [response, error];
  };

  getNurseNoteItem = async ({ nurseNoteId, encounterId, fromDate, toDate, apiToken } = {}) => {
    let params = {};
    if (nurseNoteId) {
      params.nurse_note = nurseNoteId;
    }
    if (fromDate) {
      params.from_date = fromDate;
    }
    if (toDate) {
      params.to_date = toDate;
    }
    if (encounterId) {
      params.encounter = encounterId;
    }
    const ptmService = new PTMService(this.apiToken);
    const [error, response, network] = await to(ptmService.getNurseNoteItem(params, apiToken));
    return [response, error, network];
  };
  postNurseNoteItem = async ({
    date,
    time,
    workingShift,
    plan,
    goal,
    diagnosis,
    username,
    password,
    nurseNoteId,
    encounter,
    division,
    token,
    doctorOrderId,
    userToken,
    apiToken
  } = {}) => {
    let data = {};
    if (encounter) {
      data.encounter = encounter;
    }
    if (division) {
      data.division = division;
    }
    if (nurseNoteId) {
      data.nurse_note_id = nurseNoteId;
    }
    if (workingShift) {
      data.working_shift = workingShift;
    }
    if (date) {
      data.date = `${date}-${time}`;
    }
    if (plan) {
      data.plan = plan;
    }
    if (diagnosis) {
      data.diagnosis = diagnosis;
    }
    if (goal) {
      data.goal = goal;
    }
    if (doctorOrderId) {
      data.doctor_order_id = doctorOrderId;
    }
    if (username) {
      data.username = username;
    }
    if (password) {
      data.password = password;
    }
    if (token) {
      data.csrfmiddlewaretoken = token;
    }
    if (userToken) {
      data.user_token = userToken;
    }

    const params = {};
    const ptmService = new PTMService(this.apiToken);
    const [error, response, network] = await to(ptmService.postNurseNoteItem({ params, data, apiToken }));
    return [response, error, network];
  };
  putNurseNoteItem = async ({
    date,
    time,
    workingShift,
    plan,
    goal,
    diagnosis,
    username,
    password,
    nurseNoteId,
    encounter,
    token,
    doctorOrderId,
    nurseNoteItemId,
    apiToken,
    division,
    userToken
  } = {}) => {
    let data = {};
    if (encounter) {
      data.encounter = encounter;
    }
    if (nurseNoteId) {
      data.nurse_note_id = nurseNoteId;
    }
    if (workingShift) {
      data.working_shift = workingShift;
    }
    if (date) {
      data.date = `${date}-${time}`;
    }
    if (plan) {
      data.plan = plan;
    }
    if (diagnosis) {
      data.diagnosis = diagnosis;
    }
    if (goal) {
      data.goal = goal;
    }
    if (doctorOrderId) {
      data.doctor_order_id = doctorOrderId;
    }
    if (username) {
      data.username = username;
    }
    if (password) {
      data.password = password;
    }
    if (token) {
      data.csrfmiddlewaretoken = token;
    }
    if (division) {
      data.division = division
    }
    if (userToken) {
      data.user_token = userToken
    }

    const ptmService = new PTMService(this.apiToken);
    const [error, response, network] = await to(ptmService.putNurseNoteItem({ apiToken, data, nurseNoteItemId }));
    return [response, error, network];
  };
  deleteNurseNoteItem = async ({
    username,
    password,
    csrf,
    nurseNoteItemId
  } = {}) => {
    let data = {};
    if (username) {
      data.username = username;
    }
    if (password) {
      data.password = password;
    }
    if (csrf) {
      data.csrfmiddlewaretoken = csrf;
    }
    const [error, response] = await to(
      this.ptmService.deleteNurseNoteItem({ data, nurseNoteItemId })
    );
    return [response, error];
  };
  putNurseNote = async ({
    username,
    password,
    csrf,
    nurseNoteId,
    status,
    action,
    isCheifNurse,
    encounter
  } = {}) => {
    let data = {};
    if (username) {
      data.username = username;
    }
    if (password) {
      data.password = password;
    }
    if (csrf) {
      data.csrfmiddlewaretoken = csrf;
    }
    if (nurseNoteId) {
      data.id = nurseNoteId;
    }
    if (status) {
      data.status = status;
    }
    if (action) {
      data.action = action;
    }
    if (isCheifNurse) {
      data.is_cheif_nurse = isCheifNurse;
    }
    if (encounter) {
      data.encounter = encounter;
    }
    const ptmService = new PTMService(this.apiToken);
    const [error, response, network] = await to(ptmService.putNurseNote({ data, nurseNoteId }));
    return [response, error, network];
  };
  getDrOrder = async ({ fromDate, toDate, encounterId } = {}) => {
    let params = {};
    if (fromDate) {
      params.from_date = fromDate;
    }
    if (toDate) {
      params.to_date = toDate;
    }
    if (encounterId) {
      params.encounter = encounterId;
    }
    const [error, response] = await to(this.ptmService.getDrOrder({ params }));
    return [response, error];
  };
  getVentilatorMode = async () => {
    let params = {};
    const [error, response] = await to(
      this.ptmService.getVentilatorMode({ params })
    );
    return [response, error];
  };
  putEncounterVitalSignType = async ({ params, data, encounterId }) => {
    const [error, response, network] = await to(
      this.ptmService.putEncounterVitalSignType({ params, data, encounterId })
    );
    return [response, error, network];
  };
  putRemoveEncounterVitalSignType = async ({ params, data, encounterId }) => {
    const [error, response, network] = await to(
      this.ptmService.putRemoveEncounterVitalSignType({
        params,
        data,
        encounterId
      })
    );
    return [response, error, network];
  };
  postFormData = async ({
    action,
    encounter,
    formCode,
    formName,
    formVersion,
    formData
  } = {}) => {
    let params = {};
    params.encounter = encounter;
    params.action = action;
    params.form_code = formCode;
    params.form_name = formName;
    params.form_version = formVersion;
    params.data = formData;
    const [error, response] = await to(
      this.ptmService.postFormData({ params })
    );
    return [response, error];
  };
  putFormData = async ({
    formDataId,
    action,
    encounter,
    formCode,
    formName,
    formVersion,
    formData
  } = {}) => {
    let params = {};
    params.action = action;
    params.encounter = encounter;
    params.form_code = formCode;
    params.form_name = formName;
    params.form_version = formVersion;
    params.data = formData;
    const [error, response] = await to(
      this.ptmService.putFormData({ params, formDataId })
    );
    return [response, error];
  };
  putMeasurement = async ({ encounterId, date, time, measurementId } = {}) => {
    let data = {};
    let params = {};
    if (measurementId) {
      data.id = measurementId;
    }
    if (date) {
      data.date = date;
    }
    if (encounterId) {
      data.encounter = encounterId;
    }
    if (time) {
      data.time = time;
    }
    const [error, response] = await to(
      this.ptmService.putMeasurement({ params, data, measurementId })
    );
    return [response, error];
  };
}
