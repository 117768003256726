import React, { useState, useEffect } from "react";
import {
  Dimmer,
  Button,
  Form,
  Divider,
  Input,
  Image,
  Grid
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import DateTextBox from "./../..//common/DateTextBox";
import personPicture from "../../../assets/images/person.png";
import styles from "../css/styles.ts";
import PropTypes from "prop-types";

const StudioProfile = props => {
  const [imageUrl, setImageUrl] = useState((props.image) ? props.image : personPicture);
  const [birthDate, setBirthDate] = useState(props.birthDate);
  const [firstName, setFirstName] = useState(props.firstName);
  const [surName, setSurName] = useState(props.surName);
  const [email, setEmail] = useState(props.email);
  const [telephone, setTelephone] = useState(props.telephone);

  // console.log("StudioProfile firstName", firstName);
  const UserState = props => {
    // console.log('UserState props', props)
    if (props.userVerified) {
      return (
        <div style={{ color: "#76cfe3", fontSize: "36px" }}>
          {"สถานะ : พร้อมใช้งาน (Active)"}
        </div>
      );
    } else {
      return (
        <div style={{ color: "#FF2929", fontSize: "36px" }}>
          {"สถานะ : รอยืนยันบัญชี (Inactive)"}
        </div>
      );
    }
  };

  const handleChangeBirthDate = date => {
    setBirthDate(date);
  };

  useEffect(() => {
    if (props.apiToken && (!props.email)) {
      props.onLoadProfile(props.apiToken);
    } else {
      // Re-initial
      setFirstName(props.firstName);
      setSurName(props.surName);
      setBirthDate(props.birthDate);
      setEmail(props.email);
      setTelephone(props.telephone);
      setImageUrl((props.image) ? props.image : personPicture);
    }
  }, [props.email])

  console.log('StudioProfile props', props)
  return (
    <Dimmer.Dimmable>
      <div>

        <Grid style={{marginTop: "24px"}}>
          <Grid.Column width={3}>
            <Image 
              className="rounded" 
              style={{width: "225px", height: "225px"}} 
              circular 
              floated={"right"} 
              src={imageUrl} />
          </Grid.Column>
          <Grid.Column width={13} verticalAlign={"middle"}>
            <div style={styles.header}> {firstName} {" "} {surName} </div>
            <Divider  style={styles.divider} />
            <div style={styles.negative_header}>
            <br/>
              <UserState userVerified={props.userVerified} />
            </div>
            <br />
            <br />
            <div style={styles.info_header}>
              สังกัด : {props.divisionName}
            </div>
          </Grid.Column>
        </Grid>

        <Grid columns={2} style={{marginTop: "24px", marginLeft: "56px", marginRight: "56px"}}>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group style={{ justifyContent: "space-between" }}>
                  <Form.Field width={16}>
                    <label style={{ color: "#76cfe3", fontSize:'18px' }}>ชื่อ</label>
                    <Input
                      value={firstName}
                      onChange={(e, data) => {
                        setFirstName(data.value);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group style={{ justifyContent: "space-between" }}>
                  <Form.Field width={16}>
                    <label style={{ color: "#76cfe3", fontSize:'18px' }}>วันเกิด</label>
                    <DateTextBox
                      value={birthDate}
                      onChange={handleChangeBirthDate}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group style={{ justifyContent: "space-between" }}>
                  <Form.Field width={16}>
                    <label style={{ color: "#76cfe3", fontSize:'18px' }}>
                      Email ที่ติดต่อได้
                    </label>
                    <Input
                      readOnly
                      value={email}
                      // onChange={(e, data) => {
                      //   setEmail(data.value);
                      // }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group style={{ justifyContent: "space-between" }}>
                  <Form.Field width={16}>
                    <label style={{ color: "#76cfe3", fontSize:'18px'}}>
                      เบอร์โทรศัพท์ที่ติดต่อได้
                    </label>
                    <Input
                      value={telephone}
                      onChange={(e, data) => {
                        setTelephone(data.value);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group inline style={{ justifyContent: "space-between" }}>
                  <Form.Field width={3}>
                    <Button
                      style={{
                        ...styles.basicSmallButton,
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center"
                      }}
                      content="Save"
                      fluid
                      color="teal"
                      basic
                      onClick={() => {
                        props.onSave({
                          firstName: firstName,
                          surName: surName,
                          telephone: telephone,
                          birthDate: birthDate
                        });
                      }}
                    />
                  </Form.Field>

                  <Form.Field width={13}></Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
            <Grid.Column>
              <Form>
                <Form.Group style={{ justifyContent: "space-between" }}>
                  <Form.Field width={16}>
                    <label style={{ color: "#76cfe3", fontSize:'18px'}}>นามสกุล</label>
                    <Input
                      value={surName}
                      onChange={(e, data) => {
                        setSurName(data.value);
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
              <div class="ui message">
                <div class="header">
                  คุณยังไม่ได้ยืนยันบัญชี IsHealth Studio
                </div>
                <p>
                  <p>
                    กรุณาส่ง Email ที่ ishealth@mor.company โดยแจ้งข้อมูลดังนี้{" "}
                    <br />
                  </p>
                  1. โรงพยาบาลที่สังกัด <br />
                  2. Email ของ facebook account ดังแสดงข้างล่างนี้
                  <br /> <br />
                  doctor1@rama.co.th
                  <br /> <br />
                  3. แนบเอกสารขอเข้าใช้งานระบบ ซึ่ง download
                  ได้จากปุ่มด้านล่างนี้
                </p>
                <p>เพื่อให้เจ้าหน้าที่ทำการ Activate account</p>
              </div>
              <Button
                content="Download เอกสารขอเข้าใช้งานระบบ"
                fluid
                color="blue"
                basic
                style={styles.basicButton}
                onClick={() => {
                  window.open(
                    "https://showtime.sgp1.digitaloceanspaces.com/media/uploaded/Request%20Permission%20Form.pdf",
                    "_blank"
                  );
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </Dimmer.Dimmable>
  );
};

StudioProfile.defaultProps = {
  apiToken: null,
  userVerified: false,
  divisionName: "",
  firstName: "",
  surName: "",
  birthDate: "",
  telephone: "",
  email: "",
  image: null,
  onLoadProfile: (token) => {},
  onSave: () => {}
};

StudioProfile.propTypes = {
  apiToken: PropTypes.string,
  userVerified: PropTypes.bool,
  divisionName: PropTypes.string,
  firstName: PropTypes.string,
  surName: PropTypes.string,
  birthDate: PropTypes.string,
  telephone: PropTypes.string,
  email: PropTypes.string,
  image: PropTypes.string,
  onLoadProfile: PropTypes.func,
  onSave: PropTypes.func
};

export default StudioProfile;
