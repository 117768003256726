import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Dimmer } from "semantic-ui-react";
import CONFIG from "../config/config";

import { getFirestore } from "firebase/firestore";

import {
  collection,
  getDocs,
  updateDoc,
  query,
  where,
  setDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import _ from "react-lib/compat/lodashplus";

import { firebaseApp } from "react-lib/frameworks/Firebase";
const db = getFirestore(firebaseApp);

export const Main = (props: any) => {
  const history = useHistory();
  
  const [iframeHeight, setIframeHeight] = useState(480);
  const [iframeWidth, setIframeWidth] = useState(640);
  const [visible, setVisible] = useState(true);
  const [videoCallStartPip, setVideoCallStartPip] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (firstTime) {
      // const _openVideoCallModal = localStorage.getItem("openVideoCallModal");
      // const _videoCallRoom = localStorage.getItem("videoCallRoom");
      // const _closeOverriding = localStorage.getItem("closeOverriding");
      setFirstTime(false);
      // if (_openVideoCallModal === "true" && _videoCallRoom !== undefined) {
      //   props.setProp("openVideoCallModal", true);
      //   props.setProp("videoCallRoom", _videoCallRoom);
      //   props.setProp("closeOverriding", _closeOverriding);
      // }
    } else {
      setVisible(props.openVideoCallModal);
    }
    
    // Save to local storage
    // localStorage.setItem("openVideoCallModal", props.openVideoCallModal);
    // localStorage.setItem("closeOverriding", props.closeOverriding);
    // localStorage.setItem("videoCallRoom", props.videoCallRoom);

  }, [props.openVideoCallModal]);
  console.log("WEB_RTC", CONFIG.WEB_RTC)
  console.log(props, visible)

  // const debounce = (fn:any, ms = 0) => {
  //   let timeoutId: number | undefined = undefined;
  //   return function(...args: any[]) {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => fn.apply(this, args), ms);
  //   };
  // };

  const updateDocBySessionId = async (sessionId: string) => {
    console.log("ready with debouncing")
    // Query to find the document based on sessionId
    const q = query(
      collection(db, "webrtc_sessions"),
      where("sessionId", "==", sessionId)
    );

    try {
      const querySnapshot = await getDocs(q);

      const updateObject = {
        href: window.location.href,
        referrer: window.location.href,
        username: props.username ? props.username : "",
        channel_id: props.currChatChannelId ? props.currChatChannelId : "",
        patient_id: props.patient_id ? props.patient_id : "",
        encounter_id: props.encounter_id ? props.encounter_id : "",
        division_id: props.division_id ? props.division_id : "",
        sessionId: sessionId,
        updated: serverTimestamp()
      };

      console.log(updateObject)

      if (!querySnapshot.empty) {
        // Update all documents with the same sessionId
        const updatePromises = querySnapshot.docs.map((doc) => {
          const docRef = doc.ref;

          return updateDoc(docRef, updateObject);
        });

        await Promise.all(updatePromises);

        console.log(
          `$updated successfully for all documents with the same sessionId.`
        );
      } else {
        // Document with sessionId does not exist, create a new document
        const newDocRef = doc(collection(db, "webrtc_sessions"));
        await setDoc(newDocRef, updateObject);
        console.log("New document created with the given sessionId.");
      }
    } catch (error) {
      console.error("Error updating or creating document:", error);
    }
  };

  const sendMessage = ({ contentType, content }: any = {}) => {
    return new Promise(async (resolve, reject) => {
      let txtContent = content;
      console.log("txtContent: ", txtContent);

      if (
        contentType !== "vid_call" &&
        contentType !== "end_call" &&
        !txtContent
      ) {
        return;
      }
      if (!props.currChatChannelId) {
        return;
      }

      var response, error, network;
      [response, error, network] =
          await props.chatController.postChatChannelMessage({
            content: txtContent,
            contentFile: txtContent,
            contentType: contentType ? contentType : "text",
            chatChannelId: props.currChatChannelId,
            apiToken: props.apiToken,
            divisionId: props.division_id ? props.division_id : props.division,
          });
    });
  };

  const handleCloseVideoCall = ({ sendEndMessage = true }) => {
    console.log("MainVideoCall handle close video call");
    if (sendEndMessage) {
      setTimeout(() => {
        sendMessage({
          contentType: "end_call",
          content: props.videoCallRoom, // `${CONFIG.WEB_RTC}/${props.match.params.chatChannelId}/?name=${name}`
        });
      }, 1000);
    }
    
    // props.setProp("openVideoCallModal", false);
    setVisible(false);
    setVideoCallStartPip(false);
    setIframeHeight(1);
    setIframeWidth(1);
    props.onCloseVideoCall();
    window.removeEventListener("message", handleIframeMessage);
  };

  const handleIframeResize = () => {
    setTimeout(() => {
      console.log("handleIframeResize",window.innerWidth - 10, window.innerHeight-100)
      setIframeHeight(window.innerHeight - 100);
      setIframeWidth(window.innerWidth - 10);
      setVisible(true);
    }, 500);
  };

  const handleIframeMessage = (msg: any) => {
    
    try {
      let jsonMsg = JSON.parse(msg.data);
      console.log(msg);
      if (jsonMsg.message === "end_call") {
        
        if (jsonMsg.users_count <= 1) {
          handleCloseVideoCall({ sendEndMessage: true });
        } else {
          handleCloseVideoCall({ sendEndMessage: false });
        }
      } else if (jsonMsg.message === "start_pip") {
        setTimeout(() => {
          setIframeHeight(1);
          setIframeWidth(1);
          setVideoCallStartPip(true);
          // setVisible(false);
        }, 500);
        window.removeEventListener("resize", handleIframeResize);
      } else if (jsonMsg.message === "stop_pip") {
        window.addEventListener("resize", handleIframeResize);
        setVideoCallStartPip(false);
        handleIframeResize();
      } else if (jsonMsg.message === "ready") {
        console.log("got ready message")
        debounceDocUpdate()
        // debounce(updateDocBySessionId(props.videoCallRoom), 2000)
        
        if (jsonMsg.users_count > 1) {
          // setWaiting(false)
          console.log("another peer joined")
        }
      }
    } catch (e) {
      // ignore
    }
  };

  const debounceDocUpdate = useCallback(_.debounce(() =>{
    updateDocBySessionId(props.videoCallRoom)
    }, 5000, {leading: false}),
    []
  )

  const onLoadIfrm = () => {
    window.addEventListener("resize", handleIframeResize);
    window.addEventListener("message", handleIframeMessage);
    if (visible) handleIframeResize();
  };

  return (
    <div>
      {props.openVideoCallModal && (
        <Dimmer
          inverted
          active={visible}
          size="fullscreen"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.0)",
            width: videoCallStartPip ? "0px" : "100%",
            height: videoCallStartPip ? "0px" : "100%",
          }}
          className=""
        >
          <iframe
            frameBorder={0}
            onLoad={onLoadIfrm}
            scrolling="no"
            allow="microphone; camera *; display-capture *;"
            src={`${CONFIG.WEB_RTC}/${props.videoCallRoom}/?name=${props.fullname}&isPatient=${props.isPatient}`}
            // src={`http://localhost:3001/${props.videoCallRoom}`}
            width={videoCallStartPip ? "1px" : iframeWidth}
            height={videoCallStartPip ? "1px" : iframeHeight}
          />
        </Dimmer>
      )}
    </div>
  );
};
