import React, {FormEvent, SyntheticEvent} from 'react';
import {Button, Form, Header, Message, Modal} from "semantic-ui-react";
import PasswordLoginManager from "./PasswordLoginManager";

interface Props {
  open: boolean,
  cid: string,
  username: string,
  onOTPSuccess: (token:string) => void,
  onCancel: () => void,  // parent need to change `open` to false
}

interface State {
  ref: string,
  otp: string,
  isLoading: boolean,
  errorMessage: string,
}

export default class OTPModal extends React.PureComponent<Props, State> {
  state:State = {
    ref: '',
    otp: '',
    isLoading: false,
    errorMessage: '',
  }
  manager = new PasswordLoginManager();

  setOTP = (event: any) => {this.setState({otp: event.target.value})}

  onOpen = async () => {
    this.setState({isLoading: true});
    console.log('========== onOpen ==========');
    const {cid, username} = this.props;
    if (cid === ''){
      this.setState({errorMessage: 'Invalid cid'});
    } else if (username === '') {
      this.setState({errorMessage: 'Invalid username'});
    } else {
      let res = await this.manager.requestMobileOTP(cid, username);
      await this.setState({ref: res.ref});
    }
    this.setState({isLoading: false});
  }

  onSubmit = async (event: FormEvent) => {
    this.setState({isLoading: true});
    const {username} = this.props;
    const {ref, otp} = this.state;
    try {
      let data = await this.manager.requestMobileToken(username, ref, otp);
      this.props.onOTPSuccess(data.token);
    } catch (e) {
      console.error(e);
      this.setState({errorMessage: e.detail});
    }
    this.setState({isLoading: false});
  }

  componentDidUpdate(prevProps:Readonly<Props>) {
    if (prevProps.open === false && this.props.open === true) {
      this.onOpen();
    }
  }

  render() {
    const {ref, otp, isLoading, errorMessage} = this.state;
    const {open} = this.props;
    return (
      <Modal open={open} size='tiny' onOpen={this.onOpen} onClose={this.props.onCancel} closeIcon>
        <Modal.Header>Please enter your OTP</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form loading={isLoading} onSubmit={this.onSubmit} error={errorMessage !== ''}>
              { errorMessage ? (
                <Message
                  error
                  content={errorMessage}
                />
              ):null }
              {/*<p>*/}
              {/*  The OTP has been sent to your phone.*/}
              {/*  If you haven&apos;t got a sms message with in a minute,*/}
              {/*  try to close this window and login again*/}
              {/*</p>*/}
              <p>
                The OTP has been sent to your email.
                If you haven&apos;t got a email with in 2 minutes,
                try to close this window and login again
              </p>
              <Form.Field>
                <Form.Input
                  label='OTP'
                  value={otp}
                  onChange={this.setOTP}
                />
              </Form.Field>
              <p><b>ref. code</b> {ref}</p>
              <Button type='submit'>Check OTP</Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}