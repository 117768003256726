import WasmController from 'react-lib/frameworks/WasmController';
import axios from "axios";
import { getSheetInfo } from "react-lib/frameworks/Excel";

export type State = 
  {
    vaccineInfo: any[],
  }

export const StateInitial: State = 
  {
    vaccineInfo: []
  }

export type Event = 
    { message: "GetVaccineInfo", params: {} }
  | { message: "ChooseVaccineFile", params: {} }

export type Data = 
  {
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const GetVaccineInfo: Handler = async (controller, params) => {
  controller.setState({ vaccineInfo: ["ok"] });
  // axios.post("http://localhost:8000/get-appointment/", {
  //   data: vacc
  // })
  // .then((res: any) => {
  //   console.log(res)
  // });
}

export const ChooseVaccineFile: Handler = async (controller, params) => {
  console.log(params.file.files[0]);
  const vaccineInfo = await getSheetInfo(params.file.files[0],
    { "0": "reference_id",
      "1": "name",
      "2": "last_name",
      "3": "citizen_id",
      "4": "brand",
      "5": "mobile",
      "6": "email",
    });
  controller.setState({ vaccineInfo: vaccineInfo })
  axios.post("http://localhost:8000/get-appointment/", {
    vaccineInfo: vaccineInfo
  })
  .then((res: any) => {
    console.log(res)
  });
}
