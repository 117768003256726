import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from "react";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import {
  Header,
  Form,
  Divider,
  Dimmer,
  Input,
  Button,
  Icon,
  Item,
  Loader,
} from "semantic-ui-react";
// import the progress bar
// import StepProgressBar from 'react-step-progress';
// import Stepper from "react-stepper-horizontal"
// import the stylesheet
// import 'react-step-progress/dist/index.css';
import { ModInfo, ErrorMessage } from "react-lib/apps/common";

import config from "../../../../config/config";
// import "../../../../Main/MainNurse.scss"

type InformationError = {
  position?: boolean;
  accountName?: boolean;
};
type InformationProps = {
  position: string;
  accountName: string;
  description: string;
  doctorOptions?: any[];
  username: string;
  displayContent?: boolean;
  error?: InformationError;
  showAccountNameLabel?: boolean;
};

type RegisterInformationProps = {
  onSave: Function;
  data: {
    username: string;
    accountName: string;
    position: string;
    description: string;
    positionName?: string;
  };
  doctorOptions?: any[];
  onCancel: Function;
};

type CheckInformationProps = {} & RegisterInformationProps;

type RegisterCompleteProps = {
  onSuccess: Function;
  data: any;
};

const HeaderCustom = (props) => {
  return (
    <>
      <Header as={props.as || "h3"} style={{ color: "#4e4e4e" }}>
        {props.label}
      </Header>
      {!props.hideDivider && (
        <Divider style={{ borderTop: "1px solid rgb(238 233 233)" }} />
      )}
    </>
  );
};

const InfomationForm = forwardRef((props: InformationProps, ref) => {
  const [position, setPosition] = useState<any>("");
  const [accountName, setAccountName] = useState<any>("");
  const [description, setDescription] = useState<any>("");

  useEffect(() => {
    if (props.position) {
      setPosition(props.position);
    }
    setAccountName(props.accountName);
    setDescription(props.description);
  }, [props.position]);

  useImperativeHandle(ref, () => ({
    getData: () => {
      const findPosition = props.doctorOptions?.find(
        (doctor) => doctor.value === position
      );
      console.log(findPosition, props.doctorOptions, position);
      return {
        accountName,
        description,
        position: findPosition?.value,
        positionName: findPosition?.text,
      };
    },
  }));

  return (
    <>
      <div>
        <Form>
          <Form.Group inline>
            <Form.Field width={4}>ชื่อผู้ใช้</Form.Field>
            <Form.Field width={4}>
              <div style={{ wordSpacing: "10px" }}>{props.username}</div>
            </Form.Field>
          </Form.Group>
          {/* <Form.Group inline style={{ marginTop: "20px", alignItems: "baseline" }}>
          <Form.Field width={4}>
            <div style={{ display: 'flex' }}>
              <label>ตำแหน่ง</label>
              <div style={{ color: "red" }}>*</div>
            </div>
          </Form.Field>
          <Form.Field width={4}>
            {
              props.displayContent ?
                <div>{props.position}</div> :
                <div style={{ display: "grid", width: "100%" }}>
                  <Dropdown
                    value={position}
                    options={props.doctorOptions}
                    selection
                    search
                    onChange={(e, { value }) => setPosition(value)}
                    fluid
                    style={{ width: "100%" }}
                    error={props.error?.position}
                  />
                  {
                    props.error?.position &&
                    <label
                      className="font-error"
                    >กรุณาระบุตำแหน่ง</label>
                  }
                </div>
            }
          </Form.Field>
        </Form.Group> */}
        </Form>
      </div>

      <div style={{ marginTop: "45px" }}>
        <Form>
          <HeaderCustom label="ข้อมูลบัญชี" />

          <Form.Group inline style={{ alignItems: "baseline" }}>
            <Form.Field width={4}>
              <div style={{ display: "flex" }}>
                <label>ชื่อ Official Account</label>
                <div style={{ color: "red" }}>*</div>
              </div>
            </Form.Field>
            <Form.Field width={4}>
              {props.displayContent ? (
                <div>{props.accountName}</div>
              ) : (
                <div style={{ display: "grid", width: "100%" }}>
                  <Input
                    value={accountName}
                    onChange={(e, { value }) => setAccountName(value)}
                    error={props.error?.accountName}
                  />
                  {props.error?.accountName && (
                    <label className="font-error">
                      กรุณาระบุ Official Account
                    </label>
                  )}
                </div>
              )}
            </Form.Field>
            {props.showAccountNameLabel && (
              <Form.Field width={8}>
                <label style={{ color: "#828282" }}>
                  *ตัวอย่างการตั้งชื่อ : แผนกอายุรกรรม โรงพยาบาลสุขภาพดี
                </label>
              </Form.Field>
            )}
          </Form.Group>
          <Form.Group inline style={{ marginTop: "20px" }}>
            <Form.Field width={4}>
              <label>รายละเอียดเพิ่มเติม</label>
            </Form.Field>
            <Form.Field width={4}>
              {props.displayContent ? (
                <div>{props.description || "-"}</div>
              ) : (
                <Input
                  value={description}
                  onChange={(e, { value }) => setDescription(value)}
                />
              )}
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    </>
  );
});

const RegisterInformation: React.FC<RegisterInformationProps> = (props) => {
  const [error, setError] = useState<InformationError>({});

  const formRef = useRef<any>();

  const handleSave = () => {
    const data = formRef.current.getData();
    if (data.accountName) {
      props.onSave?.(data);
      setError({});
    } else {
      setError({
        position: !data.position ? true : false,
        accountName: !data.accountName ? true : false,
      });
    }
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  return (
    <>
      <div>{/* Step */}</div>
      <div>
        <Form>
          <HeaderCustom label="สร้าง Official Account" />
          <HeaderCustom label="ข้อมูลการเข้าสู่ระบบ" />

          <InfomationForm
            ref={formRef}
            doctorOptions={props.doctorOptions}
            username={props.data?.username}
            accountName={props.data?.accountName}
            position={props.data?.position}
            description={props.data?.description}
            error={error}
            showAccountNameLabel
          />
        </Form>
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "60px" }}
      >
        <Button className="primary back" onClick={handleCancel}>
          ยกเลิก
        </Button>
        <Button className="primary" onClick={handleSave}>
          ยืนยัน
        </Button>
      </div>
    </>
  );
};

const CheckInformation: React.FC<CheckInformationProps> = (props) => {
  const handleSave = () => {
    props.onSave?.();
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  return (
    <>
      <HeaderCustom label="ตรวจสอบรายละเอียด" />
      <HeaderCustom label="ข้อมูลการเข้าสู่ระบบ" />
      <InfomationForm
        displayContent={true}
        username={props.data?.username}
        accountName={props.data?.accountName}
        position={props.data?.positionName}
        description={props.data?.description}
      />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "60px" }}
      >
        <Button className="primary back" onClick={handleCancel}>
          ยกเลิก
        </Button>
        <Button className="primary" onClick={handleSave}>
          ยืนยัน
        </Button>
      </div>
    </>
  );
};

const RegisterComplete: React.FC<RegisterCompleteProps> = (props) => {
  const inputRef = useRef<Input>();

  const handleSuccess = () => {
    props.onSuccess?.();
  };

  const handleCopyClipboard = () => {
    var copyText = inputRef.current;
    copyText.select();
    document.execCommand("copy");
  };

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <HeaderCustom
          label="สร้าง Official Account แล้ว"
          hideDivider={true}
          as="h2"
        />
      </div>
      <HeaderCustom label="ข้อมูลบัญชี" />
      <Form>
        <Form.Group inline>
          <Form.Field width={4}>ชื่อ Official Account</Form.Field>
          <Form.Field width={4}>{props.data?.account_name}</Form.Field>
        </Form.Group>
        {/* <Form.Group inline style={{ marginTop: "20px" }}>
        <Form.Field width={4}>
          ชื่อโรงพยาบาล/แผนก
              </Form.Field>
        <Form.Field width={4}>
          {props.data?.hospital_name}
        </Form.Field>
      </Form.Group> */}
      </Form>
      <Divider style={{ borderTop: "1px solid rgb(238 233 233)" }} />
      <HeaderCustom label="Link สำหรับการใช้งาน" hideDivider={true} />
      <div
        style={{ display: "flex", alignItems: "baseline", marginTop: "10px" }}
      >
        <label style={{ marginRight: "20px", fontWeight: "bold" }}>
          Official Account link
        </label>
        <div style={{ display: "grid" }}>
          <div>
            <Input
              ref={inputRef}
              value={props.data?.account_link}
              readOnly={true}
              style={{ marginRight: "10px" }}
              className="primary"
            />
            <Icon
              name="copy"
              className="primary"
              style={{ cursor: "pointer" }}
              onClick={handleCopyClipboard}
            />
          </div>
          <div style={{ marginTop: "15px" }}>
            ท่านสามารถคัดลอกและส่ง Link นี้ให้กับผู้ป่วยในเครือของท่าน
            เพื่อเชิญเข้าใช้งาน
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "60px" }}
      >
        <Button className="primary" onClick={handleSuccess}>
          เสร็จสิ้น
        </Button>
      </div>
    </>
  );
};

const StepProgressBar = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  useEffect(() => {
    const index =
      props.activeStep < 0
        ? 0
        : props.activeStep >= props.steps.length
        ? props.steps.length - 1
        : props.activeStep;
    setActiveStep(index);
  }, [props.activeStep]);
  return (
    <>
      <ol className="steps oa" style={{ margin: "auto", ...props.style }}>
        {props.steps?.map((step, index) => (
          <li
            key={"step-" + index}
            className={`step${
              index < activeStep
                ? " is-complete"
                : index === activeStep
                ? " is-active"
                : ""
            }`}
            data-step={index + 1}
          >
            {step.label}
          </li>
        ))}
      </ol>
      {props.steps?.[activeStep]?.content?.()}
    </>
  );
};

const CreateOA = (props) => {
  const history = useHistory();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [showCreateScreen, setShowCreateScreen] = useState<string>("");
  const [data, setData] = useState<any>({});
  const [successData, setSuccessData] = useState<any>({});
  const [showNoti, setShowNoti] = useState(false);

  const [openModError, setOpenModError] = useState({
    open: false,
    error: null,
  });
  // myDivisions

  useEffect(() => {
    props.onGetDivision("create_oa");
  }, []);

  useEffect(() => {
    props.onSetShowMenuNoti?.(true);
  }, []);

  useEffect(() => {
    if (props.myDivisions.length >= 2) {
      setShowCreateScreen("open");
      props.onSetShowMenuNoti?.(false);
      // props.onSetShowMenuDivision?.(false)
    } else {
      setShowCreateScreen("close");
    }
  }, [props.myDivisions]);

  useEffect(() => {
    const getProfile = async ({ apiToken }) => {
      const [data, error, network] = await props.controller.getUserProfile({
        apiToken,
      });
      console.log(" data", data, error, network);

      let username = "";
      if (props?.userFullName) {
        username = props.userFullName;
      } else {
        username = `${data?.first_name} ${data?.last_name}`;
      }

      if (data) {
        setData({
          ...data,
          username,
        });
      }
    };

    if (Cookies.get("apiToken") && props.controller?.getUserProfile) {
      getProfile({ apiToken: Cookies.get("apiToken") });
    }
  }, [props.controller?.getUserProfile]);

  useEffect(() => {
    // props.onEvent({ message: "GetRoleListUsers", params: {} })
  }, []);

  const handleSaveRegistor = (saveData) => {
    setData({
      ...data,
      ...saveData,
    });
    setActiveStep(1);
  };

  const handleSaveCheck = async () => {
    const { description, accountName, position } = data;
    const params = {
      detail: description || "",
      name: accountName,
      apiToken: props.apiToken,
    };

    const [res, error, network] =
      await props.controller.prxManager.postRequestOA(params);

    // const [res, err] = props.onEvent({ message: "CreateOfficialAccount", params: { data: params } })
    // console.log(res, err)
    if (error) {
      setOpenModError({
        open: true,
        error,
      });
      return;
    }
    // const findUserRole = props.userRoleList.find((list) => list.id === res.user_role)
    setSuccessData({
      account_name: res.name,
      account_link: `${
        config.OFFICIAL_ACCOUNT_HOST || "https://ishealth.app"
      }/OA/${res.id}/?openExternalBrowser=1`,
    });
    setActiveStep(2);
  };

  const handleCancelCheck = () => {
    setActiveStep(0);
  };

  const handleSuccessRegistor = () => {
    globalThis.location.replace("/Chat");
  };

  const handleCreateScreenBack = () => {
    setData({});

    if (props.myDivisions.length >= 2) {
      history.goBack();
    } else {
      setShowCreateScreen("close");
      props.onSetShowMenuNoti?.(true);
      // props.onSetShowMenuDivision?.(true)
    }
  };

  const handleCloseModInfo = () => {
    setOpenModError({
      open: false,
      error: null,
    });
  };

  const doctorOptions = useMemo(() => {
    return (props.userRoleList || [])?.map((list, index) => ({
      key: index,
      text: list.name,
      value: list.id,
    }));
  }, [props.userRoleList]);

  return (
    <div className="oa-form-custom">
      {showCreateScreen === "close" ? (
        // หน้าแรกก่อนเข้าหน้า register
        <>
          {/* Notification */}
          {showNoti && (
            <div className="card-noti-oa">
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    marginBottom: "10px",
                  }}
                >
                  <Icon name="hospital" color="blue" />
                  <div className="title">{"แผนกอายุรกรรม"}</div>
                </div>
                <div>
                  คุณร้องขอเข้าใช้งาน Official Account "{"แผนกอายุรกรรม"}"
                  แล้วกรุณารอการตรวจสอบจากแอดมิน
                </div>

                <div className="btn-remove">
                  <div
                    style={{
                      cursor: "pointer",
                      width: "120px",
                      padding: "8px",
                    }}
                    onClick={() => setShowNoti(false)}
                  >
                    ลบการแจ้งเตือน
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "94vh",
            }}
          >
            <div
              style={{
                color: "rgb(63 60 60)",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              คุณไม่มี Official Account ที่สามารถใช้งานได้
            </div>
            <div
              style={{
                color: "rgb(63 60 60)",
                fontSize: "18px",
                margin: "12px 0 40px",
                fontWeight: "bold",
              }}
            >
              กรุณา "สร้าง Official Account" เพื่อเข้าใช้งาน
            </div>
            <div>
              <Button
                onClick={() => {
                  setShowCreateScreen("open");
                  // props.onSetShowMenuDivision?.(false)
                  props.onSetShowMenuNoti?.(false);
                }}
                className="second"
                size="huge"
                color="blue"
              >
                สร้าง Official Account
              </Button>
            </div>
          </div>
        </>
      ) : showCreateScreen === "open" ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "180px auto",
            height: "94vh",
          }}
        >
          {/* Left panel */}
          <div className="nav-bar">
            <Item.Group>
              <Item className="is_active">
                <Icon name="edit" />
                สร้างใหม่
              </Item>
            </Item.Group>
          </div>
          {/* หน้า Register */}
          <div style={{ padding: "20px" }}>
            <ModInfo
              open={openModError.open}
              titleColor={"red"}
              key="modError"
              onApprove={handleCloseModInfo}
              onClose={handleCloseModInfo}
            >
              <ErrorMessage error={openModError.error} />
            </ModInfo>
            {
              <div>
                <StepProgressBar
                  activeStep={activeStep}
                  style={{ width: "80%" }}
                  steps={[
                    {
                      label: "ลงทะเบียนข้อมูล Official Account",
                      name: "step 1",
                      content: () => (
                        <div style={{ marginTop: "50px" }}>
                          <RegisterInformation
                            onSave={handleSaveRegistor}
                            data={data}
                            doctorOptions={doctorOptions}
                            onCancel={handleCreateScreenBack}
                          />
                        </div>
                      ),
                    },
                    {
                      label: "ตรวจสอบข้อมูลการสมัคร",
                      name: "step 2",
                      content: () => (
                        <div style={{ marginTop: "50px" }}>
                          <CheckInformation
                            onSave={handleSaveCheck}
                            onCancel={handleCancelCheck}
                            data={data}
                          />
                        </div>
                      ),
                    },
                    {
                      label: "เสร็จสิ้นการสมัคร",
                      name: "step 3",
                      content: () => (
                        <div style={{ marginTop: "65px" }}>
                          <RegisterComplete
                            onSuccess={handleSuccessRegistor}
                            data={successData}
                          />
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            }
          </div>
        </div>
      ) : (
        <Dimmer active={true} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
    </div>
  );
};

export default CreateOA;
