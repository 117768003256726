import React, { useState } from "react";
import { serial_to_datestr, QueueController } from "./Time";
import Cookies from "js-cookie";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { HourRangeDropdown } from "../common";
import { Dimmer, Loader, Divider, Button } from "semantic-ui-react";
import * as Model from "./Model";
import * as DefaultModel from "./DefaultModel";
import moment from "moment";
import * as Util from "react-lib/utils/tsUtils";
import { formatDate } from "react-lib/utils/index";

interface RouterProps {
  providerId?: string;
  date?: string;
}

interface TimeDoctorProps extends RouteComponentProps<RouterProps> {
  apiToken: string;
  controller: QueueController;
  providerId?: number;
  divisionId?: number | string;
}

const TimeDoctorEdit = (props: TimeDoctorProps) => {
  const [date, setDate] = useState<moment.Moment | null>(null);
  const [minHour, setMinHour] = useState(0);
  const [maxHour, setMaxHour] = useState(24);
  const [isLoading, setIsLoading] = useState(false);
  const [doctorSchedule, setDoctorSchedule] = useState<Model.DoctorSchedule>(
    DefaultModel.DoctorSchedule
  );
  const [startTime, setStartTime] = useState<moment.Moment | null>(null);
  const [endTime, setEndTime] = useState<moment.Moment | null>(null);
  const [serviceSlots, setServiceSlots] = useState<{
    [key: string]: any[];
  }>({});
  const isMounted = React.useRef(true);
  const history = useHistory();

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (props.match.params.date) {
      let adDate = Util.convertToADDate({
        date: props.match.params.date.split("-").join("/")
      });
      setDate(moment(adDate, "DD/MM/YYYY"));
    } else {
      setDate(moment());
    }
  }, [props.match.params.date]);

  React.useEffect(() => {
    if (date) {
      getDoctorWorkSchedule();
    }
  }, [date]);

  React.useEffect(() => {
    let newObj = { ...serviceSlots };
    if (doctorSchedule.service_slots.length > 0) {
      for (let item of doctorSchedule.service_slots) {
        let time = serial_to_datestr(item.serial); // convert serial to date
        if (newObj[moment(time).format("HH:mm")]) {
          // check whether serviceSlots has same key
          newObj[moment(time).format("HH:mm")].push(item); // if yes add serviceSlot to array(value) of that key
        } else {
          newObj[moment(time).format("HH:mm")] = [item]; // else add an arr of serviceSlot to that key
        }
      }
      setServiceSlots(newObj);
    }
  }, [doctorSchedule && doctorSchedule.service_slots]);

  const getDoctorWorkSchedule = async () => {
    let startDate = props.match.params.date
      ? props.match.params.date.split("-").join("/") // change from dd-mm-YYYY to dd/mm/YYYY
      : formatDate(moment()); // if no date from url params, use current date
    let endDate = props.match.params.date
      ? props.match.params.date.split("-").join("/") // change from dd-mm-YYYY to dd/mm/YYYY
      : formatDate(moment()); // if no date from url params, use current date
    setIsLoading(true);
    setServiceSlots({});
    let [response, error] = await props.controller.getDoctorWorkSchedule({
      apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
      startDate,
      endDate,
      providerId: props.match.params.providerId,
      divisionId: props.divisionId ? props.divisionId : Cookies.get("division_id")
    });
    if (isMounted.current) {
      setIsLoading(false);

      if (response && response.items.length > 0) {
        let cloneResItem = [...response.items];
        setDoctorSchedule(response.items[0]);
        const findMin = [...cloneResItem].sort((a, b) => ('' + a.start_datetime).localeCompare(b.start_datetime))
        if (findMin[0].start_datetime) {
          // if not check, typscript will error
          let startTimeArr = findMin[0].start_datetime.split(" "); // split YYYY-MM-DD HH:mm:ss ICU
          setStartTime(
            moment(
              `${startTimeArr[0]} ${startTimeArr[1]}`,
              "YYYY-MM-DD HH:mm:ss"
            )
          );
          setMinHour(parseInt(startTimeArr[1].split(":")[0]));
        }
        const findMax = [...cloneResItem].sort((a, b) => ('' + b.end_datetime).localeCompare(a.end_datetime))
        if (findMax[0].end_datetime) {
          // if not check, typscript will error
          let endTimeArr = findMax[0].end_datetime.split(" "); // split YYYY-MM-DD HH:mm:ss ICU
          setEndTime(
            // keep as moment obj
            moment(`${endTimeArr[0]} ${endTimeArr[1]}`, "YYYY-MM-DD HH:mm:ss")
          );
          setMaxHour(parseInt(endTimeArr[1].split(":")[0]));
        }
      } else {
        setDoctorSchedule(DefaultModel.DoctorSchedule);
        setStartTime(null);
        setEndTime(null);
      }
    }
  };

  const createDoctorSchedule = () => {
    let timeArr = [];
    if (startTime && endTime) {
      let newStartTime = startTime.clone();
      while (!newStartTime.isSame(endTime)) {
        // create time from start to end, each time will be +15minutes from start time
        let formatNewStartTime = newStartTime.format("HH:mm");
        if (
          moment(formatNewStartTime, "HH:mm").isBefore(
            moment(minHour + ":00", "HH:mm") // check if time is before minHour
          ) ||
          moment(formatNewStartTime, "HH:mm").isAfter(
            moment(maxHour + ":00", "HH:mm") // check if time is after maxHour
          )
        ) {
          newStartTime.add(15, "minutes"); // if yes, add 15 minutes add continue, dont draw
          continue;
        }
        let dividerEl = <Divider key={`Divider${formatNewStartTime}`} />;
        let el = (
          <div key={`Time${formatNewStartTime}`}>{formatNewStartTime}</div>
        );
        if (serviceSlots[formatNewStartTime]) {
          for (let item of serviceSlots[formatNewStartTime]) {
            el = (
              <div key={`Time${formatNewStartTime}`}>
                {formatNewStartTime}&emsp;&emsp;&emsp;{item.division_name}
              </div>
            );
          }
        }
        if (formatNewStartTime.split(":")[1] === "00") {
          timeArr.push(dividerEl);
        }
        timeArr.push(el);
        newStartTime.add(15, "minutes"); // 1 division service block = 15 minutes
      }
    }
    return timeArr;
  };

  const handleChangeDate = ({ type }: { type: string }) => {
    setStartTime(null);
    setEndTime(null);
    if (!date) return;
    let newDate: string | moment.Moment = date.clone();
    if (type === "back" && date) {
      newDate = newDate.subtract(1, "days");
    } else {
      newDate = newDate.add(1, "days");
    }
    newDate = Util.convertToThaiDate({ date: newDate, display: "localeDate" });
    return history.push(
      `/DoctorSchedule/${props.match.params.providerId}/${newDate
        .split("/")
        .join("-")}`
    );
  };

  return (
    <div className="DoctorScheduleView">
      <Dimmer.Dimmable
        dimmed={isLoading}
        style={{ height: "100%" }}
        className="MainLayout"
      >
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <div className="dateHeader">
          <Button
            compact
            disabled={isLoading}
            content="<"
            onClick={(e: any) => {
              handleChangeDate({ type: "back" });
            }}
          />
          &ensp;
          <h3 style={{ margin: 0 }}>
            {date &&
              Util.convertToThaiDate({
                date,
                display: "localeDate",
                options: {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric"
                }
              })}
          </h3>
          &ensp;
          <Button
            compact
            content=">"
            disabled={isLoading}
            onClick={(e: any) => {
              handleChangeDate({ type: "next" });
            }}
          />
        </div>

        <div className="content">
          <h2>
            ตารางออกตรวจแพทย์{" "}
            {doctorSchedule ? doctorSchedule.provider_name : ""}
            <span className="header5">
              &ensp; ช่วงเวลา&ensp;
              <HourRangeDropdown
                onChange={(e: any) => {
                  setMinHour(e.currentTarget.value);
                }}
                defaultValue={minHour}
              />
              &ensp; ถึง &ensp;{" "}
              <HourRangeDropdown
                onChange={(e: any) => setMaxHour(e.currentTarget.value)}
                defaultValue={maxHour}
              />
            </span>
          </h2>
          <div className="calendarContent">
            {!startTime && !endTime && <div>ไม่มีตารางออกตรวจ</div>}
            {createDoctorSchedule()}
          </div>
        </div>
      </Dimmer.Dimmable>
    </div>
  );
};

export default React.memo(TimeDoctorEdit);
